import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import appAbility from '~/services/ability';
import store from '~/store';
import { RouteMeta } from './types';
import { loggedInAsAnotherAdmin } from '~/helpers';

const MOBILE_MENU_SELECTOR = '#mobile-main-menu';

const router = createRouter({
	history: createWebHistory(),
	linkActiveClass: 'active',
	linkExactActiveClass: 'active-exact',
	routes,
});

router.afterEach(() => {
	const mobileMenu = document.querySelector(MOBILE_MENU_SELECTOR);
	if (mobileMenu == null) {
		console.error(`${ MOBILE_MENU_SELECTOR } not found`);
		return;
	}

	mobileMenu.classList.remove('active');
	document.body.classList.remove('no-scroll-mobile');
});

router.beforeEach(async (to) => {
	store.commit('core/GLOBAL_ERROR', null);
	if (!store.getters['user/ready']) {
		await store.dispatch('user/getMe');
	}

	const thisRoute = to.matched.find(match => match.name === to.name);

	if (!thisRoute) {
		return;
	}

	const { permissions, forbiddenAsAnotherAdmin } = thisRoute.meta as RouteMeta;
	if (
		(permissions != null && permissions.every(permission => appAbility.cannot(permission.action, permission.subject))) ||
		(forbiddenAsAnotherAdmin && loggedInAsAnotherAdmin())
	) {
		store.commit('core/GLOBAL_ERROR', 403);
		return;
	}

	if (thisRoute.meta.redirectToChild) {
		const firstPassableChild = thisRoute.children.find(child => {
			const { permissions: childPermissions } = child.meta as RouteMeta;
			return (
				childPermissions == null ||
				childPermissions.some(({ action, subject }) => appAbility.can(action, subject))
			);
		});
		if (firstPassableChild) {
			return { name: firstPassableChild.name };
		}
		else {
			store.commit('core/GLOBAL_ERROR', 403);
			return;
		}
	}
});

export default router;
