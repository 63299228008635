// Nette forms validace custom setup
export function handleValidation () {

	if (document.querySelector('form input')) {
		let forms = document.querySelectorAll('form');

		forms.forEach(form => {

			form.setAttribute('novalidate', '');

			if (form.querySelector('[type="submit"]')) {
				var submits = form.querySelectorAll('[type="submit"]');
				var overlay = document.querySelector('.overlay');

				var complete = false;

				submits.forEach(submit => {
					submit.addEventListener('click', () => {

						// Spustit jen pokud load trva dele nez 2 sekundy
						setTimeout(function () {
							if (!complete) { overlay.classList.add('is-loading'); }
						}, 2000);

						// Pro submity po kterych nenasleduje reload stranky - nutne je schovat
						naja.addEventListener('complete', () => {
							complete = true;

							if (overlay.classList.contains('is-loading')) { overlay.classList.remove('is-loading'); }
						});
					});
				});
			}

			form.querySelectorAll('input').forEach(el => {
				el.addEventListener('keyup', event => {
					let elName = el.hasAttribute('data-nette-rules') ? null : el.getAttribute('name');
					// Regex kvuli osetreni neplatneho selectoru (services[])
					let equalInput = elName === null || !/^\w+$/.test(elName) ? null : form.querySelector(`input[data-nette-rules*=${ elName }]`);

					var val = Nette.getValue(event.target);
					var ref = { value: val };

					removeErrorMessage(event.target); // to allow change error message (e.g. from Zadejte cenu to Cena musi byt cislo)

					// Pokud je nekde input zavisly na tomto inputu, zkontrolovat ho
					if (equalInput) { Nette.validateControl(equalInput, null, false, ref, undefined, true); }

					else if (!Nette.validateControl(event.target, null, false, ref)) {
						Nette.showFormErrors(form, Nette.formErrors);
					}
					else { removeErrorMessage(event.target); }
				});
			});

			form.querySelectorAll('textarea').forEach(el => {
				el.addEventListener('keyup', event => {

					var val = Nette.getValue(event.target);
					var ref = { value: val };

					removeErrorMessage(event.target); // to allow change error message (e.g. from Zadejte cenu to Cena musi byt cislo)

					if (!Nette.validateControl(event.target, null, false, ref)) {
						Nette.showFormErrors(form, Nette.formErrors);
					}
					else { removeErrorMessage(event.target); }
				});
			});

			// Kvuli prekreslovani na BE, musi byt navazane na document
			document.querySelectorAll('.checkbox').forEach(el => {
				el.addEventListener('click', event => {
					// Zajistuje jen jeden pruchod funkci (pro label, jinak i pro input)
					if (event.target.tagName.toLowerCase() !== 'input') { return; }

					let input = el.querySelector('input');

					let elName = input.hasAttribute('data-nette-rules') ? null : input.getAttribute('name');
					// Regex kvuli osetreni neplatneho selectoru (services[])
					let equalInput = elName === null || !/^\w+$/.test(elName) ? null : form.querySelector(`input[data-nette-rules*=${ elName }]`);

					var val = Nette.getValue(equalInput);
					var ref = { value: val };

					// Pokud je nekde input zavisly na checkboxu, zkontrolovat ho
					if (equalInput) { Nette.validateControl(equalInput, null, false, ref, undefined, true); }

					else if (el.parentElement.classList.contains('error')) { removeErrorMessage(event.target); }
				});
			});

			form.querySelectorAll('select').forEach(el => {
				el.addEventListener('change', event => {
					var val = Nette.getValue(event.target);
					var ref = { value: val };

					removeErrorMessage(event.target); // to allow change error message (e.g. from Zadejte cenu to Cena musi byt cislo)

					if (!Nette.validateControl(event.target, null, false, ref)) {
						Nette.showFormErrors(form, Nette.formErrors);
					}
					else { removeErrorMessage(event.target); }
				});
			});
		});

		errorScroll();
	}
}

export function removeErrorMessage (elem) {
	let errorElem = elem.closest('.error') || elem.parentNode.querySelector('.message-error'); // closest pro radio buttony

	if (errorElem) {
		elem.closest('.error').classList.remove('error');
		errorElem.querySelector('.message-error').remove();
	}

	// Smaze zaznam erroru, pokud je input uz spravne
	Nette.formErrors.forEach((el, i) => {
		if (el.element == elem) { Nette.formErrors.splice(i, 1); }

		// Pro radio buttony
		else if (el.element.type === 'radio' && elem.hasAttribute('for') && elem.tagName.toLowerCase() === 'label') {

			let selectedId = elem.getAttribute('for').match(/(.*)-\d+/)[1];
			let errorId = el.element.getAttribute('id').match(/(.*)-\d+/)[1];

			if (errorId === selectedId) { Nette.formErrors.splice(i, 1); }
		}
	});
}

handleValidation();

// Inicializace forms
naja.afterUpdateFunctions.push(handleValidation);


// Form validations & alert
/* global Nette */
Nette.showFormErrors = function (form, errors) {
	var messages = [],
		focusElem;

	for (var dataMsg = 0; dataMsg < errors.length; dataMsg++) {

		if (Object.prototype.hasOwnProperty.call(errors, dataMsg)) {
			var elem = errors[dataMsg].element;
			var message = errors[dataMsg].message;

			if (elem.type === 'radio') { elem.closest('fieldset').classList.add('error'); }
			else { elem.parentElement.classList.add('error'); }

			messages.push(message);

			if (!elem.closest('.error').querySelector('.message-error')) {
				var msg = document.createElement('div');
				msg.classList.add('message-error');
				msg.innerHTML = messages[dataMsg];

				if (elem.type === 'radio') { elem.closest('fieldset').querySelector('label').appendChild(msg); }
				else { elem.parentNode.appendChild(msg); }
			}
		}
	}

	if (messages.length) {
		// console.log(messages.join('\n'));

		if (focusElem) {
			focusElem.focus();
		}
	}
};

/**
 * Adds error message to the queue.
 */

Nette.addError = function (elem, message) {
	var elems = Nette.formErrors.map(item => item.element);

	if (!elems.includes(elem)) {
		Nette.formErrors.push({
			element: elem,
			message: message,
		});
	}
};

/**
 * Validates form element against given rules.
 */

Nette.validateControl = function (elem, rules, onlyCheck, value, emptyOptional, isEqual) {
	var top = !rules;
	elem = elem.tagName ? elem : elem[0]; // RadioNodeList
	rules = rules || JSON.parse(elem.getAttribute('data-nette-rules') || '[]');
	value = value === undefined ? { value: Nette.getEffectiveValue(elem) } : value;
	emptyOptional = emptyOptional === undefined ? !Nette.validateRule(elem, ':filled', null, value) : emptyOptional;

	for (var id = 0, len = rules.length; id < len; id++) {
		var rule = rules[id],
			op = rule.op.match(/(~)?([^?]+)/),
			curElem = rule.control ? elem.form.elements.namedItem(rule.control) : elem;

		rule.neg = op[1];
		rule.op = op[2];
		rule.condition = Boolean(rule.rules);

		if (!curElem) {
			continue;
		}
		else if (emptyOptional && !rule.condition && rule.op !== ':filled') {
			continue;
		}

		curElem = curElem.tagName ? curElem : curElem[0]; // RadioNodeList

		// Custom uprava - Pri propojeni prvku (:equal), validovat i pri interakci s prvkem, na ktery rule odkazuje
		if (curElem !== elem && isEqual) {
			let form = elem.closest('form');
			return Nette.validateControl(elem, null, true, value) ? removeErrorMessage(elem) : Nette.showFormErrors(form, Nette.formErrors);
		}

		var success = Nette.validateRule(curElem, rule.op, rule.arg, elem === curElem ? value : undefined);

		// Custom url validation
		if (success && rule.op.includes('url')) {

			if (!(/^[a-z\d+.-]+:/).test(elem.value)) {
				let form = elem.closest('form');

				Nette.addError(elem, t('Zadejte prosím validní url.'));
				Nette.showFormErrors(form, Nette.formErrors);

				return false;
			}
		}

		if (success === null) {
			continue;
		}
		else if (rule.neg) {
			success = !success;
		}

		if (rule.condition && success) {
			if (!Nette.validateControl(elem, rule.rules, onlyCheck, value, rule.op === ':blank' ? false : emptyOptional)) {
				return false;
			}
		}
		else if (!rule.condition && !success) {
			if (Nette.isDisabled(curElem)) {
				continue;
			}
			if (!onlyCheck) {
				var arr = Array.isArray(rule.arg) ? rule.arg : [ rule.arg ],
					// TODO this should be refactored to a part of the Vue app ASAP; no point fixing ESLint errors
					// eslint-disable-next-line no-loop-func
					message = rule.msg.replace(/%(value|\d+)/g, function (foo, m) {
						return Nette.getValue(m === 'value' ? curElem : elem.form.elements.namedItem(arr[m].control));
					});
				Nette.addError(curElem, message);
			}
			return false;
		}
	}

	if (elem.type === 'number' && !elem.validity.valid) {
		if (top && !onlyCheck) {
			Nette.addError(elem, Nette.invalidNumberMessage);
		}
		return false;
	}

	return true;
};

/**
* Validates whole form.
*/

Nette.validateForm = function (sender, onlyCheck) {
	var form = sender.form || sender,
		scope = false;

	Nette.formErrors = [];

	if (form['nette-submittedBy'] && form['nette-submittedBy'].getAttribute('formnovalidate') !== null) {
		var scopeArr = JSON.parse(form['nette-submittedBy'].getAttribute('data-nette-validation-scope') || '[]');
		if (scopeArr.length) {
			scope = new RegExp('^(' + scopeArr.join('-|') + '-)');
		}
		else {
			Nette.showFormErrors(form, []);
			return true;
		}
	}

	var radios = {}, i, elem;

	for (i = 0; i < form.elements.length; i++) {
		elem = form.elements[i];

		if (elem.tagName && !(elem.tagName.toLowerCase() in { input: 1, select: 1, textarea: 1, button: 1 })) {
			continue;

		}
		else if (elem.type === 'radio') {
			if (radios[elem.name]) {
				continue;
			}
			radios[elem.name] = true;
		}

		if ((scope && !elem.name.replace(/]\[|\[|]|$/g, '-').match(scope)) || Nette.isDisabled(elem)) {
			continue;
		}

		if (!Nette.validateControl(elem, null, onlyCheck) && !Nette.formErrors.length) {
			return false;
		}
	}
	var success = !Nette.formErrors.length;
	Nette.showFormErrors(form, Nette.formErrors);

	return success;
};

// Po submitu scrollovat k prvnimu erroru, pokud nejaky je
function errorScroll () {
	if (document.querySelector('form .error')) {
		var firstErrPosition = document.querySelector('form .error').getBoundingClientRect();

		window.scrollBy({ top: firstErrPosition.top - (screen.height / 6), behavior: 'smooth' });
	}
}

// Reqion, oblast, teritorium select boxes handle - update snippets after select --------------------------
document.addEventListener('DOMContentLoaded', function () {
	if (!document.getElementById('pos-hierarchy')) { return; }

	function updateReqions () {
		document.getElementById('pos-hierarchy').addEventListener('change', e => {
			let url = document.querySelector('[data-update-hierarchy]').getAttribute('data-update-hierarchy');
			let name = e.target.getAttribute('name');
			let value = e.target.value;

			naja.makeRequest('POST', url, { [name]: value }, {
				history: false,
			}).then(response => { naja.snippetHandler.updateSnippets(response.snippets); });
		});
	}

	updateReqions();
	naja.afterUpdateFunctions.push(function () { updateReqions(); });
});
