import { buildModule, RootDispatch } from './module';
import { RouteQuery, ROUTE_NAMESPACE } from './route';

type PaginationConfig = {
	pageQueryKey?: string;
	pageSizeQueryKey?: string;
	defaultPageSize?: number;
	itemCountGetter?: string;
};

export function createPaginationModule ({
	pageQueryKey = 'page',
	pageSizeQueryKey = 'itemsPerPage',
	defaultPageSize = 30,
	itemCountGetter,
}: PaginationConfig = {}) {
	return buildModule()
		.withGetters({
			pageSize (_state, _getters, _rootState, rootGetters) {
				const query = rootGetters[`${ ROUTE_NAMESPACE }/query`] as RouteQuery;
				const pageSize = query[pageSizeQueryKey];
				return sanitizeInt(pageSize, defaultPageSize);
			},
		})
		.withGetters({
			itemCount (_state, _getters, _rootState, rootGetters) {
				return itemCountGetter != null
					? sanitizeInt(rootGetters[itemCountGetter], Infinity, 0)
					: Infinity;
			},
		})
		.withGetters({
			pageCount (_state, getters, _rootState) {
				const itemCount = getters.itemCount;
				return Math.ceil(itemCount / getters.pageSize);
			},
		})
		.withGetters({
			page (_state, getters, _rootState, rootGetters) {
				const query = rootGetters[`${ ROUTE_NAMESPACE }/query`] as RouteQuery;
				const { pageCount } = getters;
				return sanitizeInt(query[pageQueryKey], 1, 1, pageCount);
			},
		})
		.withGetters({
			offset (_state, getters) {
				return (getters.page - 1) * getters.pageSize;
			},
		})
		.withActions({
			async pageTo ({ dispatch, getters }, page: number) {
				const { pageCount } = getters;
				const validPage = sanitizeInt(page, 1, 1, pageCount);
				const queryValue = validPage === 1 ? null : validPage;
				await dispatchQueryUpdate(dispatch, pageQueryKey, queryValue);
			},
		})
		.withActions({
			async pageBy ({ dispatch, getters }, pageDiff: number) {
				const { page } = getters;
				await dispatch('pageTo', page + pageDiff);
			},
			async resizePage ({ dispatch }, pageSize: number) {
				const validSize = sanitizeInt(pageSize, defaultPageSize);
				const queryValue = validSize === defaultPageSize ? null : validSize;
				await dispatchQueryUpdate(dispatch, pageSizeQueryKey, queryValue);
			},
		});
}

function sanitizeInt (
	value: unknown,
	defaultValue: number,
	min = 1,
	max = Infinity,
): number {
	const int = parseInt(String(value));
	const validInt = isNaN(int) ? defaultValue : int;
	const clampedInt = Math.max(min, Math.min(validInt, max));
	return clampedInt;
}

async function dispatchQueryUpdate (
	dispatch: RootDispatch,
	key: string,
	value: unknown,
): Promise<void> {
	const query = { [key]: value != null ? String(value) : null };
	const routeUpdate = { query, mergeQuery: true, replaceRoute: true };
	await dispatch(`${ ROUTE_NAMESPACE }/update`, routeUpdate, { root: true });
}
