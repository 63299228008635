import { Api, FilterKey } from '~/api';
import $axios from '~/plugins/axios';
import { buildModule } from '../utils';

const api = $axios as Api;

export default buildModule()
	.withState({
		exportTypeKeys: [] as FilterKey[],
	})
	.withGetters({
		list: state => state.exportTypeKeys,
	})
	.withMutations({
		STORE_EXPORT_TYPE_KEYS (state, keys: FilterKey[]) {
			state.exportTypeKeys = keys;
		},
	})
	.withActions({
		async getList ({ commit }) {
			const { data } = await api.get('/info-extra/in-export/keys');
			commit('STORE_EXPORT_TYPE_KEYS', data.payload);
		},
	});
