import $axios from '~/plugins/axios';
import { Dict, fetchAllPages } from '~/helpers';
import { buildModule } from '../utils';
import { Api, AuditItem, AuditItemLight, GetAuditItemParams } from '~/api';

const api = $axios as Api;

export default buildModule()
	.withState({
		auditItems: {} as Dict<AnyAuditItem>,
		auditItemsLoading: false,
	})
	.withGetters({
		auditItemsByIds: state => (ids: number[]) => ids
			.map(id => state.auditItems[id])
			.filter(id => id != null),
		auditItemsLoading: state => state.auditItemsLoading,
	})
	.withMutations({
		AUDIT_ITEMS_LOADING (state, loading: boolean) {
			state.auditItemsLoading = loading;
		},
		AUDIT_ITEMS_SUCCESS (state, auditItems: AuditItemLight[]) {
			state.auditItems = auditItems.reduce(upsertAuditItem, state.auditItems);
		},
		AUDIT_ITEM_SUCCESS (state, auditItem: AuditItem) {
			state.auditItems = upsertAuditItem(state.auditItems, auditItem);
		},
	})
	.withActions({
		async getAuditItems ({ commit }, ids: number[]) {
			commit('AUDIT_ITEMS_LOADING', true);
			const params: GetAuditItemParams = { auditItemId: ids };
			const items = await fetchAllPages('/audit-item', params)<AuditItemLight>(api);
			commit('AUDIT_ITEMS_SUCCESS', items);
			commit('AUDIT_ITEMS_LOADING', false);
		},
		async getDetailedAuditItem ({ commit }, id: number) {
			const resp = await api.get(`/audit-item/${ id }`);
			commit('AUDIT_ITEM_SUCCESS', resp.data.payload);
		},
	});

type AnyAuditItem = AuditItem | AuditItemLight;

function upsertAuditItem (
	items: Dict<AnyAuditItem>,
	newItem: AnyAuditItem,
): Dict<AnyAuditItem> {
	return {
		...items,
		[newItem.id]: { ...items[newItem.id], ...newItem },
	};
}
