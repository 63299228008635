import { AdminInfo, AdminInfoListItemRequest, AdminInfoRequest, AdminInfoType, Api, GetSettingAdminInfoParams, Settings } from '~/api';
import $axios from '../../plugins/axios';
import { buildModule, createFiltersModule, createPaginationModule, defineSingleFilter } from '../utils';
import { t } from 'i18next';

import adminInfoExport from './admin-info-export';
import adminInfoFilters from './admin-info-filters';
import exportTypeKeys from './export-type-keys';
import filtersLocationKeys from './filters-location-keys';

const api = $axios as Api;

const ADMIN_INFO_DEFAULT = {
	name: '',
	required: false,
	adminEditable: false,
	valueType: 'text',
	adminInfoListItems: [] as AdminInfoListItemRequest[],
};

export type AdminInfoNew = typeof ADMIN_INFO_DEFAULT;

export type AdminInfoUpdate = {
	id: number;
	name: string;
	required: boolean;
	adminEditable: boolean;
	valueType: AdminInfoRequest['valueType'];
	adminInfoListItems: AdminInfoListItemRequest[];
};

export default buildModule()
	.withSubmodules({
		adminInfoExport,
		adminInfoFilters,
		exportTypeKeys,
		filtersLocationKeys,
		dashboardAuditsFilters: createFiltersModule({
			filters: {
				onlyActive: defineSingleFilter('onlyActive', Boolean, false),
			},
		}),
		dashboardAuditsPagination: createPaginationModule({
			pageQueryKey: 'auditsPage',
			pageSizeQueryKey: 'auditsPerPage',
			defaultPageSize: 10,
			itemCountGetter: 'audits/auditsTotalCount',
		}),
		hierarchyFilters: createFiltersModule({
			filters: {
				searchTerm: defineSingleFilter('region', String),
			},
		}),
		hierarchyPagination: createPaginationModule({
			pageQueryKey: 'regionsPage',
			pageSizeQueryKey: 'regionsPerPage',
			itemCountGetter: 'planner/planning/regionTotalCount',
		}),
	})
	.withState({
		settings: null as Settings | null,
		adminInfo: [] as AdminInfo[],
		adminInfoNew: [] as AdminInfoNew[],
		adminInfoTypes: [] as AdminInfoType[],
		status: '' as '' | 'loading' | 'success' | 'error',
	})
	.withGetters({
		settings: state => state.settings,
		adminInfo: state => state.adminInfo,
	})
	.withMutations({
		SET_SETTINGS (state, settings: Settings) {
			state.settings = settings;
		},

		ADMIN_INFO_REQUEST (state) {
			state.status = 'loading';
		},

		ADMIN_INFO_SUCCESS (state, info: AdminInfo[]) {
			state.status = 'success';
			state.adminInfo = info;
		},

		ADMIN_INFO_TYPES_SUCCESS (state, types: AdminInfoType[]) {
			state.status = 'success';
			state.adminInfoTypes = types;
		},

		ADMIN_INFO_ERROR (state) {
			state.status = 'error';
		},

		ADD_INFO_NEW (state) {
			state.adminInfoNew.push({ ...ADMIN_INFO_DEFAULT });
		},

		CLEAR_INFO_NEW (state) {
			state.adminInfoNew = [];
		},

		INFO_OPEN (state, adminInfo: AdminInfo[]) {
			state.adminInfo = adminInfo;
		},
	})
	.withActions({
		async getSettings ({ commit }) {
			const { data } = await api.get('/settings');
			commit('SET_SETTINGS', data.payload);
		},
		async getAdminInfo ({ commit }, params: GetSettingAdminInfoParams = {}) {
			commit('ADMIN_INFO_REQUEST');

			const { data } = await api.get('/setting/admin-info', { params });
			commit('ADMIN_INFO_SUCCESS', data.payload);
		},
		getAdminInfoTypes ({ commit }) {
			commit('ADMIN_INFO_REQUEST');

			return api.get('/setting/admin-info/types').then(resp => {
				const types = resp.data.payload;
				commit('ADMIN_INFO_TYPES_SUCCESS', types);
				return resp;
			})
				.catch(() => {
					commit('ADMIN_INFO_ERROR');
				});
		},
	})
	.withActions({
		addAdminInfo ({ dispatch, commit }, {
			name,
			required,
			adminEditable,
			valueType,
			adminInfoListItems,
		}: Omit<AdminInfoUpdate, 'id'>) {
			commit('ADMIN_INFO_REQUEST');

			const body: AdminInfoRequest = valueType === 'list'
				? { name, required, adminEditable, valueType, adminInfoListItems }
				: { name, required, adminEditable, valueType, adminInfoListItems: null };

			return api.post('/setting/admin-info', body)
				.then(resp => {
					dispatch('getAdminInfo');

					new TempMessage({
						html: `<p>${ t('Položka byla přidána.') }</p>`,
						type: 'success',
					});

					return resp;
				})
				.catch(() => {
					commit('ADMIN_INFO_ERROR');
					new TempMessage({
						html: `<p>${ t('Položku se nepodařilo uložit. Možnost nesmí být prázdná nebo se opakovat.') }</p>`,
						type: 'error',
					});
				});
		},

		editAdminInfo ({ dispatch, commit }, {
			id,
			name,
			required,
			adminEditable,
			valueType,
			adminInfoListItems,
		}: AdminInfoUpdate) {
			commit('ADMIN_INFO_REQUEST');

			const body: AdminInfoRequest = valueType === 'list'
				? { name, required, valueType, adminEditable, adminInfoListItems }
				: { name, required, valueType, adminEditable, adminInfoListItems: null };

			return api.put(`/setting/admin-info/${ id }`, body)
				.then(resp => {
					dispatch('getAdminInfo');

					new TempMessage({
						html: `<p>${ t('Položka byla upravena.') }</p>`,
						type: 'success',
					});

					return resp;
				})
				.catch((err) => {
					let message = `<p>${ t('Položku se nepodařilo upravit. Možnost nesmí být prázdná nebo se opakovat.') }</p>`;
					if (err.response.data.message === 'You can\'t change valueType with existing admins values.') {
						message = `<p>${ t('Nelze změnit typ položky. Položka je již přiřazena k uživatelům.') }</p>`;
					}
					commit('ADMIN_INFO_ERROR');
					new TempMessage({
						html: message,
						type: 'error',
					});
				});
		},

		deleteAdminInfo ({ commit, dispatch }, id: number) {
			commit('ADMIN_INFO_REQUEST');

			return api.delete(`/setting/admin-info/${ id }`)
				.then((resp) => {
					dispatch('getAdminInfo');

					new TempMessage({
						html: `<p>${ t('Položka byla smazána.') }</p>`,
						type: 'success',
					});

					return resp;
				})
				.catch(() => {
					commit('ADMIN_INFO_ERROR');
					new TempMessage({
						html: `<p>${ t('Položku se nepodařilo odstranit.') }</p>`,
						type: 'error',
					});
				});
		},
	});
