import $axios from '~/plugins/axios';

import { Api, Task, TaskRequest } from '~/api';
import { buildModule } from '../utils';
import { Dict } from '~/helpers';

const api = $axios as Api;

export default buildModule()
	.withState({
		storedTasks: {} as Dict<Task>,
	})
	.withGetters({
		byId: state => (taskId: number) => (state.storedTasks[taskId] ?? null),
	})
	.withMutations({
		STORE_TASK_ITEMS (state, task: Task) {
			state.storedTasks = {
				...state.storedTasks,
				[task.id]: task,
			};
		},
	})
	.withActions({
		async get ({ commit }, id: number) {
			const { data } = await api.get(`/task/${ id }`);
			commit('STORE_TASK_ITEMS', data.payload);
		},
		async post (_, request: TaskRequest) {
			const { data: _data } = await api.post('/task', request);
		},
		async delete (_, id: number) {
			await api.delete(`/task/${ id }`);
		},
	});
