import { buildModule, createFiltersModule, defineArrayFilter, defineSingleFilter } from '../utils';

import list from './list';
import detail from './detail';

export default buildModule()
	.withSubmodules({
		list,
		detail,
		adminFilters: createFiltersModule({
			filters: {
				searchTerm: defineSingleFilter('search', String),
				enabledOnly: defineSingleFilter('enabledOnly', Boolean, false),
				orderBy: defineSingleFilter('orderBy', String),
				orderAsc: defineSingleFilter('orderAsc', Boolean, false),
				roleId: defineArrayFilter('role', Number),
				customFilterIds: defineArrayFilter('filterId', Number),
				customFilterValues: defineArrayFilter('filterValue', String),
			},
		}),
	});
