import axios from './axios';
import moment from './moment';
import FloatingVue from 'floating-vue';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import { notivue } from 'notivue';
import VueVirtualScroller from 'vue-virtual-scroller';
import { abilitiesPlugin } from '@casl/vue';
import appAbility from '~/services/ability';
import { App } from 'vue';

import 'floating-vue/dist/style.css';
import 'notivue/animations.css';
import 'notivue/notifications.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

export default function registerPlugins (app: App) {
	app.config.globalProperties.$axios = axios;
	app.config.globalProperties.$moment = moment;

	app.provide('$axios', app.config.globalProperties.$axios);
	app.provide('$moment', app.config.globalProperties.$moment);

	app.use(FloatingVue);
	app.use(abilitiesPlugin, appAbility);
	app.use(I18NextVue, { i18next });
	app.use(notivue, { position: 'bottom-left' });
	app.use(VueVirtualScroller);
}
