<template>
	<div class="notification-avatar">
		<icon which="robot" />
		<div class="notification-avatar__badge">
			<pin
				color="#999999"
				:ripple="props.alert"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
type Props = {
	alert: boolean;
};

const props = defineProps<Props>();
</script>

<style scoped lang="scss">
$diameter: 3.2rem;
$badge-offset: (0.5 - 0.25 * math.sqrt(2)) * $diameter;

.notification-avatar {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: $diameter;
	height: $diameter;
	padding: 0.4rem;
	border: 1px solid $grey;
	border-radius: 50%;
	background-color: $transparentGrey;
	color: $black;

	&__badge {
		position: absolute;
		top: $badge-offset;
		left: $badge-offset;
		translate: -50% -50%;
	}
}
</style>
