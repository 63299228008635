import i18next from 'i18next';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import 'moment/dist/locale/cs';
import 'moment/dist/locale/sk';

const moment = extendMoment(Moment);
moment.locale(i18next.language);

export default moment;
