import $axios from '~/plugins/axios';

import { TaskItemLight, Api, PaginatorResult, GetTaskItemParams, GetTaskItemStatisticsParams, GetTaskItemMeStatisticsParams, TaskItemStat, GetTaskItemMeParams } from '~/api';
import { buildModule, createPaginationModule } from '../utils';
import { Dict, createDict } from '~/helpers';

const api = $axios as Api;

export default buildModule()
	.withSubmodules({
		pagination: createPaginationModule({
			pageQueryKey: 'taskItemsPage',
			pageSizeQueryKey: 'taskItemsPerPage',
			itemCountGetter: 'taskItems/list/totalCount',
		}),
	})
	.withState({
		storedTaskItems: {} as Dict<TaskItemLight>,
		taskItems: [] as TaskItemLight[],
		loading: false,
		paginator: null as PaginatorResult | null,
		statistics: [] as TaskItemStat[],
	})
	.withGetters({
		byId: state => (taskItemId: number) => (
			state.storedTaskItems[taskItemId] ?? null
		),
		page: state => state.taskItems,
		loading: state => state.loading,
		totalCount: state => state.paginator?.totalCount ?? 0,
		statistics: (state) => state.statistics,
	})
	.withMutations({
		SET_STORED_TASK_ITEMS (state, taskItems: TaskItemLight[]) {
			state.storedTaskItems = {
				...state.storedTaskItems,
				...createDict(taskItems, (taskItem) => taskItem.id),
			};
		},
		SET_TASK_ITEMS (state, taskItemIds: number[]) {
			state.taskItems = taskItemIds.map(id => state.storedTaskItems[id]);
		},
		SET_LOADING (state, loading: boolean) {
			state.loading = loading;
		},
		SET_PAGINATOR (state, paginator: PaginatorResult) {
			state.paginator = paginator;
		},
		SET_TASK_ITEMS_STATISTICS (state, statistics: TaskItemStat[]) {
			state.statistics = statistics;
		},
	})
	.withActions({
		async get ({ commit }, { showAll, ...params }: (GetTaskItemParams | GetTaskItemMeParams) & { showAll: boolean }) {
			commit('SET_LOADING', true);

			const { data } = showAll
				? await api.get('/task-item', { params })
				: await api.get('/task-item/me', { params });

			commit('SET_LOADING', false);

			const taskItems = data.payload;
			const taskItemIds = taskItems.map(taskItem => taskItem.id);

			commit('SET_STORED_TASK_ITEMS', taskItems);
			commit('SET_TASK_ITEMS', taskItemIds);
			commit('SET_PAGINATOR', data.paginator);

			// new TempMessage({
			// 	html: `<p>${ t('Seznam úkolů se nepodařilo načíst.') }</p>`,
			// 	type: 'error',
			// });
		},
		async getStatistics ({ commit }, { showAll, ...params }: (GetTaskItemStatisticsParams | GetTaskItemMeStatisticsParams) & { showAll: boolean }) {
			const resp = showAll
				? await api.get('/task-item/statistics', { params })
				: await api.get('/task-item/me/statistics', { params });

			commit('SET_TASK_ITEMS_STATISTICS', resp.data.payload);

			// new TempMessage({
			// 	html: `<p>${ t('Statistiky úkolů se nepodařilo načíst.') }</p>`,
			// 	type: 'error',
			// });
		},
	});
