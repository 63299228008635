import $axios from '~/plugins/axios';
import { Admin, Api, Area, AuditBulk, Filter, GetAuditBulkParams, GetFilterParams, PaginatorResult, Pos, Region, Territory } from '~/api';
import {
	buildModule,
	createPaginationModule,
	createFiltersModule,
	defineArrayFilter,
	defineSingleFilter,
} from '../utils';

const api = $axios as Api;

export default buildModule()
	.withSubmodules({
		filters: createFiltersModule({
			filters: {
				q: defineSingleFilter('search', String),
				dateFrom: defineArrayFilter('dateFrom', String),
				dateTo: defineArrayFilter('dateTo', String),
				createdBy: defineArrayFilter('createdBy', Number),
				auditId: defineSingleFilter('audit', Number),
				auditedPosId: defineArrayFilter('pos', Number),
				auditedAdminId: defineArrayFilter('admin', Number),
				areaId: defineSingleFilter('area', Number),
				regionId: defineSingleFilter('region', Number),
				territoryId: defineSingleFilter('territory', Number),
				customFilterIds: defineArrayFilter('filterId', Number),
				customFilterValues: defineArrayFilter('filterValue', String),
				orderBy: defineSingleFilter('orderBy', String, 'createdAt'),
				orderAsc: defineSingleFilter('orderAsc', Boolean, false),
			},
		}),
		pagination: createPaginationModule({
			pageQueryKey: 'auditsPage',
			pageSizeQueryKey: 'auditsPerPage',
			itemCountGetter: 'audits/list/auditListTotalCount',
		}),
	})
	.withState({
		audits: [] as AuditBulk[],
		auditsLoading: false,
		auditListPaginator: null as PaginatorResult | null,

		posSelected: null as Pos | null,
		userSelected: null as Admin | null,
		regionSelected: null as Region | null,
		areaSelected: null as Area | null,
		territorySelected: null as Territory | null,

		customFilters: [] as Filter[],
	})
	.withGetters({
		audits: state => state.audits,
		auditsLoading: state => state.auditsLoading,
		auditListTotalCount: state => state.auditListPaginator?.totalCount ?? 0,
		posSelected: state => state.posSelected,
		userSelected: state => state.userSelected,
		regionSelected: state => state.regionSelected,
		areaSelected: state => state.areaSelected,
		territorySelected: state => state.territorySelected,
		customFilters: state => state.customFilters,
	})
	.withMutations({
		SET_AUDITS (state, audits: AuditBulk[]) {
			state.audits = audits;
		},
		SET_AUDITS_LOADING (state, loading: boolean) {
			state.auditsLoading = loading;
		},
		AUDITS_PAGINATION (state, paginator: PaginatorResult) {
			state.auditListPaginator = paginator;
		},
		SET_SELECTED_POS (state, pos: Pos) {
			state.posSelected = pos;
		},
		SET_SELECTED_USER (state, user: Admin) {
			state.userSelected = user;
		},
		SET_SELECTED_REGION (state, region: Region) {
			state.regionSelected = region;
		},
		SET_SELECTED_AREA (state, area: Area) {
			state.areaSelected = area;
		},
		SET_SELECTED_TERRITORY (state, territory: Territory) {
			state.territorySelected = territory;
		},
		CUSTOM_FILTERS_SUCCESS (state, value: Filter[]) {
			state.customFilters = value;
		},
	})
	.withActions({
		async getAudits ({ commit }, params: GetAuditBulkParams = {}) {
			commit('SET_AUDITS_LOADING', true);
			const { data } = await api.get('/audit-bulk', { params });
			commit('SET_AUDITS_LOADING', false);
			commit('SET_AUDITS', data.payload);
			commit('AUDITS_PAGINATION', data.paginator);
		},

		getPos ({ commit }, posId: number) {
			return api.get(`/pos/${ posId }`)
				.then(resp => {
					const pos = resp.data.payload;
					commit('SET_SELECTED_POS', pos);
				})
				.catch(err => { console.log(err); });
		},

		getUser ({ commit }, userId: number) {
			return api.get(`/admin/${ userId }`)
				.then(resp => {
					const user = resp.data.payload;
					commit('SET_SELECTED_USER', user);
				})
				.catch(err => { console.log(err); });
		},

		getRegionById ({ commit }, regionId: number) {
			return api.get(`/region/${ regionId }`)
				.then(resp => {
					const region = resp.data.payload;
					commit('SET_SELECTED_REGION', region);
				})
				.catch(err => { console.log(err); });
		},

		getAreaById ({ commit }, areaId: number) {
			return api.get(`/area/${ areaId }`)
				.then(resp => {
					const area = resp.data.payload;
					commit('SET_SELECTED_AREA', area);
				})
				.catch(err => { console.log(err); });
		},

		getTerritoryById ({ commit }, territoryId: number) {
			return api.get(`/territory/${ territoryId }`)
				.then(resp => {
					const territory = resp.data.payload;
					commit('SET_SELECTED_TERRITORY', territory);
				})
				.catch(err => { console.log(err); });
		},

		getCustomFilters ({ commit }) {
			const params: GetFilterParams = {
				key: 'audit',
			};

			return api.get('/filter', { params })
				.then(resp => {
					const customFilters = resp.data.payload;
					commit('CUSTOM_FILTERS_SUCCESS', customFilters);
					return resp;
				});
		},
	});
