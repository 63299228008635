import { AnsweredQuestion, Api, GetAuditAnswersIdParams } from '~/api';
import { Dict } from '~/helpers';
import $axios from '~/plugins/axios';
import { buildModule } from '../utils';

const api = $axios as Api;

export default buildModule()
	.withState({
		questionsByAuditId: {} as Dict<AnsweredQuestion[]>,
	})
	.withGetters({
		questionsByAuditId: state => (auditId: number) => (
			state.questionsByAuditId[auditId] ?? []
		),
	})
	.withMutations({
		STORE_ANSWERED_QUESTIONS (state, { auditId, questions }: {
			auditId: number;
			questions: AnsweredQuestion[];
		}) {
			state.questionsByAuditId = {
				...state.questionsByAuditId,
				[auditId]: questions,
			};
		},
	})
	.withActions({
		async get ({ commit }, { auditId, params }: {
			auditId: number;
			params: GetAuditAnswersIdParams;
		}) {
			const { data } = await api.get(`/audit/answers/${ auditId }`, { params });
			commit('STORE_ANSWERED_QUESTIONS', data.payload);
		},
	});
