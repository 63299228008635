import { Api, GetPlannerStatsAdminsParams, GetPlannerStatsMonthlyParams, PlannerAdminStats, PlannerMonthlyStats, GetAuditBusynessParams, AuditBusynessResponse } from '~/api';
import $axios from '~/plugins/axios';
import { buildModule } from '../utils';

const api = $axios as Api;

export default buildModule()
	.withState({
		monthlyStats: null as PlannerMonthlyStats | null,
		adminStats: null as PlannerAdminStats | null,
		busynessStats: null as AuditBusynessResponse | null,
	})
	.withGetters({
		monthlyStats: state => state.monthlyStats?.payload ?? [],
		adminStats: state => state.adminStats?.payload ?? [],
		adminStatsCount: state => state.adminStats?.paginator?.totalCount ?? 0,
		busynessStatsTimes: state => state.busynessStats?.payload.times ?? [],
	})
	.withMutations({
		MONTHLY_STATS_SUCCESS (state, stats: PlannerMonthlyStats) {
			state.monthlyStats = stats;
		},
		ADMIN_STATS_SUCCESS (state, stats: PlannerAdminStats) {
			state.adminStats = stats;
		},
		BUSINESS_STATS_SUCCESS (state, stats: AuditBusynessResponse) {
			state.busynessStats = stats;
		},
	})
	.withActions({
		async getMonthlyStats ({ commit }, params: GetPlannerStatsMonthlyParams) {
			const resp = await api.get('/planner-stats/monthly', { params });
			commit('MONTHLY_STATS_SUCCESS', resp.data);
		},
		async getAdminStats ({ commit }, params: GetPlannerStatsAdminsParams) {
			const resp = await api.get('/planner-stats/admins', { params });
			commit('ADMIN_STATS_SUCCESS', resp.data);
		},
		async getBusynessStats ({ commit }, params: GetAuditBusynessParams) {
			const resp = await api.get('/audit-busyness', { params });
			commit('BUSINESS_STATS_SUCCESS', resp.data);
		},
	});
