import { keysOf } from './common';

export const GRAPH_COLORS = {
	color1: '#133FEB',
	color2: '#DB0033',
	color3: '#84E126',
	color4: '#FFBA00',
	color5: '#11E5D9',
	color6: '#00CABE',
	color7: '#097763',
	color8: '#5E00BC',
	color9: '#EE6400',
} as const;
export const ROLE_COLOR_FALLBACK = '#67676780';
export const EXCUSE_COLOR = '#C3EFFC';

export const graphColorKeys = keysOf(GRAPH_COLORS);

export const isGraphColorKey = (key: string): key is keyof typeof GRAPH_COLORS => (key in GRAPH_COLORS);

export const graphColorPalette = graphColorKeys
	.map(key => ({ key, value: GRAPH_COLORS[key] }));

export const graphColorKeyToColor = (key: string) => (isGraphColorKey(key) ? GRAPH_COLORS[key] : '#000000');

export const fillGraphChartColors = (items: (string | null)[]) => {
	const takenColors = [ ...new Set(items.filter(item => item != null)) ];
	const availableColors = takenColors.length < graphColorKeys.length
		? graphColorKeys.filter(color => !takenColors.includes(color))
		: graphColorKeys;

	const nullIndexes = items.reduce<number[]>((indexes, item, index) => (item === null ? [ ...indexes, index ] : [ ...indexes ]), []);

	return items.map((item, index) => (item != null
		? item
		: availableColors[nullIndexes.indexOf(index) % availableColors.length]));
};
