(function () {
	var dataGridRegisterExtension = function (named, extension) {
		var init = extension.init;
		var success = extension.success;
		var before = extension.before;
		var complete = extension.complete;


		var NewExtension = function NewExtension (naja, name) {
			this.name = name;

			if (init) {
				naja.addEventListener('init', function (params) {
					init(params.defaultOptions);
				});
			}

			if (success) {
				naja.addEventListener('success', function (params) {
					success(params.response, params.options);
				});
			}

			if (before) {
				naja.addEventListener('before', function (params) {
					before(params.xhr, params.options);
				});
			}

			if (complete) {
				naja.addEventListener('complete', function (params) {
					complete(params.xhr, params.options);
				});
			}

			return this;
		};

		naja.registerExtension(NewExtension, named);
	};

	var datagridSerializeUrl = function (obj, prefix) {
		var str = [];
		for (var p in obj) {
			if (obj.hasOwnProperty(p)) {
				var k = prefix ? prefix + '[' + p + ']' : p, v = obj[p];
				if (v !== null && v !== '') {
					if (typeof v == 'object') {
						var r = datagridSerializeUrl(v, k);
						if (r) {
							str.push(r);
						}
					}
					else {
						str.push(encodeURIComponent(k) + '=' + encodeURIComponent(v));
					}
				}
			}
		}
		return str.join('&');
	};

	dataGridRegisterExtension('datagrid.url', {
		success: function (payload) {
			var host, path, query, url;
			/* eslint-disable no-underscore-dangle */
			if (payload._datagrid_url) {
			/* eslint-enable no-underscore-dangle */
				if (window.history.replaceState) {
					host = window.location.protocol + '//' + window.location.host;
					path = window.location.pathname;
					query = datagridSerializeUrl(payload.state).replace(/&+$/gm, '');
					if (query) {
						url = host + path + '?' + query.replace(/&*$/, '');
					}
					else {
						url = host + path;
					}
					url += window.location.hash;
					if (window.location.href !== url) {
						return window.history.replaceState({
							path: url,
						}, '', url);
					}
				}
			}
		},
	});

	dataGridRegisterExtension('datagrid.sort', {
		success: function (payload) {
			var href, key, ref, results;
			/* eslint-disable no-underscore-dangle */
			if (payload._datagrid_sort) {
				ref = payload._datagrid_sort;
				/* eslint-enable no-underscore-dangle */
				results = [];
				for (key in ref) {
					if (ref.hasOwnProperty(key)) {
						href = ref[key];
						// TODO this should be refactored to a part of the Vue app ASAP; no point fixing ESLint errors
						// eslint-disable-next-line no-loop-func
						results.push(document.querySelectorAll('#datagrid-sort-' + key + ' a').forEach(function (element) {
							element.setAttribute('href', href);
						}));
					}
				}
				return results;
			}
		},
	});

	// datagrid-instant-url-refresh
	var dataGridRegisterAjaxCall = function (params) {
		var method = params.type || 'GET';
		var data = params.data || null;

		naja.makeRequest(method, params.url, data, {
			history: 'replace',
		})
			.then(params.success)
			.catch(params.error);
	};

	document.addEventListener('DOMContentLoaded', function () {
		var element = document.querySelector('.datagrid');

		if (element !== null) {
			return dataGridRegisterAjaxCall({
				type: 'GET',
				url: element.getAttribute('data-refresh-state'),
			});
		}
	});


})();
