(function () {
	var LoadingIndicatorExtension = function (naja, defaultLoadingIndicatorSelector) {
		var loaderSelector = defaultLoadingIndicatorSelector;
		var defaultLoadingIndicator = document.querySelector(loaderSelector);

		var showLoader = function () {
			defaultLoadingIndicator.classList.add('is-loading');
		};

		var hideLoader = function () {
			defaultLoadingIndicator.classList.remove('is-loading');
		};

		let complete;

		naja.addEventListener('start', () => {
			complete = false;

			// Spustit jen pokud load trva dele nez 2 sekundy
			setTimeout(function () { if (!complete) { showLoader(); } }, 2000);
		});

		naja.addEventListener('complete', () => {
			complete = true;
			if (defaultLoadingIndicator.classList.contains('is-loading')) { hideLoader(); }
		});
	};

	naja.registerExtension(LoadingIndicatorExtension, '.overlay[data-loading]');
})();
