import { Store } from 'vuex';
import { Admin } from '~/api';
import { appAbility, AppRule } from '~/services/ability';

export default (store: Store<{ user: { me: Admin | null } }>) => {
	return store.subscribe((mutation, state) => {
		const mutationType = mutation.type;
		if (mutationType === 'user/SET_ME') {
			const resources = state.user.me?.role?.resources ?? {};
			const formatted: AppRule[] = [];
			Object.keys(resources).forEach(resource => {
				formatted.push({ subject: resource, action: resources[resource] });
			});
			appAbility.update(formatted);
		}
		if (mutationType === 'user/UNSET_ME') {
			appAbility.update([]);
		}
	});
};
