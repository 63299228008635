import $axios from '~/plugins/axios';

import { Admin, Api, GetAdminParams, PaginatorResult } from '~/api';
import { buildModule, createPaginationModule } from '../utils';
import { Dict, createDict } from '~/helpers';

const api = $axios as Api;

export default buildModule()
	.withSubmodules({
		pagination: createPaginationModule({
			pageQueryKey: 'adminsPage',
			pageSizeQueryKey: 'adminsPerPage',
			itemCountGetter: 'admins/list/totalCount',
		}),
	})
	.withState({
		storedAdmins: {} as Dict<Admin>,
		admins: [] as Admin[],
		loading: false,
		paginator: null as PaginatorResult | null,
	})
	.withGetters({
		byId: state => (adminId: number) => (
			state.storedAdmins[adminId] ?? null
		),
		page: state => state.admins,
		loading: state => state.loading,
		totalCount: state => state.paginator?.totalCount ?? 0,
	})
	.withMutations({
		SET_STORED_ADMINS (state, admins: Admin[]) {
			state.storedAdmins = {
				...state.storedAdmins,
				...createDict(admins, (admin) => admin.id),
			};
		},
		SET_ADMINS (state, adminIds: number[]) {
			state.admins = adminIds.map(id => state.storedAdmins[id]);
		},
		SET_LOADING (state, loading: boolean) {
			state.loading = loading;
		},
		SET_PAGINATOR (state, paginator: PaginatorResult) {
			state.paginator = paginator;
		},
	})
	.withActions({
		async get ({ commit }, params: GetAdminParams = {}) {
			commit('SET_LOADING', true);
			const { data } = await api.get('/admin', { params });
			commit('SET_LOADING', false);

			const admins = data.payload;
			const adminIds = admins.map(admin => admin.id);

			commit('SET_STORED_ADMINS', admins);
			commit('SET_ADMINS', adminIds);
			commit('SET_PAGINATOR', data.paginator);
		},
	});
