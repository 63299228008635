<template>
	<v-tooltip
		popper-class="basic-tooltip"
		:disabled="props.disabled"
	>
		<slot />
		<template #popper>
			<slot name="tooltip" />
		</template>
	</v-tooltip>
</template>

<script setup lang="ts">
type Props = {
	disabled?: boolean;
};

const props = defineProps<Props>();
</script>

<style lang="scss">
.v-popper__popper.basic-tooltip {
	.v-popper__inner {
		* {
			// reset some global styles to make <p>, <strong>, ... usable in <template #tooltip>
			margin: 0;
			padding: 0;
			color: inherit;
			font-size: inherit;
			line-height: 1.5;
		}
	}
}
</style>
