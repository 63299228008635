import { buildModule, createFiltersModule, createPaginationModule, defineSingleFilter } from '../utils';
import list from './list';

export default buildModule()
	.withSubmodules({
		list,

		filters: createFiltersModule({
			filters: {
				unreadOnly: defineSingleFilter('unread', Boolean, false),
			},
			replaceRoute: true,
		}),

		pagination: createPaginationModule({
			defaultPageSize: 10,
			itemCountGetter: 'notifications/list/totalCount',
		}),
	});
