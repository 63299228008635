import { Api, AuditCategoryLight, AuditCategoryRequest, GetAuditCategoryParams } from '~/api';
import { Dict, createDict, removeKey } from '~/helpers';
import $axios from '~/plugins/axios';
import { buildModule } from '../utils';

const api = $axios as Api;

export default buildModule()
	.withState({
		categories: {} as Dict<AuditCategoryLight>,
	})
	.withGetters({
		list: state => Object.values(state.categories)
			.sort((a, b) => a.name.localeCompare(b.name)),

		byId: state => (id: number) => state.categories[id] ?? null,
	})
	.withMutations({
		STORE_ALL (state, categories: AuditCategoryLight[]) {
			state.categories = createDict(categories, ({ id }) => id);
		},

		INSERT_ONE (state, category: AuditCategoryLight) {
			state.categories = { ...state.categories, [category.id]: category };
		},

		DELETE_ONE (state, id: number) {
			state.categories = removeKey(state.categories, id);
		},
	})
	.withActions({
		async getAll ({ commit }, params: GetAuditCategoryParams = {}) {
			const { data } = await api.get('/audit-category', { params });
			commit('STORE_ALL', data.payload);
		},

		async create ({ commit }, body: AuditCategoryRequest) {
			const { data } = await api.post('/audit-category', body);
			commit('INSERT_ONE', data.payload);
		},

		async update ({ commit }, { id, body }: {
			id: number;
			body: AuditCategoryRequest;
		}) {
			const { data } = await api.put(`/audit-category/${ id }`, body);
			commit('INSERT_ONE', data.payload);
		},

		async delete ({ commit }, id: number) {
			await api.delete(`/audit-category/${ id }`);
			commit('DELETE_ONE', id);
		},
	});
