import $axios from '~/plugins/axios';
import { errorMessageHandeling } from '~/helpers';
import { t } from 'i18next';

function formatAuditPayload (payload, auditId, getters) {
	return payload.map(question => {
		const { roles: _roles, dashboardOptions: _dashboardOptions, options: _options, ...rest } = question;
		return {
			...rest,
			options: question.options.map(option => option.optionId),
			dashboardOptions: question.dashboardOptions.map(dashboardOption => ({
				optionId: dashboardOption.option.id,
				color: dashboardOption.color,
			})),
			questions: getters.auditQuestionAssignedOptions(auditId, question.options.map(option => option.optionId)),
			roleId: question.roles.map(role => role.id),
		};
	});
}

export default {
	namespaced: true,
	state: {
		auditsOpened: [],
	},
	getters: {
		auditsOpened: state => state.auditsOpened,
		detail: state => id => state.auditsOpened.find(audit => audit.id === id) || null,
		graphDetail: state => (auditId, graphIndex) => {
			const thisAudit = state.auditsOpened.find(audit => audit.id === auditId);
			return thisAudit.graphs[graphIndex];
		},
		auditQuestionsByOptions: (state, getters, rootState, rootGetters) => (auditId, options) => {
			const audit = rootGetters['audits/auditDetail'](auditId);
			if (!audit) {
				return [];
			}
			return audit.questions.filter(question => {
				return question.options.some(option => options.includes(option.id));
			});
		},
		optionsFlat: (state, getters) => (auditId, graphIndex) => {
			const graph = getters.graphDetail(auditId, graphIndex);
			const options = [];
			graph.questions.forEach(q => {
				options.push(...q.options);
			});
			return options;
		},
		auditQuestionAssignedOptions: (state, getters, rootState, rootGetters) => (auditId, options) => {
			const audit = rootGetters['audits/auditDetail'](auditId);
			if (!audit) {
				return [];
			}
			const result = [];
			audit.questions.forEach(question => {
				const questionOptions = question.options.filter(option => options.includes(option.id));
				if (!questionOptions.length) {
					return;
				}
				result.push({ id: question.id, options: questionOptions.map(option => option.id) });
			});

			return result;
		},
	},
	mutations: {
		AUDIT_OPEN (state, { graphs, id }) {
			state.auditsOpened.push({ id, graphs });
		},
		GRAPH_ADD (state, { auditId }) {
			const thisAuditIndex = state.auditsOpened.findIndex(audit => audit.id === auditId);
			const thisAudit = state.auditsOpened[thisAuditIndex];
			thisAudit.graphs.push({
				id: null,
				chartType: null,
				dashboardOptions: [],
				title: null,
				questionId: null,
				questions: [],
				roleId: [],
			});
		},
		GRAPH_ADD_QUESTIONS (state, { auditId, questionsIds }) {
			const thisAuditIndex = state.auditsOpened.findIndex(audit => audit.id === auditId);
			const thisAudit = state.auditsOpened[thisAuditIndex];
			thisAudit.graphs = [
				...thisAudit.graphs,
				...questionsIds.map(questionId => {
					return {
						id: null,
						chartType: null,
						dashboardOptions: [],
						title: null,
						questionId,
						questions: [],
						roleId: [],
					};
				}),
			];
		},
		GRAPH_REMOVE (state, { auditId, graphIndex }) {
			const thisAuditIndex = state.auditsOpened.findIndex(audit => audit.id === auditId);
			const thisAudit = state.auditsOpened[thisAuditIndex];
			thisAudit.graphs.splice(graphIndex, 1);
		},
		GRAPH_SORT (state, { auditId, graphs }) {
			const auditIndex = state.auditsOpened.findIndex(audit => audit.id === auditId);
			state.auditsOpened[auditIndex].graphs = graphs;
		},
		GRAPH_UPDATE_FIELD (state, { auditId, graphIndex, key, value }) {
			const thisAuditIndex = state.auditsOpened.findIndex(audit => audit.id === auditId);
			const thisAudit = state.auditsOpened[thisAuditIndex];
			thisAudit.graphs[graphIndex][key] = value;

			state.auditsOpened[thisAuditIndex].graphs[graphIndex][key] = value;
		},
		GRAPH_FILTER_QUESTION_ADD (state, { auditId, graphIndex }) {
			const thisAuditIndex = state.auditsOpened.findIndex(audit => audit.id === auditId);
			const thisAudit = state.auditsOpened[thisAuditIndex];
			// const thisGraphIndex = thisAudit.graphs.findIndex(graph => graph.id === graphId);
			const thisGraph = thisAudit.graphs[graphIndex];
			thisGraph.questions.push({ id: '', options: [] });
		},
		GRAPH_FILTER_QUESTION_CHANGE (state, { auditId, graphIndex, questionIndex, questionId }) {
			const thisAuditIndex = state.auditsOpened.findIndex(audit => audit.id === auditId);
			const thisAudit = state.auditsOpened[thisAuditIndex];
			// const thisGraphIndex = thisAudit.graphs.findIndex(graph => graph.id === graphId);
			const thisGraph = thisAudit.graphs[graphIndex];
			thisGraph.questions[questionIndex] = { id: questionId, options: [] };
		},
		GRAPH_FILTER_QUESTION_REMOVE (state, { auditId, graphIndex, questionIndex }) {
			const thisAuditIndex = state.auditsOpened.findIndex(audit => audit.id === auditId);
			const thisAudit = state.auditsOpened[thisAuditIndex];
			// const thisGraphIndex = thisAudit.graphs.findIndex(graph => graph.id === graphId);
			const thisGraph = thisAudit.graphs[graphIndex];
			thisGraph.questions.splice(questionIndex, 1);
		},
		GRAPH_FILTER_ANSWER_ADD (state, { auditId, graphIndex, optionId, questionIndex }) {
			const thisAuditIndex = state.auditsOpened.findIndex(audit => audit.id === auditId);
			const thisAudit = state.auditsOpened[thisAuditIndex];
			// const thisGraphIndex = thisAudit.graphs.findIndex(graph => graph.id === graphId);
			const thisGraph = thisAudit.graphs[graphIndex];
			thisGraph.questions[questionIndex].options.push(optionId);
		},
		GRAPH_FILTER_ANSWER_REMOVE (state, { auditId, graphIndex, optionId, questionIndex }) {
			const thisAuditIndex = state.auditsOpened.findIndex(audit => audit.id === auditId);
			const thisAudit = state.auditsOpened[thisAuditIndex];
			// const thisGraphIndex = thisAudit.graphs.findIndex(graph => graph.id === graphId);
			const thisGraph = thisAudit.graphs[graphIndex];
			const thisGraphOptions = thisGraph.questions[questionIndex].options;
			thisGraphOptions.splice(thisGraphOptions.indexOf(optionId), 1);
		},
	},
	actions: {
		getGraphs ({ commit, getters }, { auditId }) {
			return $axios.get(`/dashboard/${ auditId }`, { params: { inactive: true } }).then(resp => {
				commit('AUDIT_OPEN', { id: auditId, graphs: formatAuditPayload(resp.data.payload, auditId, getters) });
			}).catch(() => {
				new TempMessage({
					html: `<p>${ t('Nastavení auditu pro dashboard se nepodařilo načíst.') }</p>`,
					type: 'error',
				});
			});
		},
		addGraphsForAllQuestions ({ commit, rootGetters }, { auditId }) {
			const detail = rootGetters['audits/auditDetail'](auditId);
			const questionsIds = detail?.questions?.map(question => question.id) ?? [];
			commit('GRAPH_ADD_QUESTIONS', { auditId, questionsIds });
		},
		saveAudit ({ dispatch }, { auditId, graphs }) {
			return $axios.put(`/dashboard/${ auditId }`, { questions: graphs }).then(() => {
				dispatch('getGraphs', { auditId });
				new TempMessage({
					html: `<p>${ t('Grafy pro dotazník byly uloženy.') }</p>`,
					type: 'success',
				});
			}).catch((err) => {
				new TempMessage({
					html: `<p>${ t('Grafy pro dotazník se nepodařilo uložit.') } ${ errorMessageHandeling(err.response.data.code) }</p>`,
					type: 'error',
				});
			});
		},
	},
};
