import $axios from '../../plugins/axios';
import { buildModule, createFiltersModule, createPaginationModule, defineArrayFilter, defineSingleFilter } from '../utils';
import { Api, Event, GetEventsParams, GetPosParams, PaginatorResult, Pos } from '~/api';
import { fetchAllPages } from '~/helpers';

const api = $axios as Api;

export default buildModule()
	.withSubmodules({
		filters: createFiltersModule({
			filters: {
				admin: defineArrayFilter('admin', Number),
				searchPos: defineSingleFilter('pos', String),
				roleId: defineArrayFilter('role', Number),
				date: defineSingleFilter('date', String),
				orderBy: defineSingleFilter('orderBy', String),
				orderAsc: defineSingleFilter('orderAsc', Boolean, false),
				eventType: defineArrayFilter('eventType', Number),
			},
		}),
		pagination: createPaginationModule({
			pageQueryKey: 'page',
			pageSizeQueryKey: 'itemsPerPage',
			itemCountGetter: 'planner/activities/activitiesTotalCount',
		}),
	})
	.withState({
		activities: [] as Event[],
		poses: [] as Pos[],
		activitiesPaginator: null as PaginatorResult | null,
		activitiesLoading: false,
	})
	.withGetters({
		activities: state => state.activities,
		posIds: state => state.poses.map(pos => pos.id),
		activitiesPaginator: state => state.activitiesPaginator,
		activitiesTotalCount: state => state.activitiesPaginator?.totalCount,
		activitiesLoading: state => state.activitiesLoading,
	})
	.withMutations({
		ACTIVITIES_SUCCESS (state, events: Event[]) {
			state.activities = events;
		},

		ACTIVITIES_PAGINATION (state, paginator: PaginatorResult) {
			state.activitiesPaginator = paginator;
		},

		ACTIVITIES_LOADING (state, isLoading: boolean) {
			state.activitiesLoading = isLoading;
		},

		ACTIVITIES_POSES (state, poses: Pos[]) {
			state.poses = poses;
		},
	})
	.withActions({
		async getActivities ({ commit }, { params = {} }: { params?: GetEventsParams }) {
			commit('ACTIVITIES_LOADING', true);

			const { data } = await api.get('/events', {
				params: params,
			});

			commit('ACTIVITIES_SUCCESS', data.payload);
			commit('ACTIVITIES_PAGINATION', data.paginator);

			commit('ACTIVITIES_LOADING', false);
		},

		async getPoses ({ commit }, params: GetPosParams = {}) {
			const poses = await fetchAllPages('/pos', params)<Pos>(api);
			commit('ACTIVITIES_POSES', poses);
		},
	});
