export type Dict<T> = Record<string, T>;

/**
 * @example
 * createDict([ 'foobar', 'qux', 'fizz', 'buzz' ], str => str.length)
 * // { 6: 'foobar', 3: 'qux', 4: 'buzz' }
 */

export function createDict<T> (items: T[], keyFn: (item: T) => string | number): Dict<T> {
	return Object.fromEntries(items.map(item => [ keyFn(item), item ]));
}

/**
 * @example
 * createIndex([ 'foobar', 'qux', 'fizz', 'buzz' ], str => str.length)
 * // { 6: [ 'foobar' ], 3: [ 'qux' ], 4: [ 'fizz', 'buzz' ] }
 * createIndex([ 'a', 'b', 'cd', 'abc' ], str => [ ...str ])
 * // { a: [ 'a', 'abc' ], b: [ 'b', 'abc' ], c: [ 'cd', 'abc' ], d: [ 'cd' ] }
 */

export function createIndex<T> (
	items: T[],
	getItemKey: (item: T) => string | string[] | number | number[],
): Dict<T[]> {
	return items
		.map(item => [ getItemKey(item), item ] as const)
		.flatMap(([ key, item ]) => (
			Array.isArray(key)
				? key.map(singleKey => [ singleKey, item ] as const)
				: [ [ key, item ] as const ]
		))
		.reduce<Dict<T[]>>((acc, [ key, item ]) => ({
			...acc,
			[key]: [ ...(acc[key] ?? []), item ],
		}), {});
}

/**
 * @example
 * removeKey({ adam: 22, brie: 14, chad: 53 }, 'brie')
 * // { adam: 22, chad: 53 }
 */

export function removeKey<T> (dict: Dict<T>, key: string | number): Dict<T> {
	const { [key]: _, ...rest } = dict;
	return rest;
}

/**
 * @example
 * mapDict({ alice: 12, bob: 42, clair: 17 }, age => age >= 18)
 * // { alice: false, bob: true, clair: false }
 */

export function mapDict<T, U> (dict: Dict<T>, mapFn: (value: T) => U): Dict<U> {
	const mappedEntries = Object
		.entries(dict)
		.map(([ key, value ]) => [ key, mapFn(value) ]);

	return Object.fromEntries(mappedEntries);
}

/**
 * @example
 * fromKeyValueArrays(
 *     [ 'foo', 42, 42, 'bar', 'foo' ],
 *     [ 'lorem', 'ipsum', 'dolor', 'sit', 'amet' ],
 * )
 * // { foo: [ 'lorem', 'amet' ], 42: [ 'ipsum', 'dolor' ], bar: [ 'sit' ] }
 */

export function fromKeyValueArrays<T> (keys: unknown[], values: T[]): Dict<T[]> {
	return keys
		.map(String)
		.reduce<Dict<T[]>>((dict, key, i) => (
			values[i] != null
				? { ...dict, [key]: [ ...(dict[key] ?? []), values[i] ] }
				: dict
		), {});
}

/**
 * @example
 * toKeyValueArrays({
 *     foo: [ 'lorem', 'amet' ],
 *     42: [ 'ipsum', 'dolor' ],
 *     bar: [ 'sit' ],
 * })
 * // [
 * //     [ 'foo', '42', '42', 'bar', 'foo' ],
 * //     [ 'lorem', 'ipsum', 'dolor', 'sit', 'amet' ],
 * // ]
 */

export function toKeyValueArrays<T> (dict: Dict<T[]>): [string[], T[]] {
	const entries = Object.entries(dict);
	const keys = entries.flatMap(([ key, value ]) => value.map(() => key));
	const values = entries.flatMap(([ , value ]) => value);
	return [ keys, values ];
}

export function insertDictItem<T> (itemKey: (item: T) => number | string): (
	<U extends T>(dict: Dict<U>, item: U) => Dict<U>
) {
	return (dict, item) => ({ ...dict, [itemKey(item)]: item });
}
