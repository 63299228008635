import { AdminInfo, AdminInfoValue, AdminInfoValueRequest } from '~/api';

export type InfoValueEntry = Omit<AdminInfoValue, 'id'>;

export function mapAdminInfoToInfoValueEntries (adminInformations: AdminInfo[]): InfoValueEntry[] {
	return adminInformations.map(adminInfo => ({ adminInfo, value: '', valueId: null }));
}

export function mapInfoValueEntryToRequest (infoValue: InfoValueEntry): AdminInfoValueRequest {
	const { adminInfo, value, valueId } = infoValue;

	switch (adminInfo.valueType) {
	case 'list':
		return {
			adminInfo: adminInfo.id,
			adminInfoListItemId: valueId,
			value: null,
		};
	default:
		return {
			adminInfo: adminInfo.id,
			adminInfoListItemId: null,
			value: value !== '' ? value : null,
		};
	}
}

export function fillEmptyInfoValueEntries (
	infoValueFields: InfoValueEntry[],
	fillValues: AdminInfoValue[],
): InfoValueEntry[] {
	return infoValueFields.map(field => {
		const foundValue = fillValues.find(value => value.adminInfo.id === field.adminInfo.id);

		return foundValue !== undefined
			? { ...field, value: foundValue.value, valueId: foundValue.valueId }
			: field;
	});
}

export function updateInfoValueEntry (fields: InfoValueEntry[], newValue: InfoValueEntry): InfoValueEntry[] {
	return fields.map(item => (item.adminInfo.id === newValue.adminInfo.id ? newValue : item));
}
