import * as Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import { stopMessaging } from './helpers';

import registerPlugins from './plugins';
import registerComponents from './register-components.js';

const vueAppEl = document.getElementById('vue-init');
if (vueAppEl) {
	const app = Vue.createApp(App).use(store).use(router);
	registerPlugins(app);
	registerComponents(app);
	app.mount('#vue-init');
}
else {
	stopMessaging();
}
