import { Api, File } from '~/api';
import { Dict } from '~/helpers';
import $axios from '~/plugins/axios';
import { buildModule } from '../utils';

const api = $axios as Api;

export default buildModule()
	.withState({
		archives: {} as Dict<File>,
		loading: false,
	})
	.withGetters({
		byAuditItemId: state => (id: number) => state.archives[id],
		loading: state => state.loading,
	})
	.withMutations({
		SET_LOADING (state, loading: boolean) {
			state.loading = loading;
		},
		STORE_ARCHIVE (state, [ auditItemId, archive ]: [number, File]) {
			state.archives = { ...state.archives, [auditItemId]: archive };
		},
	})
	.withActions({
		async get ({ commit }, auditItemId: number) {
			commit('SET_LOADING', true);
			const { data } = await api.get(`/audit-item/${ auditItemId }/images-archive`);
			commit('STORE_ARCHIVE', [ auditItemId, data.payload ]);
			commit('SET_LOADING', false);
		},
	});
