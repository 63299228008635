<template>
	<div class="tray">
		<div class="tray__admin-name">
			{{ props.adminName }}
		</div>
		<div class="tray__items">
			<slot />
		</div>
	</div>
</template>

<script setup lang="ts">
type Props = {
	adminName: string;
};

const props = defineProps<Props>();
</script>

<style scoped lang="scss">
.tray {
	display: flex;
	gap: 1rem;
	justify-content: center;
	align-items: center;
	height: 100%;

	&__admin-name {
		color: $black;
		font-weight: 500;
		font-size: 1.4rem;
	}

	&__items {
		display: flex;
		height: 100%;
	}
}
</style>
