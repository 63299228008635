import $axios from '~/plugins/axios';

import { Api, GetTerritoryParams, Territory } from '~/api';
import { buildModule } from '../utils';
import { createDict, Dict } from '~/helpers';

const api = $axios as Api;

type LazyLoadingParams = Omit<GetTerritoryParams, 'limit' | 'offset'>;

export default buildModule()
	.withState({
		territoryById: {} as Dict<Territory>,
		orderedIds: [] as number[],
		params: {} as LazyLoadingParams,
	})
	.withGetters({
		items: (state) => state.orderedIds.map(id => state.territoryById[id]),
		byId: (state) => (id: number) => state.territoryById[id],
	})
	.withMutations({
		RESET (state, params: LazyLoadingParams = {}) {
			state.params = params;
			state.orderedIds = [];
		},
		APPEND_TERRITORIES (state, territories: Territory[]) {
			state.territoryById = { ...state.territoryById, ...createDict(territories, ({ id }) => id) };
			state.orderedIds = Array.from(new Set([
				...state.orderedIds,
				...territories.map((territory) => territory.id),
			]));
		},
	})
	.withActions({
		async getMore ({ state, commit }, limit: number | null = 20) {
			const params: GetTerritoryParams = { ...state.params, limit, offset: state.orderedIds.length };
			const { data } = await api.get('/territory', { params });

			commit('APPEND_TERRITORIES', data.payload);
		},
		async prefetchTerritories ({ commit }, territoryIds: number[]) {
			const territoryGetRequests = territoryIds.map((id) => api.get(`/territory/${ id }`));
			const territoryResponses = await Promise.all(territoryGetRequests);

			const territories = territoryResponses.map((resp) => resp.data.payload);

			commit('APPEND_TERRITORIES', territories);
		},
		resetParams ({ state, commit }, params: LazyLoadingParams = {}) {
			commit('RESET', { ...state.params, ...params });
		},
	});
