import $axios from '~/plugins/axios';

import { Api, GetProductCategoriesParams, ProductCategory } from '~/api';
import { buildModule } from '../utils';
import { createDict, Dict } from '~/helpers';

const api = $axios as Api;

export default buildModule()
	.withState({
		productCategoryById: {} as Dict<ProductCategory>,
		items: [] as ProductCategory[],
	})
	.withGetters({
		items: (state) => Object.values(state.productCategoryById),
		byId: (state) => (id: number) => state.productCategoryById[id],
	})
	.withMutations({
		SET (state, productCategories: ProductCategory[]) {
			state.productCategoryById = { ...state.productCategoryById, ...createDict(productCategories, ({ id }) => id) };
		},
	})
	.withActions({
		async get ({ commit }, params: GetProductCategoriesParams = {}) {
			const { data } = await api.get('/product-categories', { params });
			commit('SET', data.payload);
		},
	});
