import moment from 'moment';
import { Filter, FiltersResponse } from '~/api';
import $axios from '~/plugins/axios';
import { createFiltersModule, createPaginationModule, defineArrayFilter, defineSingleFilter, buildModule } from '../utils';
import type { Validation } from '@vuelidate/core';

const DATE_FORMAT = 'YYYY-MM-DD';
const START_DATE = moment().add(-1, 'M').startOf('M').format(DATE_FORMAT);
const END_DATE = moment().add(1, 'M').endOf('M').format(DATE_FORMAT);

export default buildModule()
	.withSubmodules({
		filters: createFiltersModule({
			filters: {
				adminId: defineArrayFilter('admin', Number),
				auditId: defineSingleFilter('audit', Number),
				areaId: defineSingleFilter('area', Number),
				posId: defineArrayFilter('pos', Number),
				roleId: defineArrayFilter('role', Number),
				regionId: defineSingleFilter('region', Number),
				territoryId: defineSingleFilter('territory', Number),
				startDate: defineSingleFilter('start', String, START_DATE),
				endDate: defineSingleFilter('end', String, END_DATE),
				customFilterIds: defineArrayFilter('filterId', Number),
				customFilterValues: defineArrayFilter('filterValue', String),
			},
		}),
		adminStatsPagination: createPaginationModule({
			pageQueryKey: 'adminsPage',
			pageSizeQueryKey: 'adminsPerPage',
			itemCountGetter: 'planner/dashboard/adminStatsCount',
			defaultPageSize: 5,
		}),
	})
	.withState({
		customFilters: [] as Filter[],
		filterValidator: null as Validation | null,
	})
	.withGetters({
		customFilters: state => state.customFilters,
		filterValidator: state => state.filterValidator,
	})
	.withMutations({
		CUSTOM_FILTERS_SUCCESS (state, value: Filter[]) {
			state.customFilters = value;
		},
		VALIDATOR_INSTANCE (state, instance: Validation) {
			state.filterValidator = instance;
		},
	})
	.withActions({
		async getCustomFilters ({ commit }) {
			const params = { key: 'dashboard' };
			const resp = await $axios.get<FiltersResponse>('/filter', { params });
			commit('CUSTOM_FILTERS_SUCCESS', resp.data.payload);
		},
	});
