import { cacheUntilDistinct, deepEqual, Dict, isEmpty, mapDict, toArray } from '~/helpers';
import { buildModule, RootDispatch } from './module';
import { RouteQuery, RouteQueryUpdate, ROUTE_NAMESPACE } from './route';

export type FilterDefinition<I, O = I> = {
	queryKey: string;
	parse: (queryValue: string | string[]) => O;
	serialize: (filterValue: I | null) => string | string[] | null;
};

export type FilterValues<D> = {
	[K in keyof D]: D[K] extends FilterDefinition<infer T> ? T : never;
};

export function createFiltersModule<
	D extends Dict<FilterDefinition<never, unknown>>,
> (config: { filters: D; replaceRoute?: boolean }) {
	const getQuery = (rootGetters: Dict<unknown>) => (
		rootGetters[`${ ROUTE_NAMESPACE }/query`] as RouteQuery
	);

	const shouldReplaceRoute = config.replaceRoute ?? false;
	const filterDefs = config.filters;
	const cacheFilterValues = cacheUntilDistinct<FilterValues<D>>(deepEqual);

	return buildModule()
		.withGetters({
			values (_state, _getters, _rootState, rootGetters): FilterValues<D> {
				const query = getQuery(rootGetters);

				const filterValues = mapDict(filterDefs, ({ queryKey, parse }) => (
					parse(query[queryKey])
				)) as FilterValues<D>;

				return cacheFilterValues(filterValues);
			},
			someActive (_state, _getters, _rootState, rootGetters): boolean {
				const query = getQuery(rootGetters);

				return Object
					.values(filterDefs)
					.some(({ queryKey }) => queryKey in query);
			},
			someActiveExcept: (_s, _g, _r, rootGetters) => (excludeFilters: string[]) => {
				const query = getQuery(rootGetters);

				return Object
					.values(filterDefs)
					.some(({ queryKey }) => !excludeFilters.includes(queryKey) && queryKey in query);
			},
		})
		.withActions({
			async update ({ dispatch }, filtersUpdate: Partial<FilterValues<D>>) {
				const updateEntries = Object.entries(filtersUpdate);

				updateEntries
					.filter(([ key ]) => !Object.hasOwn(filterDefs, key))
					.forEach(([ key, value ]) => {
						console.error(`Setting value ${ value } to unknown filter ${ key }`);
					});

				const queryUpdate = Object.fromEntries((
					updateEntries
						.filter(([ key ]) => Object.hasOwn(filterDefs, key))
						.map(([ key, value ]) => {
							const { queryKey, serialize } = filterDefs[key];
							return [ queryKey, serialize(value) ];
						})
				));

				await dispatchQueryUpdate(dispatch, queryUpdate, shouldReplaceRoute);
			},
			async reset ({ dispatch }) {
				const queryUpdate = Object.fromEntries((
					Object
						.values(filterDefs)
						.map(({ queryKey }) => [ queryKey, null ])
				));

				await dispatchQueryUpdate(dispatch, queryUpdate, shouldReplaceRoute);
			},
		});
}

export function defineSingleFilter <T> (
	queryKey: string,
	type: (value: string) => T,
): FilterDefinition<T | null>
export function defineSingleFilter <T> (
	queryKey: string,
	type: (value: string) => T,
	defaultValue: T,
): FilterDefinition<T>
export function defineSingleFilter <T> (
	queryKey: string,
	type: (value: string) => T,
	defaultValue: T | null = null,
): FilterDefinition<T | null> {
	return {
		queryKey,
		parse: queryValue => (
			toArray(queryValue).map(type).at(-1) ?? defaultValue
		),
		serialize: filterValue => (
			filterValue !== false && !isEmpty(filterValue)
				? String(filterValue)
				: null
		),
	};
}

export function defineArrayFilter <T> (
	queryKey: string,
	type: (value: string) => T,
): FilterDefinition<T[]> {
	return {
		queryKey,
		parse: queryValue => toArray(queryValue).map(type),
		serialize: filterValue => toArray(filterValue).map(String),
	};
}

async function dispatchQueryUpdate (
	dispatch: RootDispatch,
	query: RouteQueryUpdate,
	replaceRoute: boolean,
): Promise<void> {
	const routeUpdate = { query, replaceRoute, mergeQuery: true };
	await dispatch(`${ ROUTE_NAMESPACE }/update`, routeUpdate, { root: true });
}
