import $axios from '~/plugins/axios';

import { Api, PaginatorResult, GetTaskParams, TaskLight } from '~/api';
import { buildModule, createPaginationModule } from '../utils';
import { Dict, createDict } from '~/helpers';

const api = $axios as Api;

export default buildModule()
	.withSubmodules({
		pagination: createPaginationModule({
			pageQueryKey: 'tasksPage',
			pageSizeQueryKey: 'tasksPerPage',
			itemCountGetter: 'tasks/list/totalCount',
		}),
	})
	.withState({
		storedTasks: {} as Dict<TaskLight>,
		tasks: [] as TaskLight[],
		loading: false,
		paginator: null as PaginatorResult | null,
	})
	.withGetters({
		byId: state => (taskId: number) => (
			state.storedTasks[taskId] ?? null
		),
		page: state => state.tasks,
		loading: state => state.loading,
		totalCount: state => state.paginator?.totalCount ?? 0,
	})
	.withMutations({
		SET_STORED_TASKS (state, tasks: TaskLight[]) {
			state.storedTasks = {
				...state.storedTasks,
				...createDict(tasks, (task) => task.id),
			};
		},
		SET_TASKS (state, taskIds: number[]) {
			state.tasks = taskIds.map(id => state.storedTasks[id]);
		},
		SET_LOADING (state, loading: boolean) {
			state.loading = loading;
		},
		SET_PAGINATOR (state, paginator: PaginatorResult) {
			state.paginator = paginator;
		},
	})
	.withActions({
		async get ({ commit }, params: GetTaskParams = {}) {
			commit('SET_LOADING', true);
			const { data } = await api.get('/task', { params });
			commit('SET_LOADING', false);

			const tasks = data.payload;
			const taskIds = tasks.map(task => task.id);

			commit('SET_STORED_TASKS', tasks);
			commit('SET_TASKS', taskIds);
			commit('SET_PAGINATOR', data.paginator);
		},
	});
