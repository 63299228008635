import { buildModule, createFiltersModule, defineSingleFilter } from '../utils';

import detail from './detail';
import list from './list';

export default buildModule()
	.withSubmodules({
		detail,
		list,
		filters: createFiltersModule({
			filters: {
				typeId: defineSingleFilter('type', Number),
				statusId: defineSingleFilter('status', Number),
				state: defineSingleFilter('state', String),
				assigned: defineSingleFilter('assigned', String),
				showAll: defineSingleFilter('showAll', Boolean, false),
				orderBy: defineSingleFilter('orderBy', String),
				orderAsc: defineSingleFilter('orderAsc', Boolean, false),
			},
		}),
	});
