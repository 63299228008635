import { Api, Filter, GetInfoExtraInExportParams, InfoExtraInExportRequest } from '~/api';
import { Dict } from '~/helpers';
import $axios from '~/plugins/axios';
import { buildModule } from '../utils';

const api = $axios as Api;

export default buildModule()
	.withState({
		exportTypeKeysById: {} as Dict<string[]>,
	})
	.withGetters({
		exportTypeKeysById: state => (id: number) => state.exportTypeKeysById[id],
	})
	.withMutations({
		STORE_EXPORT_TYPE_KEYS (state, [ adminInfoId, payload ]: [number, Filter[]]) {
			state.exportTypeKeysById = {
				...state.exportTypeKeysById,
				[adminInfoId]: payload.map(({ key }) => key),
			};
		},
	})
	.withActions({
		async getExportTypeKeys ({ commit }, { adminInfoId }: {
			adminInfoId: number;
		}) {
			const params: GetInfoExtraInExportParams = { adminInfoId };
			const { data } = await api.get('/info-extra/in-export', { params });
			commit('STORE_EXPORT_TYPE_KEYS', [ adminInfoId, data.payload ]);
		},
		async updateExportTypeKeys ({ commit }, { adminInfoId, keys }: {
			adminInfoId: number;
			keys: InfoExtraInExportRequest['keys'];
		}) {
			const body: InfoExtraInExportRequest = { adminInfoId, keys, posInfoId: null };
			const { data } = await api.put('/info-extra/in-export', body);
			commit('STORE_EXPORT_TYPE_KEYS', [ adminInfoId, data.payload ]);
		},
	});
