import writeXlsxFile from 'write-excel-file';
import Highcharts, { Chart } from 'highcharts';
import type { DashboardQuestion } from '~/api';
import { IconName } from './icons';
import { t } from 'i18next';

type ColumnOption = {
	id: string;
	width?: number;
	name: string;
};

export type ChartTypeId = Exclude<DashboardQuestion['chartType'], null>;

export type ChartType = {
	icon: IconName;
	id: ChartTypeId;
	title: string;
}

export const CHART_TYPES: ChartType[] = [
	{
		icon: 'chart-pie-small',
		id: 'pie',
		title: t('Koláčový'),
	}, {
		icon: 'chart-column-small',
		id: 'column',
		title: t('Sloupcový'),
	},
];

export const createSheetDataFromChart = (chart: Chart, columnOptions: ColumnOption[]) => {
	const ids = columnOptions.map(({ id }) => id);
	const [ headerRow, ...dataRows ] = chart.getDataRows(true).slice(1);

	return [
		headerRow.map((value) => ({ value: columnOptions.find(columnOption => columnOption.id === value)?.name })),
		...dataRows.map(row => row
			.filter((_, i) => columnOptions.some(columnOption => columnOption.id === headerRow[i]))
			.sort((a, b) => ids.findIndex(id => id === headerRow[row.indexOf(a)]) - ids.findIndex(id => id === headerRow[row.indexOf(b)]))
			.map((value) => ({ value }))),
	];
};

export const hcXlsxExport = async (H: typeof Highcharts, { columnOptions }: {
	columnOptions: ColumnOption[];
}) => {
	const options = H.getOptions();

	if (options.exporting == null) {
		console.error('Exporting module not loaded');
		return;
	}

	H.Chart.prototype.downloadXLSX = async function () {
		if (this.options.exporting == null) {
			console.error('Exporting module not loaded');
			return;
		}

		const fileName = this.options.exporting?.filename ?? 'chart';
		const columns = columnOptions.map(({ width }) => ({ width }));
		const sheetData = createSheetDataFromChart(this, columnOptions);

		await writeXlsxFile(sheetData, {
			fileName,
			stickyRowsCount: 1,
			columns,
		});
	};

	if (options.lang != null) {
		options.lang.downloadXLSX = 'Download XLSX';
	}

	if (options.exporting.menuItemDefinitions != null) {
		options.exporting.menuItemDefinitions.downloadXLSX = {
			textKey: 'downloadXLSX',
			onclick: function () {
				this.downloadXLSX();
			},
		};
	}
};
