import { Api } from '~/api';
import $axios from '~/plugins/axios';
import { buildModule } from '../utils';
import { initMessaging, Message } from '~/helpers';

declare const window: Window & { Notification?: typeof Notification };

const api = $axios as Api;

export default buildModule()
	.withState({
		permission: window.Notification?.permission ?? 'denied',
	})
	.withGetters({
		permitted: state => state.permission === 'granted',
		denied: state => state.permission === 'denied',
	})
	.withMutations({
		SET_PERMISSION (state, permission: NotificationPermission) {
			state.permission = permission;
		},
	})
	.withActions({
		async init ({ state }, { handleMessage }: {
			handleMessage: (message: Message) => void;
		}) {
			if (state.permission !== 'granted') {
				return;
			}

			const { data } = await api.get('/settings');
			if (data.payload.firebase.web == null) {
				console.error('Firebase options not received');
				return;
			}

			await initMessaging({
				options: data.payload.firebase.web,
				submitToken: token => api.post('/admin/device', { token }),
				handleMessage,
			});
		},
		async requestPermission ({ commit }) {
			if (window.Notification == null) {
				console.error('Notifications not supported by this browser');
				return;
			}

			const permission = await window.Notification.requestPermission();
			commit('SET_PERMISSION', permission);
		},
	});
