<template>
	<div
		class="pin"
		:style="{ '--color': props.color }"
	>
		<div
			v-if="props.ripple"
			class="pin__ripple"
		/>
	</div>
</template>

<script setup lang="ts">
type Props = {
	color: string;
	ripple?: boolean;
};

const props = defineProps<Props>();
</script>

<style scoped lang="scss">
@keyframes ripple {
	from {
		opacity: 0.8;
		scale: 1;
	}
	to {
		opacity: 0;
		scale: 3;
	}
}

.pin {
	width: 0.8rem;
	height: 0.8rem;
	border-radius: 50%;
	background-color: var(--color);

	&__ripple {
		height: 100%;
		border-radius: 50%;
		box-shadow: 0 0 0 1px var(--color);
		animation: ripple 1s ease-out infinite;
	}
}
</style>
