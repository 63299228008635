import { Api, AuditLight, AuditsResponse, GetAuditParams } from '~/api';
import { createDict, Dict } from '~/helpers';
import $axios from '~/plugins/axios';
import { buildModule } from '../utils';

const api = $axios as Api;

export type LazyLoadedAuditsParams = Omit<GetAuditParams, 'limit' | 'offset'>;

export default buildModule()
	.withState({
		params: {} as LazyLoadedAuditsParams,
		itemIds: [] as number[],
		itemById: {} as Dict<AuditLight>,
		totalCount: Infinity,
	})
	.withGetters({
		params: state => state.params,
		items: state => state.itemIds.map(id => state.itemById[id]),
		itemById: state => (id: number): AuditLight | null => state.itemById[id] ?? null,
	})
	.withMutations({
		RESET (state, params: LazyLoadedAuditsParams) {
			state.params = params;
			state.itemIds = [];
			state.totalCount = Infinity;
		},
		APPEND_ITEMS (state, { payload, paginator }: AuditsResponse) {
			state.itemIds = [ ...state.itemIds, ...payload.map(({ id }) => id) ];
			state.itemById = { ...state.itemById, ...createDict(payload, ({ id }) => id) };
			state.totalCount = paginator.totalCount;
		},
	})
	.withActions({
		resetParams ({ commit }, params: LazyLoadedAuditsParams) {
			commit('RESET', params);
		},
		async getMore ({ state, commit }, limit: number) {
			if (state.itemIds.length >= state.totalCount) {
				return;
			}

			const params = { ...state.params, limit, offset: state.itemIds.length };
			const { data } = await api.get('/audit', { params });
			commit('APPEND_ITEMS', data);
		},
	});
