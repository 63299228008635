// Nastroje - uzivatelske role a opravneni
document.addEventListener('DOMContentLoaded', function () {
	if (!document.getElementById('role-permissions')) { return; }

	// Prepinani role
	var amountColumns = document.querySelectorAll('#table-role tr th');
	var btnPrev = document.querySelectorAll('.btn-prev');
	var btnNext = document.querySelectorAll('.btn-next');
	var counter = 1;
	var lengthCol = amountColumns.length;

	var hideColumns = function (indHide) {
		amountColumns.forEach(function (amountColumn, i) {
			amountColumn.classList.add('role-column-' + i);
			if (amountColumn.classList.contains('role-column-0')) {
				amountColumn.style.display = 'table-cell';
			}
			if (i == indHide && indHide < amountColumns.length) {
				amountColumn.style.display = 'table-cell';
			}

			var allColumns = document.querySelectorAll('#table-role tr td:nth-child(' + (1 + i) + ')');
			allColumns.forEach(function (item) {
				item.classList.add('role-column-' + i);
				if (item.classList.contains('role-column-0')) {
					item.style.display = 'table-cell';
				}
				if (i == indHide && indHide < amountColumns.length) {
					item.style.display = 'table-cell';
				}
			});
		});
	};

	var moveColumns = function () {
		amountColumns.forEach(function (amountColumn, i) {
			amountColumn.classList.add('role-column-' + i);
			amountColumn.style.display = 'none';
			if (amountColumn.classList.contains('role-column-0')) {
				amountColumn.style.display = 'table-cell';
			}

			var allColumns = document.querySelectorAll('#table-role tr td:nth-child(' + (1 + i) + ')');
			allColumns.forEach(function (item) {
				item.classList.add('role-column-' + i);
				item.style.display = 'none';
				if (item.classList.contains('role-column-0')) {
					item.style.display = 'table-cell';
				}
			});
		});
	};

	moveColumns();
	hideColumns(counter);

	btnNext.forEach(function (el, i) {
		btnNext[i].addEventListener('click', function (e) {
			e.preventDefault();

			if (counter <= (lengthCol - 1)) {
				moveColumns();
				hideColumns(counter += 1);
			}
			if (counter == lengthCol) {
				counter = 0;
				moveColumns();
				hideColumns(counter += 1);
			}
		});

		btnPrev[i].addEventListener('click', function (e) {
			e.preventDefault();

			if (counter < lengthCol) {
				moveColumns();
				hideColumns(counter -= 1);
			}
			if (counter == 0) {
				counter = lengthCol;
				moveColumns();
				hideColumns(counter -= 1);
			}
		});
	});


	// schovavani/ukazovani sekci
	var headerRows = document.querySelectorAll('tr.header-row td:first-child');
	headerRows.forEach(row => {
		row.addEventListener('click', e => {
			e.preventDefault();

			let elementParentName = e.target.parentElement.getAttribute('name');
			let nameAttr = elementParentName ? elementParentName : e.target.getAttribute('name');
			let bodyElements = document.querySelectorAll(`tr.body-row td[name="${ nameAttr }"]`);

			bodyElements.forEach(item => {
				var bodyRow = item.parentElement;

				if (bodyRow.classList.contains('hidden')) {
					bodyRow.classList.remove('hidden');
					row.querySelector('svg').classList.add('arrow-up');
				}
				else {
					bodyRow.classList.add('hidden');
					row.querySelector('svg').classList.remove('arrow-up');
				}
			});
		});
	});

	// centralni ocicko - vychozi poloha (checked/unchecked)
	var rows = document.querySelectorAll('#table-role tbody tr');
	var columns = rows[0].querySelectorAll('td');

	for (var colIndex = 1; colIndex < columns.length; colIndex++) {
		var headerInput;

		// TODO this should be refactored to a part of the Vue app ASAP; no point fixing ESLint errors
		// eslint-disable-next-line no-loop-func
		rows.forEach((row, index) => {
			var columnRow = row.querySelectorAll('td')[colIndex];
			var headerRow = row.classList.contains('header-row');
			var bodyInput = !headerRow ? columnRow.querySelector('input') : null;
			let rowPrevious = rows[index - 1];
			var bodyInputPrevious;

			if (!headerRow && !rowPrevious.classList.contains('header-row')) {
				bodyInputPrevious = rowPrevious.querySelectorAll('td')[colIndex].querySelector('input');
			}

			if (headerRow) {
				headerInput = columnRow.querySelector('input');
				headerInput.addEventListener('click', e => checkAll(e));
			}
			else {
				bodyInput.addEventListener('click', (e) => headerInputInteractive(e));
				let isChecked = bodyInput.checked;

				if ((isChecked && (bodyInputPrevious && !bodyInputPrevious.checked)) ||
				(!isChecked && (bodyInputPrevious && bodyInputPrevious.checked))) {
					headerInput.dataset.checkedPartial = '';
					headerInput.checked = false;
				}
				else if (!bodyInput.checked) {
					headerInput.checked = false;
				}
				if (bodyInput.disabled) { headerInput.disabled = true; }

			}
		});
	}

	// pri zmene konkretniho prava - pripadna zmena centralniho oka
	function headerInputInteractive (e) {
		let columnName = e.target.getAttribute('data-privilege');
		var columnInputs = Array.from(document.querySelectorAll(`input[data-privilege="${ columnName }"]`));

		// TODO this should be refactored to a part of the Vue app ASAP; no point fixing ESLint errors
		// eslint-disable-next-line @typescript-eslint/no-shadow
		var headerInput = document.getElementById(columnName);

		var isCheckedPartial = columnInputs.some(input => !e.target.checked || !input.checked);
		var isUnchecked = columnInputs.every(input => !input.checked);

		if (isUnchecked) {
			headerInput.checked = false;
			delete headerInput.dataset.checkedPartial;
		}
		else if (isCheckedPartial) {
			headerInput.checked = false;
			headerInput.dataset.checkedPartial = '';
		}
		else { headerInput.checked = true; }
	}

	// centralni ocicko - prepinani
	function checkAll (e) {
		let columnName = e.target.getAttribute('id');
		var columnInputs = document.querySelectorAll(`input[data-privilege="${ columnName }"]`);

		const [ columnIdentifier ] = columnName.split('-');
		let columnTitleElem = document.querySelector(`td[name="${ columnIdentifier }"]`);
		let bodyElement = document.querySelector(`tr.body-row td[name="${ columnIdentifier }"]`);
		if (bodyElement.parentElement.classList.contains('hidden')) {
			columnTitleElem.click();
		}

		columnInputs.forEach(input => {
			delete e.target.dataset.checkedPartial;
			if (e.target.checked) { input.checked = true; }
			else {
				input.checked = false;
			}
		});
	}
});
