document.addEventListener('DOMContentLoaded', function () {

	// Prirazovani sloupcu pri importu
	if (document.getElementById('import-columns')) {

		// Zobrazeni preskoceni
		window.addEventListener('change', function (e) {

			// Jedna se o input[type="radio"]?
			if (e.target.tagName.toLowerCase() != 'input' || e.target.type != 'radio') { return; }

			// Najit kontejner <div>
			var div = e.target.parentNode;

			while (div.parentNode != document.getElementById('import-columns')) {
				div = div.parentNode;
				if (div == document.body) { return; }
			}

			// Zmenit tridu
			if (e.target.hasAttribute('data-skip')) { div.classList.add('skip'); }
			else { div.classList.remove('skip'); }
		});

		// Povoleni/zakazani prepinace unikatni hodnoty
		var toggleUnique = function (div) {

			// Najit select box a input[type="checkbox"]
			var select = div.querySelector('select');
			var input = div.querySelector('input[type="checkbox"]');

			// Ziskat hodnoty, pro ktere muze sloupec byt unikatni
			var uniqueColumns = (select.getAttribute('data-unique') || '').split(',');
			var canBeUnique = (uniqueColumns.indexOf(select.value) != -1);

			if (input) {
				// Povolit checkbox
				if (canBeUnique) {
					input.disabled = false;
				}

				// Nebo odskrtnout a zakazat
				else {
					input.checked = false;
					input.disabled = true;
				}
			}
		};

		// Povolit/zakazat prepinac unikatni hodnoty
		document.querySelectorAll('#import-columns > div').forEach(toggleUnique);

		window.addEventListener('change', function (e) {

			// Jedna se o select box?
			if (e.target.tagName.toLowerCase() != 'select') { return; }

			// Najit kontejner <div>
			var div = e.target.parentNode;

			while (div.parentNode != document.getElementById('import-columns')) {
				div = div.parentNode;
				if (div == document.body) { return; }
			}

			toggleUnique(div);
		});
	}

	if (document.getElementById('export')) {

		// Prepinani obsahu
		var switchContent = function () {
			var fieldset = this;

			fieldset.querySelectorAll('input[type="radio"]').forEach(function (input, i) {
				if (input.checked) {
					let switchId = fieldset.getAttribute('data-switch');

					// Schovat navazane elementy
					if (i === 0) {
						document.getElementById(switchId).classList.remove('show');
						document.querySelectorAll(`[data-shown-by="${ switchId }"]`).forEach(elm => elm.style.setProperty('display', 'none'));
						document.querySelectorAll(`[data-hidden-by="${ switchId }"]`).forEach(elm => elm.style.removeProperty('display'));
					}

					// Zobrazit navazane elementy
					else {
						document.getElementById(switchId).classList.add('show');
						document.querySelectorAll(`[data-shown-by="${ switchId }"]`).forEach(elm => elm.style.removeProperty('display'));
						document.querySelectorAll(`[data-hidden-by="${ switchId }"]`).forEach(elm => elm.style.setProperty('display', 'none'));
					}
				}
			});
		};

		// Inicializace prepinani obsahu
		var initSwitchContent = function () {
			document.querySelectorAll('fieldset[data-switch]').forEach(function (fieldset) {
				switchContent.call(fieldset);
				fieldset.addEventListener('click', switchContent);
			});
		};

		initSwitchContent();
		naja.afterUpdateFunctions.push(initSwitchContent);
	}
});
