<template>
	<teleport-optional to="#vue-app">
		<suspense>
			<router-view v-if="ready && !globalError" />
		</suspense>
	</teleport-optional>
	<teleport-optional to="#app-menu">
		<div class="app-menu-content">
			<messaging-permission-button
				v-if="!messagingPermitted"
				:permission-denied="messagingDenied"
				@click="requestMessagingPermission"
			/>
			<tray :admin-name="me?.personalData.fullname ?? ''">
				<tray-item
					v-if="can('all', 'Profile')"
					id="profile-link"
					:title="t('Profil')"
					icon-name="profile"
					:image-url="me?.personalData.photo?.link"
					link="/profile"
					:active="atProfileRoute"
					:disabled="atProfileRoute"
				/>
				<tray-item
					:title="t('Notifikace')"
					icon-name="notification"
					:active="atNotifsRoute || isNotifPanelShown"
					:disabled="atNotifsRoute"
					:alert="someNotifsUnread"
					@click="!atNotifsRoute && (isNotifPanelShown = !isNotifPanelShown)"
				/>
				<tray-item
					:title="t('Nastavení')"
					icon-name="settings"
					link="/settings"
					:active="atSettingsRoute"
					:disabled="atSettingsRoute"
				/>
				<tray-item
					id="logout"
					:title="t('Odhlásit se')"
					icon-name="logout"
					:link="loggedInAsAnotherAdmin() ? '/sign/restore-identity' : '/sign/out'"
				/>
			</tray>
		</div>
	</teleport-optional>
	<slide-in-area
		width="36rem"
		:shown="isNotifPanelShown"
	>
		<notification-panel @close="isNotifPanelShown = false" />
	</slide-in-area>
	<toasts />
	<error-page
		v-if="globalError"
		:status-code="globalError"
	/>
</template>

<script setup lang="ts">
import { useAbility } from '@casl/vue';
import { Message } from '~/helpers';
import { loggedInAsAnotherAdmin } from '~/helpers';

const { can } = useAbility();
const { t } = useTranslation();
const toast = useToasts();
const store = useTypedStore();

const ready = computed(() => store.getters['user/ready']);
const me = computed(() => store.getters['user/me']);
const globalError = computed(() => store.getters['core/globalError']);
const routePath = computed(() => store.getters['route/path']);
const messagingPermitted = computed(() => store.getters['core/messaging/permitted']);
const messagingDenied = computed(() => store.getters['core/messaging/denied']);
const someNotifsUnread = computed(() => store.getters['notifications/list/someUnread']);

const atProfileRoute = computed(() => routePath.value.startsWith('/profile'));
const atNotifsRoute = computed(() => routePath.value.startsWith('/core/notification'));
const atSettingsRoute = computed(() => routePath.value.startsWith('/settings'));

const isNotifPanelShown = ref(false);

onMounted(() => Promise.all([
	initMessaging(),
	getUnreadNotification(),
]));

onErrorCaptured(() => {
	toast({ type: 'error', message: t('Něco se pokazilo.') });
});

async function handleMessage ({ title, body }: Message): Promise<void> {
	toast({ title, message: body });
	await getUnreadNotification();
}

async function initMessaging (): Promise<void> {
	await store.dispatch('core/messaging/init', { handleMessage });
}

async function requestMessagingPermission (): Promise<void> {
	await store.dispatch('core/messaging/requestPermission');
	await initMessaging();
}

async function getUnreadNotification (): Promise<void> {
	await store.dispatch('notifications/list/getLatestUnread');
}
</script>

<style scoped lang="scss">
.app-menu-content {
	display: flex;
	gap: 1rem;
	align-items: center;
	height: 100%;
}
</style>
