import { Api, Filter, FilterRequest, GetFilterParams } from '~/api';
import { Dict } from '~/helpers';
import $axios from '~/plugins/axios';
import { buildModule } from '../utils';

const api = $axios as Api;

export default buildModule()
	.withState({
		locationKeysById: {} as Dict<string[]>,
	})
	.withGetters({
		locationKeysById: state => (id: number) => state.locationKeysById[id],
	})
	.withMutations({
		STORE_LOCATION_KEYS (state, [ adminInfoId, payload ]: [number, Filter[]]) {
			state.locationKeysById = {
				...state.locationKeysById,
				[adminInfoId]: payload.map(({ key }) => key),
			};
		},
	})
	.withActions({
		async getLocationKeys ({ commit }, { adminInfoId }: {
			adminInfoId: number;
		}) {
			const params: GetFilterParams = { adminInfoId };
			const { data } = await api.get('/filter', { params });
			commit('STORE_LOCATION_KEYS', [ adminInfoId, data.payload ]);
		},
		async updateLocationKeys ({ commit }, { adminInfoId, keys }: {
			adminInfoId: number;
			keys: FilterRequest['keys'];
		}) {
			const body: FilterRequest = { adminInfoId, keys, posInfoId: null };
			const { data } = await api.put('/filter', body);
			commit('STORE_LOCATION_KEYS', [ adminInfoId, data.payload ]);
		},
	});
