import { Api, AuditExportRequest, ExportReport } from '~/api';
import $axios from '~/plugins/axios';
import { buildModule } from '../utils';

type ExportState<T> =
	| { state: 'empty' | 'loading' }
	| { state: 'ready'; report: T };

const api = $axios as Api;

export default buildModule()
	.withState({
		auditExport: { state: 'empty' } as ExportState<ExportReport>,
	})
	.withGetters({
		auditExport: state => state.auditExport,
	})
	.withMutations({
		SET_AUDIT_EXPORT (state, auditExport: ExportState<ExportReport>) {
			state.auditExport = auditExport;
		},
	})
	.withActions({
		async exportAudit ({ commit }, { auditId, params }: {
			auditId: number;
			params: AuditExportRequest;
		}) {
			commit('SET_AUDIT_EXPORT', { state: 'loading' });
			const { data } = await api.post(`/export/audit/${ auditId }`, params);
			commit('SET_AUDIT_EXPORT', { state: 'ready', report: data.payload });
		},

		clearAuditExport ({ commit }) {
			commit('SET_AUDIT_EXPORT', { state: 'empty' });
		},
	});
