import { Api, GetNotificationParams, Notification, NotificationResponse } from '~/api';
import { Dict, createDict } from '~/helpers';
import $axios from '~/plugins/axios';
import { buildModule } from '../utils';

const api = $axios as Api;

export default buildModule()
	.withState({
		notificationById: {} as Dict<Notification>,
		latestIds: [] as number[],
		totalCount: 0,
		loading: false,
	})
	.withGetters({
		latestPage: state => state.latestIds.map(id => state.notificationById[id]),
		totalCount: state => state.totalCount,
		someUnread: state => Object.values(state.notificationById).some(({ read }) => !read),
		loading: state => state.loading,
	})
	.withMutations({
		UPDATE_LOADING_FLAG (state, loading: boolean) {
			state.loading = loading;
		},

		STORE_PAGE (state, response: NotificationResponse) {
			state.notificationById = {
				...state.notificationById,
				...createDict(response.payload, ({ id }) => id),
			};

			state.latestIds = response.payload.map(({ id }) => id);
			state.totalCount = response.paginator.totalCount;
		},

		STORE_SINGLE (state, notification: Notification) {
			state.notificationById = {
				...state.notificationById,
				[notification.id]: notification,
			};
		},

		UPDATE_WHETHER_READ (state, { id, read }: { id: number; read: boolean }) {
			state.notificationById = {
				...state.notificationById,
				[id]: { ...state.notificationById[id], read },
			};
		},

		MARK_ALL_READ (state) {
			const updatedNotifs = Object.values(state.notificationById)
				.map(notif => ({ ...notif, read: true }));

			state.notificationById = createDict(updatedNotifs, ({ id }) => id);
		},
	})
	.withActions({
		async getPage ({ commit }, params: GetNotificationParams = {}) {
			commit('UPDATE_LOADING_FLAG', true);
			const { data } = await api.get('/notification', { params });
			commit('UPDATE_LOADING_FLAG', false);
			commit('STORE_PAGE', data);
		},

		async getLatestUnread ({ commit }) {
			const params: GetNotificationParams = { read: false, limit: 1 };
			const { data } = await api.get('/notification', { params });
			if (data.payload[0] != null) {
				commit('STORE_SINGLE', data.payload[0]);
			}
		},

		async updateWhetherRead ({ commit }, { id, read }: { id: number; read: boolean }) {
			await api.post('/notification', { notificationId: [ id ], read });
			commit('UPDATE_WHETHER_READ', { id, read });
		},

		async markAllRead ({ commit }) {
			await api.post('/notification', { notificationId: [], read: true });
			commit('MARK_ALL_READ');
		},
	});
