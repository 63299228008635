import { buildModule, createFiltersModule, defineSingleFilter } from '../utils';

import detail from './detail';
import list from './list';
import taskTypes from './task-types';


export default buildModule()
	.withSubmodules({
		detail,
		list,
		taskTypes,
		filters: createFiltersModule({
			filters: {
				searchTerm: defineSingleFilter('search', String),
				isRecurring: defineSingleFilter('isRecurring', Boolean, false),
				orderBy: defineSingleFilter('orderBy', String),
				orderAsc: defineSingleFilter('orderAsc', Boolean, false),
			},
		}),
	});
