import { Api, GetInformationParams, Information, InformationRequest, PaginatorResult } from '~/api';
import $axios from '~/plugins/axios';
import { buildModule, createPaginationModule } from '../utils';
import { t } from 'i18next';

const api = $axios as Api;

export default buildModule()
	.withSubmodules({
		pagination: createPaginationModule({
			pageQueryKey: 'page',
			pageSizeQueryKey: 'itemsPerPage',
			itemCountGetter: 'information/informationTotalCount',
		}),
	})
	.withState({
		information: [] as Information[],
		currentInfo: null as Information | null,
		informationPaginator: null as PaginatorResult | null,
	})
	.withGetters({
		information: state => state.information,
		currentInfo: state => state.currentInfo,
		informationPaginator: state => state.informationPaginator,
		informationTotalCount: state => state.informationPaginator?.totalCount,
	})
	.withMutations({
		SET_INFO (state, information: Information[]) {
			state.information = information;
		},
		SET_CURRENT_INFO (state, information: Information) {
			state.currentInfo = information;
		},
		RESET_INFORMATION (state) {
			if (state.currentInfo == null) {
				return;
			}

			const current = state.information.find(({ id }) => id === state.currentInfo?.id);

			if (current) {
				state.currentInfo.start = current.start;
				state.currentInfo.end = current.end;
				state.currentInfo.content = current.content;
				state.currentInfo.roles = current.roles;
			}
			else { state.currentInfo = null; }
		},
		CLEAR_INFORMATION (state) {
			state.currentInfo = null;
		},
		INFORMATION_PAGINATION (state, paginator: PaginatorResult) {
			state.informationPaginator = paginator;
		},
	})
	.withActions({
		getInformation ({ commit }, params: GetInformationParams = {}) {
			return api.get('/information', { params }).then(resp => {
				commit('SET_INFO', resp.data.payload);
				commit('INFORMATION_PAGINATION', resp.data.paginator);
			});
		},

		addInformation (_, info: InformationRequest) {
			return api.post('/information', info).then(resp => {
				// dispatch('getInformation');

				new TempMessage({
					html: `<p>${ t('Informace byla uložena.') }</p>`,
					type: 'success',
				});

				return resp;
			}).catch(() => {
				new TempMessage({
					html: `<p>${ t('Informaci se nepodařilo uložit.') }</p>`,
					type: 'error',
				});
			});
		},

		deleteInformation (_, id: number) {
			return api.delete(`/information/${ id }`).then(resp => {
				// dispatch('getInformation');

				new TempMessage({
					html: `<p>${ t('Informace byla odebrána.') }</p>`,
					type: 'success',
				});

				return resp;
			}).catch(() => {
				new TempMessage({
					html: `<p>${ t('Informaci se nepodařilo odebrat.') }</p>`,
					type: 'error',
				});
			});
		},

		editInformation (_, { id, ...info }: { id: number } & InformationRequest) {
			return api.put(`/information/${ id }`, info).then(resp => {
				// dispatch('getInformation');

				new TempMessage({
					html: `<p>${ t('Informace byla upravena.') }</p>`,
					type: 'success',
				});

				return resp;
			}).catch(() => {
				new TempMessage({
					html: `<p>${ t('Informaci se nepodařilo uložit.') }</p>`,
					type: 'error',
				});
			});
		},
	});
