import { usePush } from 'notivue';

export type ToastOptions = {
	type?: 'info' | 'success' | 'error';
	title?: string;
	message?: string;
};

export function useToasts (): (options: ToastOptions) => void {
	const push = usePush();

	return ({ type = 'info', title, message }) => {
		push[type]({ title, message });
	};
}
