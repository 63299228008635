document.addEventListener('DOMContentLoaded', function () {
	if (!document.querySelector('[id*="settings"]') && !document.getElementById('prehled-prvku')) { return; }

	// Inicializace seznamu - otevreni detailu polozky
	function initDetails (details) {
		details.setAttribute('open', true);
		details.appendChild(document.createElement('summary'));
		details.addEventListener('click', function (e) {
			e.detailsClicked = true;
		});

		details.parentNode.addEventListener('click', function (e) {
			if (e.detailsClicked) { return; }

			else if (this.classList.contains('show-details')) { this.classList.remove('show-details'); }
			else {
				this.classList.add('show-details');

				let selectBox = this.querySelector('select');
				if (selectBox) {
					if (selectBox.selectedOptions[0].hasAttribute('data-more-options')) { toggleMoreOptions(selectBox); }
				}
			}
		});
	}

	document.querySelectorAll('div.list details').forEach(initDetails);

	naja.afterUpdateFunctions.push(function () {
		if (this.classList.contains('list')) { this.querySelectorAll('details').forEach(initDetails); }
		else { this.querySelectorAll('div.list details').forEach(initDetails); }
	});

	// Pri Enteru na inputu se podivat, jestli ma vedle sebe submit button a pripadne na nej kliknout
	window.addEventListener('keydown', function (e) {
		if (e.target.tagName.toLowerCase() != 'input') { return; }

		if (e.key === 'Enter') {
			e.preventDefault();
			var btn = e.target.nextElementSibling;
			if (btn && btn.tagName.toLowerCase() == 'button' && btn.type == 'submit') { btn.click(); }
		}
	});


	var form = document.querySelector('form');

	// Zobrazit/skryt blok s vice moznostmi
	function toggleMoreOptions (select) {
		// Vybrana moznost
		var selectedOption = select.selectedOptions[0];

		// Najit <div.more-options> v tom samem <details> elementu
		var details = select.parentNode;
		while (details.tagName.toLowerCase() != 'details') {
			details = details.parentNode;
			if (details == form) { return; }
		}
		var moreOptions = details.querySelector('div.more-options');

		// Zobrazit/skryt <div.more-options> v zavislosti na data atributu u vybrane <option>
		if (moreOptions) {
			if (selectedOption.hasAttribute('data-more-options')) { moreOptions.classList.add('show'); }
			else { moreOptions.classList.remove('show'); }
		}

		var propertyValues = details.querySelector('div.property-values');

		// Zobrazit/skryt <div.property-values> v zavislosti na data atributu u vybrane <option>
		if (propertyValues) {
			if (selectedOption.hasAttribute('data-property')) { propertyValues.classList.add('show'); }
			else { propertyValues.classList.remove('show'); }
		}
	}

	// Zobrazit/skryt input a button ke google hodnoceni
	function toggleGoogleRating (input) {
		var ratingAddOns = document.querySelectorAll('[data-google-api]');

		ratingAddOns.forEach(elem => {
			input.checked ? elem.style.setProperty('display', 'block') : elem.style.removeProperty('display');
		});
	}

	// Update hodnoty dle formularoveho prvku
	function updateElement (model) {
		// Zjistit, nastavit prislusnou hodnotu podle typu formularoveho prvku
		var tagName = model.tagName.toLowerCase();

		// Najit element, ktery se ma updatovat
		var elementToUpdate = document.querySelector('[data-model="' + model.getAttribute('name') + '"]');

		if (!elementToUpdate) { return; }

		if (tagName == 'select' || tagName == 'textarea') {
			elementToUpdate.innerHTML = model.value;
		}
		else if (tagName == 'input') {
			switch (model.type) {
			case 'radio':
			case 'checkbox':
				if (model.checked) {
					if (model.getAttribute('data-value') == '$label') {
						elementToUpdate.innerHTML = document.querySelector('label[for="' + model.id + '"]').innerHTML;
					}
					else {
						elementToUpdate.innerHTML = model.getAttribute('data-value') || '';
					}
				}
				else if (model.type == 'checkbox') { elementToUpdate.innerHTML = ''; }
				break;
			default:
				elementToUpdate.innerHTML = model.value;
			}
		}
	}

	// Listener na zmenu formularovych prvku
	// Neni primo na inputu apod. kvuli prekreslovani celeho formulare
	window.addEventListener('change', function (e) {
		if (e.target.tagName.toLowerCase() == 'select') {
			toggleMoreOptions(e.target);
			updateElement(e.target);
		}
		else if (e.target.tagName.toLowerCase() == 'input') { updateElement(e.target); }
		else if (e.target.tagName.toLowerCase() == 'textarea') { updateElement(e.target); }
	});
	window.addEventListener('keyup', function (e) {
		if (e.target.tagName.toLowerCase() == 'input') { updateElement(e.target); }
		if (e.target.tagName.toLowerCase() == 'textarea') { updateElement(e.target); }
	});
	window.addEventListener('click', function (e) {
		if (e.target.hasAttribute('data-google-rating')) { toggleGoogleRating(e.target); }
	});

	// Inicializace na vsech formularovych prvcich
	function initFormFields () {
		document.querySelectorAll('.list-item select').forEach(toggleMoreOptions);
		if (form == null) {
			return;
		}
		form.querySelectorAll('select, input').forEach(updateElement);
		form.querySelectorAll('[data-google-rating]').forEach(toggleGoogleRating);
	}

	initFormFields();
	naja.afterUpdateFunctions.push(initFormFields);
});
