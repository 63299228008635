import { RouteLocationNormalized, Router } from 'vue-router';
import { Plugin } from 'vuex';
import { Dict, omitEmpty } from '~/helpers';
import { buildModule } from './module';

export type RoutePath = string;
export type RouteQuery = Dict<string | string[]>;
export type RouteQueryUpdate = Dict<string | string[] | null>;

export type RouteState = {
	path: RoutePath;
	query: RouteQuery;
};

export type RouteUpdate = {
	path: string | null;
	query: RouteQueryUpdate | null;
	mergeQuery: boolean;
	replaceRoute: boolean;
};

export const ROUTE_NAMESPACE = 'route';

export function createRouteModule (router: Router) {
	const routeModule = buildModule()
		.withState<RouteState>({
			path: '',
			query: {},
		})
		.withGetters({
			path: state => state.path,
			query: state => state.query,
		})
		.withMutations({
			STORE_ROUTE (state, { path, query }: RouteLocationNormalized) {
				state.path = path;
				state.query = Object.fromEntries((
					Object
						.entries(query)
						.filter((entry): entry is [string, string | string[]] => (
							entry[1] != null && (
								!Array.isArray(entry) || !entry.includes(null)
							)
						))
				));
			},
		})
		.withActions({
			async update (_, {
				path = null,
				query = null,
				mergeQuery = false,
				replaceRoute = false,
			}: Partial<RouteUpdate>) {
				const {
					path: curPath,
					query: curQuery,
				} = router.currentRoute.value;

				await router.push({
					path: path != null ? path : curPath,
					query: query != null
						? omitEmpty(mergeQuery ? { ...curQuery, ...query } : query)
						: curQuery,
					replace: replaceRoute,
				});
			},
		});

	return { [ROUTE_NAMESPACE]: routeModule };
}

export function createRouteSyncPlugin<S> (router: Router): Plugin<S> {
	return store => {
		router.afterEach(location => {
			store.commit(`${ ROUTE_NAMESPACE }/STORE_ROUTE`, location);
		});
	};
}
