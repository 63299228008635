/*

Funkcionalita docasneho upozorneni
Vsechny parametry jsou optional

Pouziti:
new TempMessage({
    html: "string",
    button: {
        title: "string",
        func: function
    },
    type: 'success/warning/info/error',
    delay: integer [ms]
});

*/


// Zpristupnit funkcionalitu jeste pred nactenim DOM
(function () {
	var queue = function (conf) {
		queue.queuedMessages.push(conf);
	};
	queue.queuedMessages = [];

	window.TempMessage = queue;
})();


document.addEventListener('DOMContentLoaded', function () {

	// Zpravy cekajici na zobrazeni
	var queuedMessages = window.TempMessage.queuedMessages;

	window.TempMessage = function (config) {

		// Ulozeni konfigurace
		this.config = config;

		// HTML reference
		this.box = document.getElementById('temp-message-template').cloneNode(true);
		this.box.id = '';
		document.getElementById('temp-messages').appendChild(this.box);

		this.textDiv = this.box.querySelector('div');
		this.btn = this.box.querySelector('.button-temp-message');
		this.hideBtn = this.box.querySelector('.hide-temp-message');

		// Promenna, ktera drzi timeout
		this.timeout = null;

		// Funkce, ktera se ma vykonat pri kliknuti na tlacitko
		this.buttonFunction = function () {
			// nic nedelat
		};

		// Listenery na tlacitka
		this.btn.addEventListener('click', function (e) {
			e.preventDefault();
			this.buttonFunction();
		}.bind(this));
		this.hideBtn.addEventListener('click', function (e) {
			e.preventDefault();
			this.hide();
		}.bind(this));

		// Zobrazit zpravu
		this.show();

		return this;
	};

	// Funkce pro schovani zpravy
	TempMessage.prototype.hide = function () {
		this.box.classList.remove('show');
		this.box.style.height = 0;
		clearTimeout(this.timeout);

		// Pockat na dokonceni animace a pote odstranit z DOM
		setTimeout(function () {
			this.box.parentNode?.removeChild(this.box);
		}.bind(this), 300);
	};

	// Funkce pro zobrazeni zpravy
	TempMessage.prototype.show = function () {

		// Nastavit text zpravy
		if (this.config.html) { this.textDiv.innerHTML = this.config.html; }
		else { this.textDiv.innerHTML = ''; }

		// Nastavit tlacitko
		if (this.config.button) {
			this.btn.innerHTML = this.config.button.title;

			if (this.config.button.func && typeof this.config.button.func === 'function') {
				this.buttonFunction = this.config.button.func;
			}
			else {
				this.buttonFunction = function () {
					// nic nedelat
				};
			}

			this.box.classList.add('button');
		}
		else { this.box.classList.remove('button'); }

		// Nastavit barvu zpravy
		if (this.config.type === 'error') { this.box.classList.add('error'); }
		else if (this.config.type === 'warning') { this.box.classList.add('warning'); }
		else if (this.config.type === 'success') { this.box.classList.add('success'); }
		else if (this.config.type === 'info') { this.box.classList.add('info'); }
		else { this.box.classList.remove('success', 'error'); }

		this.box.style.height = this.box.scrollHeight + 'px';

		// Zobrazit zpravu
		this.box.classList.add('show');

		// Nastavit schovani zpravy
		if (this.config.delay) { this.timeout = setTimeout(this.hide.bind(this), this.config.delay); }
		else { this.timeout = setTimeout(this.hide.bind(this), 12000); }
	};

	if (queuedMessages && queuedMessages.length > 0) {
	// Zobrazit vsechny cekajici zpravy
		for (var i = 0; i < queuedMessages.length; i++) {
			new TempMessage(queuedMessages[i]);
		}
	}
});
