import moment from 'moment';
import calendar from './calendar';
import settings from './settings';
import planning from './planning';
import activities from './activities';
import holidays from './holidays';
import dashboard from './dashboard';
import { createFiltersModule, buildModule, createPaginationModule, defineSingleFilter } from '../utils';

const CURRENT_MONTH = moment().format('YYYY-MM');

export default buildModule()
	.withSubmodules({
		calendar,
		settings,
		planning,
		activities,
		holidays,
		dashboard,

		calendarFilters: createFiltersModule({
			replaceRoute: true,
			filters: {
				colleaguesShown: defineSingleFilter('colleagues', Boolean, false),
				period: defineSingleFilter('period', String, CURRENT_MONTH),
			},
		}),

		planningFilters: createFiltersModule({
			replaceRoute: true,
			filters: {
				period: defineSingleFilter('period', String, CURRENT_MONTH),
			},
		}),

		plannablePosesPagination: createPaginationModule({
			itemCountGetter: 'planner/planning/plannablePosesTotalCount',
		}),

		plannableAdminsPagination: createPaginationModule({
			itemCountGetter: 'planner/planning/plannableAdminsTotalCount',
		}),
	});
