import { Admin, Api, PasswordRequest, ProfileRequest } from '~/api';
import $axios from '~/plugins/axios';
import { buildModule } from '../utils';
import { assertApiError, SupportedLocale } from '~/helpers';

const api = $axios as Api;

export default buildModule()
	.withState({
		me: null as Admin | null,
		passwordRejected: false,
	})
	.withGetters({
		me: state => state.me,
		ready: state => state.me != null,
		myPosIds: state => state.me?.poses.map(({ id }) => id) ?? [],
		passwordRejected: state => state.passwordRejected,
	})
	.withMutations({
		SET_ME (state, me: Admin) {
			state.me = me;
		},
		UNSET_ME (state) {
			state.me = null;
		},
		SET_PASSWORD_REJECTED (state, value: boolean) {
			state.passwordRejected = value;
		},
	})
	.withActions({
		async getMe ({ commit }) {
			const { data } = await api.get('/admin/me');
			commit('SET_ME', data.payload);
		},
		async updateProfile (_, update: ProfileRequest) {
			await api.post('/profile/change', update);
		},
		async updatePassword ({ commit }, update: PasswordRequest) {
			commit('SET_PASSWORD_REJECTED', false);
			try {
				await api.post('/profile/change-password', update);
			}
			catch (error) {
				assertApiError(error);
				if (error.response.data.code === 422) {
					commit('SET_PASSWORD_REJECTED', true);
					return;
				}

				throw error;
			}
		},
		async updateLocale (_, locale: SupportedLocale) {
			await api.post(`/profile/change-language/${ locale }`);
		},
	});
