import $axios from '~/plugins/axios';

import { Api, EventType, GetEventTypeParams } from '~/api';
import { buildModule } from '../utils';
import { Dict, createDict } from '~/helpers';

const api = $axios as Api;

export default buildModule()
	.withState({
		storedEventTypes: {} as Dict<EventType>,
		eventTypes: [] as EventType[],
		loading: false,
	})
	.withGetters({
		byId: state => (adminId: number) => (
			state.storedEventTypes[adminId] ?? null
		),
		eventTypes: (state) => state.eventTypes,
		page: state => state.eventTypes,
		loading: state => state.loading,
	})
	.withMutations({
		SET_STORED_EVENT_TYPES (state, eventTypes: EventType[]) {
			state.storedEventTypes = {
				...state.storedEventTypes,
				...createDict(eventTypes, (type) => type.id),
			};
		},
		SET_EVENT_TYPES (state, eventTypeIds: number[]) {
			state.eventTypes = eventTypeIds.map(id => state.storedEventTypes[id]);
		},
		SET_LOADING (state, loading: boolean) {
			state.loading = loading;
		},
	})
	.withActions({
		async get ({ commit }, params: GetEventTypeParams = {}) {
			commit('SET_LOADING', true);
			const { data } = await api.get('/event-type', { params });
			commit('SET_LOADING', false);

			const eventTypes = data.payload;
			const eventTypeIds = eventTypes.map((type) => type.id);

			commit('SET_STORED_EVENT_TYPES', eventTypes);
			commit('SET_EVENT_TYPES', eventTypeIds);
		},
	});
