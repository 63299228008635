import $axios from '~/plugins/axios';

import { Api, Area, GetAreaParams } from '~/api';
import { buildModule } from '../utils';
import { createDict, Dict } from '~/helpers';

const api = $axios as Api;

type LazyLoadingParams = Omit<GetAreaParams, 'limit' | 'offset'>;

export default buildModule()
	.withState({
		areaById: {} as Dict<Area>,
		orderedIds: [] as number[],
		params: {} as LazyLoadingParams,
	})
	.withGetters({
		items: (state) => state.orderedIds.map(id => state.areaById[id]),
		byId: (state) => (id: number) => state.areaById[id],
	})
	.withMutations({
		RESET (state, params: LazyLoadingParams = {}) {
			state.params = params;
			state.orderedIds = [];
		},
		APPEND_AREAS (state, areas: Area[]) {
			state.areaById = { ...state.areaById, ...createDict(areas, ({ id }) => id) };
			state.orderedIds = Array.from(new Set([ ...state.orderedIds, ...areas.map((area) => area.id) ]));
		},
		SET_AREAS (state, areas: Area[]) {
			state.areaById = { ...state.areaById, ...createDict(areas, ({ id }) => id) };
		},
	})
	.withActions({
		async getMore ({ state, commit }, limit: number | null = 20) {
			const params: GetAreaParams = { ...state.params, limit, offset: state.orderedIds.length };
			const { data } = await api.get('/area', { params });

			commit('APPEND_AREAS', data.payload);
		},
		async prefetchAreas ({ commit }, areaIds: number[]) {
			const areaGetRequests = areaIds.map((id) => api.get(`/area/${ id }`));
			const areaResponses = await Promise.all(areaGetRequests);

			const areas = areaResponses.map((resp) => resp.data.payload);

			commit('SET_AREAS', areas);
		},
		async getArea ({ commit }, areaId: number) {
			const { data } = await api.get(`/area/${ areaId }`);

			commit('APPEND_AREAS', [ data.payload ]);
		},
		resetParams ({ state, commit }, params: LazyLoadingParams = {}) {
			commit('RESET', { ...state.params, ...params });
		},
	});
