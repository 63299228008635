<template>
	<div class="notification">
		<notification-avatar :alert="!props.isRead" />
		<div class="content">
			<!-- eslint-disable-next-line vue/no-v-html -->
			<div v-html="props.htmlContent" />
			<div class="datetime">
				{{ formattedDate }}
			</div>
		</div>
		<basic-tooltip>
			<template #tooltip>
				<div v-if="props.isRead">
					{{ t('Označit jako nepřečtené') }}
				</div>
				<div v-else>
					{{ t('Označit jako přečtené') }}
				</div>
			</template>
			<div
				class="read"
				@click="emit('toggleRead')"
			>
				<icon
					v-if="props.isRead"
					which="thick"
					:aria-label="t('Přečteno')"
				/>
				<icon
					v-else
					which="unread"
					:aria-label="t('Nepřečteno')"
				/>
			</div>
		</basic-tooltip>
	</div>
</template>

<script setup lang="ts">
import moment from 'moment';

type Props = {
	htmlContent: string;
	isoDate: string;
	isRead: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
	toggleRead: [];
}>();


const { t } = useTranslation();

const formattedDate = computed(() => moment(props.isoDate).format('D. M. YYYY'));
</script>

<style lang="scss" scoped>
.notification {
	display: grid;
	grid: auto / auto 1fr auto;
	gap: 1rem;
	padding: 1.5rem 0;
	background-color: white;

	.content {
		flex-grow: 1;
		margin: auto 0;
		font-weight: 500;
		font-size: 1.2rem;
		line-height: 1.5rem;

		.datetime {
			margin-top: 0.2rem;
			color: $grey;
			font-size: 1rem;
		}
	}

	.read {
		width: 2rem;
		height: 2rem;
		color: $black;
		cursor: pointer;
	}
}
</style>
