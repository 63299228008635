<template>
	<transition
		enter-from-class="slide-in-area--hidden"
		leave-to-class="slide-in-area--hidden"
	>
		<div
			v-if="props.shown"
			class="slide-in-area"
			:style="{ '--width': props.width }"
		>
			<slot />
		</div>
	</transition>
</template>

<script setup lang="ts">
type Props = {
	shown: boolean;
	width: `${ number }rem`;
};

const props = defineProps<Props>();
</script>

<style scoped lang="scss">
.slide-in-area {
	position: fixed;
	inset: 0 0 0 calc(100% - var(--width));
	z-index: 100000;
	transition: translate 300ms;
	translate: 0 0;

	&--hidden {
		translate: var(--width) 0;
	}
}
</style>
