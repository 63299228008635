import { buildModule } from '../utils';

import messaging from './messaging';
import role from './role';

export default buildModule()
	.withSubmodules({
		messaging,
		role,
	})
	.withState({
		globalError: null as number | null,
	})
	.withGetters({
		globalError: state => state.globalError,
	})
	.withMutations({
		GLOBAL_ERROR (state, status: number | null) {
			state.globalError = status;
		},
	});
