<template>
	<teleport
		v-if="element"
		:to="element"
	>
		<slot />
	</teleport>
</template>

<script setup lang="ts">
type Props = {
	to: string;
};

const props = defineProps<Props>();

const element = computed(() => document.querySelector(props.to));
</script>
