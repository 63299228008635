import { t } from 'i18next';

document.addEventListener('DOMContentLoaded', function () {
	if (!document.getElementById('eshop')) { return; }

	// Toggle item detail in basket
	function toggleDetail () {
		var showDetail = document.querySelectorAll('[data-show-detail]');

		if (showDetail.length != 0) {
			showDetail.forEach(el => {
				el.addEventListener('click', e => {
					var itemContainer = e.target.closest('.item-container');
					var buttonText = e.target.textContent;

					if (itemContainer) {
						let itemInfo = itemContainer.querySelector('[data-item-info]');
						if (itemInfo) { itemInfo.classList.toggle('show'); }

						buttonText === 'detail' ? e.target.textContent = t('zobrazit méně') : e.target.textContent = t('detail');
					}
				});
			});
		}
	}

	toggleDetail();
	naja.afterUpdateFunctions.push(function () { toggleDetail(); });


	// Edit basket's items count
	function editCount () {
		let countInputs = document.querySelectorAll('.basket-list .item-container [data-update-count]');
		var itemUrl;
		var value;

		countInputs.forEach(input => {
			input.addEventListener('change', e => {
				itemUrl = e.target.getAttribute('data-update-count');
				value = parseInt(e.target.value);

				naja.makeRequest('POST', itemUrl, { count: value }, { history: false });
			});
		});
	}

	editCount();
	naja.afterUpdateFunctions.push(function () { editCount(); });


	// Submit POS by OBJEDNAT button
	function sendPos () {
		let orderBtn = document.querySelector('[data-order-btn]');
		let posForm = document.getElementById('frm-orderForm-orderForm');

		if (orderBtn) { orderBtn.addEventListener('click', () => naja.uiHandler.submitForm(posForm)); }
	}

	sendPos();
	naja.afterUpdateFunctions.push(function () { sendPos(); });
});
