import { theChoices, selectedPrevious } from './campaigns.js';

var chosenAll = [];			// Moznosti, ze kterych se nacita Choices select pri "Vybrat vse"
var listHeight;

// Skryt vsechny disabled opts funkcionalita
export function handleDisabled () {
	var isDisabled = document.querySelector('#popup .hide-disabled input').checked;
	var disabledItems = document.querySelectorAll('.choices__item--disabled');

	if (isDisabled) { disabledItems.forEach(dis => { dis.classList.add('hidden'); }); }
	else { disabledItems.forEach(dis => { dis.classList.remove('hidden'); }); }
}

// Vybrat vse funkcionalita
export function handleSelectAll (onChange, part) {
	var selectAll = document.querySelector('.add-all input');

	if (!onChange) { setChosen(selectAll.checked, part); }

	else {
		var optsList = document.querySelector('.choices__list--dropdown div div');

		if (selectAll.checked && optsList) {
			if (!optsList.classList.contains('has-no-choices')) { selectAll.checked = false; }
		}
	}
	chosenAll = [];
}

// Set height of list of choices in the popup, because the input above has dynamic height
export function setListHeight () {
	listHeight = document.querySelector('.choices').offsetHeight - document.querySelector('.choices__inner').offsetHeight - 6;
	document.querySelector('.choices__list.choices__list--dropdown').style.maxHeight = listHeight + 'px';
	document.querySelector('.choices__list.choices__list--dropdown .choices__list').style.maxHeight = listHeight + 'px';
}

export function setInputWidth () {
	var input = document.querySelector('.choices__input.choices__input--cloned');

	if (!document.querySelector('.choices__list.choices__list--multiple').hasChildNodes()) {
		input.style.width = '97%';
	}
	else {
		let containerWidth = document.querySelector('.choices__inner').offsetWidth - 20;
		let lastWidth = document.querySelector('.choices__list.choices__list--multiple div:last-of-type').offsetWidth;
		let inputWidth = containerWidth - (document.querySelector('.choices__list.choices__list--multiple div:last-of-type').offsetLeft + lastWidth) - 10;

		input.style.width = inputWidth + 'px';
	}
}

// Add selected items to each block on (re)load - z hidden templatu v html
export function createSelected (part) {
	var segment = document.querySelector(`[data-visual-block=${ part }]`);

	var selectedTemplate = document.querySelector('.selected-template').cloneNode(true);
	selectedTemplate.classList.remove('selected-template');
	selectedTemplate.classList.add(`selected-${ part }`);
	segment.after(selectedTemplate);

	var optsSelected = segment.querySelectorAll('option[selected]');

	optsSelected.forEach((opt, i) => {
		var cloneTag = selectedTemplate.querySelector('.tag').cloneNode(true);
		var text = opt.textContent;

		cloneTag.querySelector('span').textContent = text;
		// Add opt group name to selected
		if (part === 'posm-type') { cloneTag.querySelector('span').textContent += ` (${ opt.parentElement.label })`; }

		selectedTemplate.querySelector('.selected-items').appendChild(cloneTag);

		if (i === 0) { selectedTemplate.querySelector('.tag').remove(); }
	});

	if (part && optsSelected.length === 0) { selectedTemplate.querySelector('.tag').remove(); }
}

export function showSelected (part) {
	var selected = document.querySelector(`.selected-${ part }`);
	var dropDown = selected.querySelector('.drop-down');
	var icon = dropDown.querySelector('svg');
	var shownSelected = selected.querySelector('.selected-container');
	var allSelected = selected.querySelector('.selected-items');

	var isRolled = false;

	if (shownSelected.offsetHeight === allSelected.offsetHeight) {
		dropDown.classList.add('hidden');
		selected.querySelector('.dots').classList.add('hidden');
	}

	// Add click to the arrow and 3 dots for show/hide all selected
	dropDown.addEventListener('click', () => { rollSelected(); });
	selected.querySelector('h1.dots').addEventListener('click', () => { rollSelected(); });

	function rollSelected () {
		if (!isRolled) {
			shownSelected.classList.add('show');
			icon.classList.add('no-rotate');
			selected.querySelector('.dots').classList.add('hidden');
		}
		else {
			shownSelected.classList.remove('show');
			icon.classList.remove('no-rotate');
			selected.querySelector('.dots').classList.remove('hidden');
		}

		isRolled ? dropDown.removeAttribute('rolled') : dropDown.setAttribute('rolled', '');
		isRolled = dropDown.hasAttribute('rolled');
	}
}

// Remove zone's name from Choices list items
export function removeZones (part) {
	if (part === 'posm-type') {
		var choicesItems = document.querySelectorAll('.choices__list--dropdown .choices__item:not(.has-no-choices)');

		choicesItems.forEach(item => {
			let zoneName = item.textContent.match(/\(([^)]*)\)[^(]*$/)[0];
			item.textContent = item.textContent.replace(zoneName, '');
		});
	}
}

// Funkcionalita "Vybrat vse" (s ohledem na disabled items)
export function setChosen (checked, part) {
	var selectCopy = document.querySelector(`select.${ part }`).cloneNode(true);
	var selectArray = selectCopy.querySelectorAll('option');

	if (part !== 'posm-type') {
		if (checked) {
			selectArray.forEach(opt => {
				if (!opt.disabled) { opt.setAttribute('selected', ''); }
				chosenAll.push(opt);
			});

			theChoices.clearStore();
			theChoices.setChoices([ ...new Set(chosenAll) ], 'value', 'label', true);
		}
		else if (!checked) {
			selectArray.forEach(opt => {
				if (opt.selected) { opt.removeAttribute('selected'); }
				chosenAll.push(opt);
			});
			theChoices.clearStore();
			theChoices.setChoices(chosenAll);
		}
	}

	// Zajisti, aby se zpet do listu vratily opts i se zonami (optgroup)
	if (part === 'posm-type') {
		var optGroupArray = selectCopy.querySelectorAll('optgroup');
		var optsArray = [];
		chosenAll = [];

		if (checked) {
			optGroupArray.forEach(el => {
				optsArray = [];

				el.querySelectorAll('option').forEach(opt => {
					opt.textContent = opt.textContent + ' (' + opt.parentElement.label + ')';

					if (!opt.disabled) { opt.setAttribute('selected', ''); }
					optsArray.push(opt);
				});

				chosenAll.push({
					label: el.label,
					choices: optsArray,
				});
			});
			theChoices.clearStore();
			theChoices.setChoices(chosenAll, 'value', 'label', true);

			removeZones(part);
		}
		else if (!checked) {
			optGroupArray.forEach(el => {
				optsArray = [];

				el.querySelectorAll('option').forEach(opt => {
					opt.textContent = opt.textContent + ' (' + opt.parentElement.label + ')';

					if (opt.selected) { opt.removeAttribute('selected'); }
					optsArray.push(opt);
				});

				chosenAll.push({
					label: el.label,
					choices: optsArray,
				});
			});
			theChoices.clearStore();
			theChoices.setChoices(chosenAll);

			removeZones(part);
		}
	}
}

// Functionality of Zrusit button in popup - get back to previous state
export function getBackSelected (part) {
	let selectEl = document.querySelector(`#visuals .choices-multiple.${ part }`);

	document.querySelectorAll('#popup .checkbox input').forEach(el => { el.checked = false; });

	selectEl.innerHTML = '';
	selectEl.append(...selectedPrevious);

	// Checkboxy v popupu do vychozi pozice -> uncheck
	document.querySelectorAll('#popup .checkbox input').forEach(el => { el.checked = false; });
}

export function sendSelected (part) {
	var url = document.querySelector(`select[data-${ part }]`).getAttribute(`data-${ part }`);
	var selectedArray = [];

	document.querySelectorAll('#popup .choices-multiple option').forEach(opt => {
		let value = opt.getAttribute('value');
		selectedArray.push(value);
	});

	naja.makeRequest('POST', url, { filter: selectedArray }, { history: false });

	// Checkboxy v popupu do vychozi pozice -> uncheck
	document.querySelectorAll('#popup .checkbox input').forEach(el => { el.checked = false; });
}
