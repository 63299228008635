import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { assertNonNullish, pickFrom } from './common';

import type { InitMessagingMessage } from '../../service-worker';

export type MessagingOptions = Pick<
	Required<FirebaseOptions>,
	'apiKey' | 'appId' | 'messagingSenderId' | 'projectId'
>;

export type MessagingConfig = {
	options: MessagingOptions;
	submitToken: (token: string) => Promise<unknown>;
	handleMessage: (message: Message) => void;
};

export type Message = {
	title?: string;
	body?: string;
};

export async function initMessaging ({
	options,
	submitToken,
	handleMessage,
}: MessagingConfig): Promise<void> {
	const serviceWorkerRegistration = await initServiceWorker(options);
	const firebaseApp = initializeApp(options);
	const messaging = getMessaging(firebaseApp);
	const token = await getToken(messaging, { serviceWorkerRegistration });
	await submitToken(token);

	onMessage(messaging, ({ notification: { title, body } = {} }) => {
		handleMessage({ title, body });
	});
}

export async function stopMessaging (): Promise<void> {
	await Promise.all([
		terminateServiceWorker(),
		purgeLocalData(),
	]);
}

const FIREBASE_INDEXED_DB_NAMES = [
	'firebase-heartbeat-database',
	'firebase-installations-database',
	'firebase-messaging-database',
];

async function initServiceWorker (options: MessagingOptions): Promise<ServiceWorkerRegistration> {
	await navigator.serviceWorker.register('/service-worker.js');
	const registration = await navigator.serviceWorker.ready;
	assertNonNullish(registration.active);

	registration.active.postMessage({
		type: 'initMessaging',
		options: pickFrom(options, [ 'apiKey', 'appId', 'messagingSenderId', 'projectId' ]),
	} satisfies InitMessagingMessage);

	return registration;
}

async function terminateServiceWorker () {
	const registration = await navigator.serviceWorker.getRegistration();
	if (registration != null) {
		await registration.unregister();
	}
}

async function purgeLocalData (): Promise<void> {
	await Promise.all((
		FIREBASE_INDEXED_DB_NAMES.map(name => new Promise<void>(resolve => {
			const deleteRequest = indexedDB.deleteDatabase(name);

			deleteRequest.addEventListener('success', () => {
				resolve();
			});

			deleteRequest.addEventListener('error', () => {
				console.error(`Error deleting database "${ name }"`);
				resolve();
			});
		}))
	));
}
