<template>
	<div class="notification-list">
		<slot />
	</div>
</template>

<style lang="scss" scoped>
.notification-list {
	display: grid;
	gap: 1px;
	background-color: $lightGrey;
}
</style>
