/* eslint-disable prefer-const */

// TODO delete the directives above after typing this file properly

import $axios from '../../plugins/axios';
import $moment from '../../plugins/moment';
import { Admin, Api, Event, EventEval, EventEvalItem, EventEvalsRequest, EventEvalSummary, EventEventEval, EventExcuseRequest, EventType, Excuse, ExcuseRequest, GetEventEvalMeSummaryParams, GetEventExcuseMeParams, GetEventsColleaguesParams, GetEventsMeParams, GetExcusesMeParams, GetPlannerHolidayParams, PlannerHoliday } from '~/api';
import { createDict, Dict, fetchAllPages, FullcalendarEvent } from '~/helpers';
import { buildModule } from '../utils';

const api = $axios as Api;

type ExtendedExcuse = Excuse & {
	title?: string;
	status: string;
	classNames: string[];
};

export default buildModule()
	.withState({
		events: {} as Dict<Event>,
		colleagueEvents: {} as Dict<Event>,
		excuses: {} as Dict<Excuse>,
		eventExcuses: [] as Excuse[],
		holidays: {} as Dict<PlannerHoliday>,
		pendingRequestCount: 0,
		excuseForm: {
			startDate: '',
			endDate: '',
			startTime: '07:00',
			endTime: '07:00',
			allDay: false,
			excuseType: '',
			note: '',
			edited: false,
		},
		isExcuseForm: false,
		isEventExcuseForm: false,
		isEventEvalForm: false,
		currentEvent: null as FullcalendarEvent | null,
		currentEventEval: {
			adminId: null as number | null,
			items: [] as EventEval[],
		},
		eventEvalItems: [] as EventEvalItem[],

		evalItemsValue: [] as EventEval[],
		sortedAddValueItems: [],

		userSumItems: [] as EventEvalSummary[],
		status: '',
	})
	.withGetters({
		events: state => Object.values(state.events),
		colleagueEvents: state => Object.values(state.colleagueEvents),
		eventExcuses: state => state.eventExcuses,
		holidays: state => Object.values(state.holidays),
		excuses: state => Object.values(state.excuses).map(extendExcuse),
		someRequestsPending: state => state.pendingRequestCount > 0,
		excuseForm: state => state.excuseForm,
		isEventExcuseForm: state => state.isEventExcuseForm,
		isEventEvalForm: state => state.isEventEvalForm,
		isExcuseForm: state => state.isExcuseForm,
		currentEvent: state => state.currentEvent,
		currentEventEval: state => state.currentEventEval,
		evalItemsValue: (state) => {
			// Pri editaci naplnit hodnotama / pri novem vyhodnoceni vratit cisty form
			if (state.currentEventEval.items.length) {
				return state.eventEvalItems.map(item => {
					let theCurrent = state.currentEventEval?.items?.find(current => { return item.id === current.eventEvalItem.id; });

					if (theCurrent && item.valueType === 'radio') {
						return {
							...item,
							value: theCurrent.eventEvalItemOption?.id || null,
						};
					}
					else if (theCurrent) {
						return {
							...item,
							value: theCurrent.value,
						};
					}

					return item;
				});
			}
			else { return state.eventEvalItems; }
		},
		current: state => state.currentEvent,
		userSumItems: state => state.userSumItems,
		eventExcusesByEventId: state => (eventId: number) => state.eventExcuses.filter(excuse => excuse.event?.id === eventId),
	})
	.withMutations({
		EVENTS_SUCCESS (state, events: Event[]) {
			state.events = {
				...state.events,
				...createDict(events, ({ id }) => id),
			};
		},

		COLLEAGUE_EVENTS_SUCCESS (state, events: Event[]) {
			state.colleagueEvents = {
				...state.colleagueEvents,
				...createDict(events, ({ id }) => id),
			};
		},

		EVENT_EXCUSES_SUCCESS (state, eventExcuses: Excuse[]) {
			state.eventExcuses = eventExcuses;
		},

		EXCUSES_SUCCESS (state, excuses: Excuse[]) {
			// Bez eventExcuse eventu
			const withoutEvents = excuses.filter(({ event }) => event == null);

			state.excuses = {
				...state.excuses,
				...createDict(withoutEvents, ({ id }) => id),
			};
		},

		HOLIDAYS_SUCCESS (state, holidays: PlannerHoliday[]) {
			const normalizedHolidays = holidays.map(holiday => ({
				...holiday,
				end: $moment(holiday.end).add(1, 'd').format('YYYY-MM-DD'),
			}));

			state.holidays = {
				...state.holidays,
				...createDict(normalizedHolidays, ({ id }) => id),
			};
		},

		UPDATE_PENDING_REQUESTS (state, by: -1 | 1) {
			state.pendingRequestCount += by;
		},

		USER_SUMMARY_SUCCESS (state, sum: EventEvalSummary[]) {
			state.userSumItems = sum;
		},

		ADD_CURRENT: (state, event: FullcalendarEvent) => {
			state.currentEvent = event;
		},

		MY_CURRENT_EVENT_EVAL (state, params: EventEventEval) {
			state.currentEventEval = params;
		},

		ADD_EXCUSE: (state, excuse: Excuse) => {
			state.isExcuseForm = false;
			state.excuses = { ...state.excuses, [excuse.id]: excuse };
		},

		ADD_EVENT_EXCUSE: (state, excuse: Excuse) => {
			state.isEventExcuseForm = false;
			state.isExcuseForm = false;
			state.eventExcuses.push(excuse);
		},

		EVENT_EXCUSE_IS_OPEN (state, isOpen: boolean) {
			state.isEventExcuseForm = isOpen;
		},

		EVENT_EVAL_IS_OPEN (state, isOpen: boolean) {
			state.isEventEvalForm = isOpen;
		},

		EDIT_EVENT_EVAL: (state, { eventId, adminId, eventEvalItems }: {
			eventId: number;
			adminId: number;
			eventEvalItems: EventEval[];
		}) => {
			const event = state.events[eventId];
			if (event == null) {
				console.error(`Event with id ${ eventId } does not exist`);
				return;
			}

			state.events = {
				...state.events,
				[event.id]: {
					...event,
					eventEvals: [ { adminId, items: eventEvalItems } ],
				},
			};
		},

		SET_EVENT_EVAL (state, eventType: EventType) {
			state.eventEvalItems = eventType.eventEvalItems.length ? eventType.eventEvalItems : [];
		},

		CLEAR_EVENT_EVAL (state) {
			state.currentEventEval = {
				adminId: null,
				items: [],
			};
			state.eventEvalItems?.forEach(item => {
				// @ts-expect-error TODO investigate this type issue
				if (item.valueType === 'number') { item.value = '0'; }
				// @ts-expect-error TODO investigate this type issue
				else if (item.required) { item.value = null; }
				// @ts-expect-error TODO investigate this type issue
				else { item.value = ''; }
			});
		},

		EXCUSE_IS_OPEN (state, isOpen: boolean) {
			state.isExcuseForm = isOpen;
		},

		EDIT_EXCUSE (state, editedExcuse: Excuse) {
			state.excuses = {
				...state.excuses,
				[editedExcuse.id]: editedExcuse,
			};
			state.isExcuseForm = false;
		},

		DELETE_CURRENT (state) {
			state.currentEvent = null;
		},

		DELETE_EXCUSE (state, excuseId: number) {
			const { [excuseId]: _, ...rest } = state.excuses;
			state.excuses = rest;
		},

		SET_EXCUSE (state, { start, end, allDay, type, note }: {
			start: string;
			end: string;
			allDay: boolean;
			type: string;
			note: string;
		}) {
			if ((start === state.excuseForm.startDate && end === state.excuseForm.endDate) || (!state.excuseForm.startDate)) {
				state.isExcuseForm = !state.isExcuseForm;
			}

			state.excuseForm.allDay = allDay;
			state.excuseForm.startDate = $moment(start).format('YYYY-MM-DD');
			state.excuseForm.excuseType = type;
			state.excuseForm.note = note;

			state.excuseForm.edited = false;

			if ($moment(start).format('H') !== '0') {
				state.excuseForm.startTime = $moment(start).format('HH:mm');
			}
			else { state.excuseForm.startTime = '07:00'; }

			if ($moment(end).format('H') !== '0') {
				state.excuseForm.endTime = $moment(end).format('HH:mm');
				state.excuseForm.endDate = $moment(end).add(1, 'd').format('YYYY-MM-DD');
			}
			else {
				state.excuseForm.endTime = '07:00';
				state.excuseForm.endDate = $moment(end).format('YYYY-MM-DD');
			}

			state.isEventExcuseForm = false;
		},

		SET_START_DATE (state, value: string) {
			state.excuseForm.startDate = value;
		},

		SET_END_DATE (state, value: string) {
			state.excuseForm.endDate = value;
		},

		SET_START_TIME (state, value: string) {
			state.excuseForm.startTime = value;
		},

		SET_END_TIME (state, value: string) {
			state.excuseForm.endTime = value;
		},

		SET_ALL_DAY (state, value: boolean) {
			state.excuseForm.allDay = value;

			if (value) { state.excuseForm.startTime = state.excuseForm.endTime = '07:00'; }
		},

		SET_EVAL (state, value: EventEval) {
			const editItemIndex = state.currentEventEval?.items.findIndex(item => { return item.eventEvalItem.id === value.eventEvalItem.id; });

			// Vyradit checkbox, kdyz je false
			if (editItemIndex !== -1) {
				state.currentEventEval.items[editItemIndex].value = value.value;
				// @ts-expect-error TODO investigate this type issue
				state.currentEventEval.items[editItemIndex].eventEvalItemOption = { id: value.eventEvalItemOption.id };
				// @ts-expect-error TODO investigate this type issue
				delete state.currentEventEval.items[editItemIndex].eventEvalItemOption?.name;
			}
			else { state.currentEventEval.items.push(value); }
		},

		SET_REASON: (state, value: string) => {
			state.excuseForm.excuseType = value;
		},

		SET_NOTE: (state, value: string) => {
			state.excuseForm.note = value;
		},
	})
	.withActions({
		async getEvents ({ commit }, params: GetEventsMeParams) {
			commit('UPDATE_PENDING_REQUESTS', 1);
			const events = await fetchAllPages('/events/me', params)<Event>(api);
			commit('EVENTS_SUCCESS', events);
			commit('UPDATE_PENDING_REQUESTS', -1);
		},

		async getColleagueEvents ({ commit }, params: GetEventsColleaguesParams) {
			commit('UPDATE_PENDING_REQUESTS', 1);
			const events = await fetchAllPages('/events/colleagues', params)<Event>(api);
			commit('COLLEAGUE_EVENTS_SUCCESS', events);
			commit('UPDATE_PENDING_REQUESTS', -1);
		},

		async getExcuses ({ commit }, { start, end }: GetExcusesMeParams) {
			commit('UPDATE_PENDING_REQUESTS', 1);

			const { data } = await api.get('/excuses/me', {
				params: { start: start, end: end },
			});

			commit('EXCUSES_SUCCESS', data.payload);
			commit('UPDATE_PENDING_REQUESTS', -1);
		},

		async getEventExcuses ({ commit }, { start, end }: GetEventExcuseMeParams) {
			commit('UPDATE_PENDING_REQUESTS', 1);

			const { data } = await api.get('/event-excuse/me', {
				params: { start: start, end: end },
			});

			commit('EVENT_EXCUSES_SUCCESS', data.payload);
			commit('UPDATE_PENDING_REQUESTS', -1);
		},

		async getEventType ({ commit }, id: number) {
			const resp = await api.get(`/event-type/${ id }`);

			commit('SET_EVENT_EVAL', resp.data.payload);
		},

		async getUserSummary ({ commit }, { start, end }: GetEventEvalMeSummaryParams) {
			const { data } = await api.get('/event-eval/me/summary', {
				params: { start: start, end: end },
			});

			commit('USER_SUMMARY_SUCCESS', data.payload);
		},

		async getHolidays ({ commit, rootGetters }, { start, end }: GetPlannerHolidayParams) {
			const me = rootGetters['user/me'] as Admin;

			commit('UPDATE_PENDING_REQUESTS', 1);

			const { data } = await api.get('/planner-holiday', {
				params: { start: start, end: end, adminId: me.id },
			});

			commit('HOLIDAYS_SUCCESS', data.payload);
			commit('UPDATE_PENDING_REQUESTS', -1);
		},

		async addExcuse ({ commit }, excuse: ExcuseRequest) {
			const sanitizedExcuse = {
				...excuse,
				note: excuse.note || null,
			};

			const { data } = await api.post('/excuses', sanitizedExcuse);

			commit('ADD_EXCUSE', data.payload);
		},

		async addEventExcuse ({ commit }, request: EventExcuseRequest) {
			const { data } = await api.post('/event-excuse', request);

			commit('ADD_EVENT_EXCUSE', data.payload);
		},

		async addEventEval (_, params: {
			body: EventEvalsRequest;
			callback: (hasDemand: boolean) => void;
		}) {
			const resp = await api.post('/event-eval', params.body);

			params.callback(resp.data.hasDemand);
		},

		async editExcuse ({ commit }, excuse: ExcuseRequest & { id: number }) {
			const { id, ...body } = excuse;
			const { data } = await api.put(`/excuses/${ id }`, body);

			commit('EDIT_EXCUSE', data.payload);
		},

		async deleteExcuse ({ commit }, excuseId: number) {
			await api.delete(`/excuses/${ excuseId }`);

			commit('DELETE_EXCUSE', excuseId);
			commit('DELETE_CURRENT');
		},
	});

/**
 * Extend an excuse with properties making it readily usable as a Fullcalendar
 * event object.
 * @param excuse an excuse from the `/excuses` endpoint
 * @returns the excuse with extra visual properties
 */

function extendExcuse (excuse: Excuse): ExtendedExcuse {
	return {
		...excuse,
		status: 'excuse',
		title: excuse.excuseType?.name,
		classNames: [ 'fc-excuse' ],
	};
}
