export type Module<
	S extends {} = {},
	G extends Getters<S> = {},
	M extends Mutations<S> = {},
	A extends Actions<S, G, M> = {},
	$ extends Submodules = {},
> = {
	namespaced: boolean;
	state: S;
	getters: G;
	mutations: M;
	actions: A;
	modules: $;
};

export type RootDispatch = (
    type: string,
    payload: unknown,
    options: { root: true },
) => Promise<unknown>;

export function buildModule (): BuiltModule {
	return new BuiltModule({}, {}, {}, {}, {});
}

class BuiltModule<
	S extends {} = {},
	G extends Getters<S> = {},
	M extends Mutations<S> = {},
	A extends Actions<S, G, M> = {},
	$ extends Submodules = {},
> implements Module<S, G, M, A, $> {
	public readonly namespaced = true;

	constructor (
		public readonly state: S,
		public readonly getters: G,
		public readonly mutations: M,
		public readonly actions: A,
		public readonly modules: $,
	) {}

	withState<T extends {}>(newState: T): BuiltModule<S & T, G, M, A, $> {
		const { getters, mutations, actions, modules } = this;
		const state = { ...this.state, ...newState };
		return new BuiltModule(state, getters, mutations, actions, modules);
	}

	withGetters<T extends Getters<S, GetterValues<S, G>>>(newGetters: T): BuiltModule<S, G & T, M, A, $> {
		const { state, mutations, actions, modules } = this;
		const getters = { ...this.getters, ...newGetters };
		return new BuiltModule(state, getters, mutations, actions, modules);
	}

	withMutations<T extends Mutations<S>>(newMutations: T): BuiltModule<S, G, M & T, A, $> {
		const { state, getters, actions, modules } = this;
		const mutations = { ...this.mutations, ...newMutations };
		return new BuiltModule(state, getters, mutations, actions, modules);
	}

	withActions<T extends Actions<S, G, M, A>>(newActions: T): BuiltModule<S, G, M, A & T, $> {
		const { state, getters, mutations, modules } = this;
		const actions = { ...this.actions, ...newActions };
		return new BuiltModule(state, getters, mutations, actions, modules);
	}

	withSubmodules<T extends Submodules>(newModules: T): BuiltModule<S, G, M, A, $ & T> {
		const { state, getters, mutations, actions } = this;
		const modules = { ...this.modules, ...newModules };
		return new BuiltModule(state, getters, mutations, actions, modules);
	}
}

type Getters<S extends {}, V extends Record<string, unknown> = {}> = Record<
	string,
	(state: S, values: V, rootState: unknown, rootValues: Record<string, unknown>) => unknown
>;

type Mutations<S extends {}> = Record<string, (state: S, payload: never) => unknown>;

type Actions<
	S extends {},
	G extends Getters<S>,
	M extends Mutations<S>,
	A extends Actions<S, G, M> = {},
> = Record<
	string,
	(
		context: {
			state: S;
			getters: GetterValues<S, G>;
			rootGetters: Record<string, unknown>;
			commit: Commit<S, M>;
			dispatch: Dispatch<S, G, M, A> & RootDispatch;
		},
		payload: never,
	) => unknown
>;

type Submodules = Record<string, Partial<Module>>;

type GetterValues<S extends {}, G extends Getters<S>> = {
	[K in keyof G]: ReturnType<G[K]>;
};

type Commit<
	S extends {},
	M extends Mutations<S>,
> = <K extends keyof M>(
	type: K,
	...payload: undefined extends Parameters<M[K]>[1]
		? [Parameters<M[K]>[1]?]
		: [Parameters<M[K]>[1]]
) => void;

type Dispatch<
	S extends {},
	G extends Getters<S>,
	M extends Mutations<S>,
	A extends Actions<S, G, M>,
> = <K extends keyof A>(
	type: K,
	...payload: undefined extends Parameters<A[K]>[1]
		? [Parameters<A[K]>[1]?]
		: [Parameters<A[K]>[1]]
) => Promise<unknown>;
