import { Api, GetTaskTypeParams, TaskType, TaskTypeLight, TaskTypeRequest } from '~/api';
import { assertApiError, createDict, Dict } from '~/helpers';
import $axios from '~/plugins/axios';
import { createFiltersModule, buildModule, createPaginationModule, defineSingleFilter } from '../utils';
import { TaskInfoRequest, TaskTypeInfoType, TaskTypeStatus } from '~/api/schemas';
import { v4 as uuidv4 } from 'uuid';
import { t } from 'i18next';

const api = $axios as Api;

export type TaskTypeStatusInternal = Omit<TaskTypeStatus, 'id'> & {
	_internalId: string;
	id: number | null;
}
export type TaskTypeInfoInternal = Omit<TaskInfoRequest, 'id'> & {
	_uuid: string;
	id: number | null;
}

export type TaskTypeDetailInternal = Omit<TaskType, 'statuses' | 'id'> & {
	statuses: TaskTypeStatusInternal[];
	id: number | null;
}

export default buildModule()
	.withSubmodules({
		filters: createFiltersModule({
			replaceRoute: true,
			filters: {
				searchTerm: defineSingleFilter('search', String),
			},
		}),

		pagination: createPaginationModule({
			pageQueryKey: 'page',
			pageSizeQueryKey: 'itemsPerPage',
			itemCountGetter: 'tasks/taskTypes/totalCount',
		}),
	})
	.withState({
		taskTypes: [] as TaskTypeLight[],
		storedTaskTypes: {} as Dict<TaskType>,
		totalCount: 0,
		taskTypesDetail: [] as TaskTypeDetailInternal[],
		infoTypes: [] as TaskTypeInfoType[],
	})
	.withGetters({
		taskTypes: state => state.taskTypes,
		totalCount: state => state.totalCount,
		taskTypesDetail: state => state.taskTypesDetail,
		taskTypeById: state => (id: number | null) => state.taskTypes.find(taskType => taskType.id === id) ?? null,
		taskTypeDetailById: state => (id: number | null) => state.taskTypesDetail.find(taskType => taskType.id === id) ?? null,
		infoTypes: state => state.infoTypes,
		storedTaskTypeById: state => (taskTypeId: number) => (state.storedTaskTypes[taskTypeId] ?? null),
	})
	.withGetters({
		statusByInternalId: (state, getters) => ({ taskTypeId, statusId }: { taskTypeId: number | null; statusId: string }) =>
			getters.taskTypeDetailById(taskTypeId)?.statuses.find(status => status._internalId === statusId) ?? null,
	})

	.withMutations({
		SET_TASK_TYPES (state, payload: TaskTypeLight[]) {
			state.taskTypes = payload;
		},

		SET_TOTAL_COUNT (state, count: number) {
			state.totalCount = count;
		},

		SET_TASK_TYPE_DETAIL (state, payload: TaskType) {
			const taskTypePayload = {
				...payload,
				statuses: payload.statuses.map(status => ({ ...status, _internalId: uuidv4() })),
			};

			const taskTypeIndex = state.taskTypesDetail.findIndex(taskType => taskType.id === payload.id);
			if (taskTypeIndex < 0) {
				state.taskTypesDetail = [ ...state.taskTypesDetail, taskTypePayload ];
				return;
			}
			state.taskTypesDetail = state.taskTypesDetail.map(taskType => (taskType.id === payload.id
				? taskTypePayload
				: taskType));
		},

		ADD_TASK_TYPE_NEW (state) {
			const NEW_TASK_TYPE: TaskTypeDetailInternal = {
				id: null,
				name: t('Nový typ úkolu'),
				description: null,
				statuses: [],
				taskInfos: [],
			};
			state.taskTypesDetail = [ ...state.taskTypesDetail, NEW_TASK_TYPE ];
		},

		DELETE_TASK_TYPE_NEW (state) {
			state.taskTypesDetail = state.taskTypesDetail.filter(taskType => taskType.id !== null);
		},

		UPDATE_FIELD (state, { id, ...taskUpdate }: { id: number | null } & Partial<TaskTypeDetailInternal>) {
			const taskTypeIndex = state.taskTypesDetail.findIndex(taskType => taskType.id === id);
			if (taskTypeIndex < 0) {
				return;
			}

			state.taskTypesDetail[taskTypeIndex] = {
				...state.taskTypesDetail[taskTypeIndex],
				...taskUpdate,
			};
		},

		UPDATE_STATUS_FIELD (state, { taskTypeId, internalId, ...statusUpdate }: { taskTypeId: number | null; internalId: string } & Partial<TaskTypeStatus>) {
			const taskType = state.taskTypesDetail.find(({ id }) => id === taskTypeId);
			if (!taskType) {
				return;
			}
			const statusTypeIndex = taskType.statuses.findIndex(status => status._internalId === internalId);
			if (statusTypeIndex < 0) {
				return;
			}

			taskType.statuses[statusTypeIndex] = {
				...taskType.statuses[statusTypeIndex],
				...statusUpdate,
			};
		},

		ADD_STATUS_FIELD (state, taskTypeId: number | null) {
			const taskType = state.taskTypesDetail.find(({ id }) => id === taskTypeId);
			if (!taskType) {
				return;
			}

			taskType.statuses = [
				...taskType.statuses,
				{
					_internalId: uuidv4(),
					id: null,
					name: t('Nový stav'),
					state: 'todo',
					description: null,
					requiredTaskInfoIds: [],
				},
			];
		},

		DELETE_STATUS_FIELD (state, { taskTypeId, internalId }: { taskTypeId: number | null; internalId: string }) {
			const taskType = state.taskTypesDetail.find(({ id }) => id === taskTypeId);
			if (!taskType) {
				return;
			}
			taskType.statuses = taskType.statuses.filter(status => status._internalId !== internalId);
		},

		SET_INFO_TYPES (state, payload: TaskTypeInfoType[]) {
			state.infoTypes = payload;
		},
		SET_STORED_TASK_TYPES (state, taskTypes: TaskType[]) {
			state.storedTaskTypes = {
				...state.storedTaskTypes,
				...createDict(taskTypes, (type) => type.id),
			};
		},
	})

	.withActions({
		async getTaskTypes ({ commit }, params: GetTaskTypeParams = {}) {
			try {
				const resp = await api.get('/task-type', { params });
				commit('SET_TASK_TYPES', resp.data.payload);
				commit('SET_TOTAL_COUNT', resp.data.paginator.totalCount);
				return resp;
			}
			catch (err) {
				new TempMessage({
					html: `<p>${ t('Typy úkolů se nepodařilo načíst.') }</p>`,
					type: 'error',
				});
			}
		},

		async getTaskType ({ commit }, id: number) {
			try {
				const resp = await api.get(`/task-type/${ id }`);
				commit('SET_TASK_TYPE_DETAIL', resp.data.payload);
				commit('SET_STORED_TASK_TYPES', [ resp.data.payload ]);
				return resp;
			}
			catch (err) {
				new TempMessage({
					html: `<p>${ t('Typ úkolu se nepodařilo načíst.') }</p>`,
					type: 'error',
				});
			}
		},

		async addTaskType (_, taskType: TaskTypeRequest) {
			try {
				const resp = await api.post('/task-type', taskType);
				new TempMessage({
					html: `<p>${ t('Typ úkolu byl přidán.') }</p>`,
					type: 'success',
				});
				return resp;
			}
			catch (err) {
				assertApiError(err);
				new TempMessage({
					html: `<p>${ t('Typ úkolu se nepodařilo uložit.') }</p>`,
					type: 'error',
				});
			}
		},

		async updateTaskType ({ commit }, { id, ...taskType }: TaskTypeRequest & { id: number }) {
			try {
				const resp = await api.put(`/task-type/${ id }`, taskType);
				commit('SET_TASK_TYPE_DETAIL', resp.data.payload);

				new TempMessage({
					html: `<p>${ t('Typ úkolu byl upraven.') }</p>`,
					type: 'success',
				});
				return resp;
			}
			catch (err) {
				assertApiError(err);
				new TempMessage({
					html: `<p>${ t('Typ úkolu se nepodařilo upravit.') }</p>`,
					type: 'error',
				});
			}
		},

		async deleteTaskType (_, id: number) {
			try {
				const resp = await api.delete(`/task-type/${ id }`);
				new TempMessage({
					html: `<p>${ t('Typ úkolu byl smazán.') }</p>`,
					type: 'success',
				});
				return resp;
			}
			catch (err) {
				assertApiError(err);
				new TempMessage({
					html: `<p>${ t('Typ úkolu se nepodařilo odstranit.') }</p>`,
					type: 'error',
				});
			}
		},

		async getInfoTypes ({ commit }) {
			try {
				const resp = await api.get('/task-type/info-types');
				commit('SET_INFO_TYPES', resp.data.payload);
				return resp;
			}
			catch (err) {
				assertApiError(err);
				new TempMessage({
					html: `<p>${ t('Typy úkolů se nepodařilo načíst.') }</p>`,
					type: 'error',
				});
			}
		},
	});
