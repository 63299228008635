import { Api, FilterKey } from '~/api';
import $axios from '~/plugins/axios';
import { buildModule } from '../utils';

const api = $axios as Api;

export default buildModule()
	.withState({
		filtersLocationKeys: [] as FilterKey[],
	})
	.withGetters({
		list: state => state.filtersLocationKeys,
	})
	.withMutations({
		STORE_FILTERS_LOCATION_KEYS (state, keys: FilterKey[]) {
			state.filtersLocationKeys = keys;
		},
	})
	.withActions({
		async getList ({ commit }) {
			const { data } = await api.get('/filter/keys');
			commit('STORE_FILTERS_LOCATION_KEYS', data.payload);
		},
	});
