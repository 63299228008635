import { GetPlannerHolidayParams, Pos, PlannerHoliday, GetPosParams, PlannerHolidayRequest } from '~/api';
import { DateTimeRange } from '~/helpers';
import moment from '../../plugins/moment';
import $axios from '../../plugins/axios';
import { createFiltersModule, buildModule, createPaginationModule, defineSingleFilter } from '../utils';
import { defineArrayFilter } from '../utils/filters';

const CURRENT_MONTH = moment().format('YYYY-MM');

export default buildModule()
	.withSubmodules({
		filters: createFiltersModule({
			replaceRoute: true,
			filters: {
				period: defineSingleFilter('period', String, CURRENT_MONTH),
				from: defineSingleFilter('from', String),
				to: defineSingleFilter('to', String),
				roleId: defineArrayFilter('role', Number),
				adminId: defineSingleFilter('admin', Number),
				searchPos: defineSingleFilter('pos', String),
				searchTerm: defineSingleFilter('note', String),
				regionId: defineSingleFilter('region', Number),
				areaId: defineSingleFilter('area', Number),
				territoryId: defineSingleFilter('territory', Number),
				orderBy: defineSingleFilter('orderBy', String),
				orderAsc: defineSingleFilter('orderAsc', Boolean, false),
			},
		}),

		pagination: createPaginationModule({
			pageQueryKey: 'page',
			defaultPageSize: 4,
			pageSizeQueryKey: 'itemsPerPage',
		}),
	})

	.withState({
		holidays: [] as PlannerHoliday[],
		pendingRequestCount: 0,
		poses: [] as Pos[],
		holidayPoses: [] as Pos[],
		totalCount: 0,
	})

	.withGetters({
		someRequestsPending: state => state.pendingRequestCount > 0,
		holidays: state => state.holidays,
		holidayById: state => (id?: number) => state.holidays.find(holiday => holiday.id === id) ?? null,
		holidaysInDay: state => (date: string) => state.holidays?.filter(holiday => moment(date).within(moment.range(moment(holiday.start), moment(holiday.end)))) ?? [],
		poses: state => state.poses,
		holidayPoses: state => state.holidayPoses,
	})
	.withGetters({
		holidaysRange: state => (range: DateTimeRange) => {
			const [ from, to ] = range;
			return state.holidays.filter(holiday => moment.range(moment(holiday.start), moment(holiday.end)).overlaps(moment.range(moment(from), moment(to)), { adjacent: true }));
		},
		posIds: state => state.poses.map(pos => pos.id),
	})
	.withGetters({
		holidaysPage: (_state, getters) => (pageSize:number, offset:number, range?: DateTimeRange) => (range
			? getters.holidaysRange(range).slice(offset, offset + pageSize)
			: getters.holidays.slice(offset, offset + pageSize)),
		totalCount: (_state, getters) => (range?: DateTimeRange) => (range
			? getters.holidaysRange(range).length
			: getters.holidays.length),
	})

	.withMutations({
		SET_HOLIDAYS (state, payload: PlannerHoliday[]) {
			state.holidays = payload;
		},
		SET_POSES (state, payload: Pos[]) {
			state.poses = payload;
		},
		SET_HOLIDAY_POSES (state, payload: Pos[]) {
			state.holidayPoses = payload;
		},
		SET_TOTAL_COUNT (state, count: number) {
			state.totalCount = count;
		},
		UPDATE_PENDING_REQUESTS (state, by: -1 | 1) {
			state.pendingRequestCount += by;
		},
	})

	.withActions({
		async getHolidays ({ commit }, params?: GetPlannerHolidayParams) {
			params = { orderBy: 'start', orderAsc: true, ...params };
			commit('UPDATE_PENDING_REQUESTS', 1);
			const resp = await $axios.get('/planner-holiday', { params });
			commit('SET_HOLIDAYS', resp.data.payload);
			commit('SET_TOTAL_COUNT', resp.data.paginator.totalCount);
			commit('UPDATE_PENDING_REQUESTS', -1);
			return resp;
		},

		async addHoliday (_, holiday: PlannerHolidayRequest) {
			await $axios.post('/planner-holiday', holiday);
		},

		async updateHoliday (_, { id, ...holiday }: PlannerHolidayRequest & { id: number }) {
			await $axios.put(`/planner-holiday/${ id }`, holiday);
		},

		async deleteHoliday (_, id: number) {
			await $axios.delete(`/planner-holiday/${ id }`);
		},

		async getPoses ({ commit }, params: GetPosParams) {
			commit('UPDATE_PENDING_REQUESTS', 1);
			const resp = await $axios.get('/pos', { params });
			commit('SET_POSES', resp.data.payload);
			commit('UPDATE_PENDING_REQUESTS', -1);
			return resp;
		},

		async getHolidayPoses ({ commit }, params: GetPosParams) {
			const resp = await $axios.get('/pos', { params });
			commit('SET_HOLIDAY_POSES', resp.data.payload);
			return resp;
		},
	});
