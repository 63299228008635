import { OpenedAuditQuestion, OpenedAudit, OpenedAuditOption } from '~/store/audits';
import { v4 as uuidv4 } from 'uuid';

export const duplicateOption = (option: OpenedAuditOption, { resetJumpTo }: {
	resetJumpTo?: boolean;
}): OpenedAuditOption => {
	return {
		...option,
		id: null,
		imageId: option._originalImage?.id ?? null,
		imageFilename: option._originalImage?.originalName ?? null,
		imageRemove: false,
		...resetJumpTo && {
			jumpTo: null,
			jumpToEnd: false,
		},
	};
};

export const duplicateQuestion = (question: OpenedAuditQuestion, { uuid, resetJumpTo, titlePrefix }: {
	uuid?: string;
	resetJumpTo?: boolean;
	titlePrefix?: string;
} = {}): OpenedAuditQuestion => ({
	...question,
	title: `${ titlePrefix ?? '' }${ question.title }`,
	uuid: uuid ?? uuidv4(),
	id: null,
	...resetJumpTo && {
		jumpTo: null,
		jumpToEnd: false,
		skipJumpTo: null,
		skipJumpToEnd: false,
	},
	options: question.options.map(option => duplicateOption(option, { resetJumpTo })),
});

export const duplicateAudit = (audit: OpenedAudit): OpenedAudit => {
	const questionUuidPairingTable: Record<string, string> = Object.fromEntries(audit.questions.map(question => [ question.uuid, uuidv4() ]));

	return {
		...audit,
		isEditable: true,
		id: null,
		name: `Kopie - ${ audit.name }`,
		questions: audit.questions
			.map(question => duplicateQuestion(question, { resetJumpTo: false, uuid: questionUuidPairingTable[question.uuid] }))
			.map(question => ({
				...question,
				jumpTo: question.jumpTo ? questionUuidPairingTable[question.jumpTo] : null,
				skipJumpTo: question.skipJumpTo ? questionUuidPairingTable[question.skipJumpTo] : null,
				options: question.options.map(option => ({
					...option,
					jumpTo: option.jumpTo ? questionUuidPairingTable[option.jumpTo] : null,
				})),
			})),
		signatureTypes: audit.signatureTypes.map(signatureType => ({
			...signatureType,
			uuid: uuidv4(),
		})),
	};
};
