<template>
	<basic-tooltip :disabled="!props.permissionDenied">
		<template #tooltip>
			{{ t('Oprávnění zamítnuto') }}
		</template>
		<button
			class="messaging-permission-button"
			type="button"
			:disabled="props.permissionDenied"
		>
			<div class="messaging-permission-button__icon">
				<icon which="notification" />
			</div>
			<div class="messaging-permission-button__caption">
				{{ t('Povolit notifikace') }}
			</div>
		</button>
	</basic-tooltip>
</template>

<script setup lang="ts">
type Props = {
	permissionDenied: boolean;
};

const props = defineProps<Props>();
const { t } = useTranslation();
</script>

<style scoped lang="scss">
@keyframes swing {
	0% { rotate: 0; }
	15% { rotate: -32deg; }
	30% { rotate: 24deg; }
	45% { rotate: -16deg; }
	60% { rotate: 0; }
}

.messaging-permission-button {
	display: flex;
	gap: 0.5rem;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	background-color: $primary;
	color: white;
	font-weight: $mediumFont;
	font-size: 1.2rem;
	text-transform: uppercase;
	cursor: pointer;

	&:disabled {
		background-color: $grey;
		cursor: not-allowed;
	}

	&__icon {
		width: 2rem;
		height: 2rem;
		color: white;
		animation: swing 1s infinite;
	}

	&:disabled &__icon {
		animation: none;
	}
}
</style>
