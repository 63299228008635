import $axios from '~/plugins/axios';

import { Api, GetPosParams, Pos } from '~/api';
import { buildModule } from '../utils';
import { createDict, Dict } from '~/helpers';

const api = $axios as Api;

type LazyLoadingParams = Omit<GetPosParams, 'limit' | 'offset'>;

export default buildModule()
	.withState({
		posById: {} as Dict<Pos>,
		orderedIds: [] as number[],
		params: {} as LazyLoadingParams,
		queryFetchEnabled: false,
	})
	.withGetters({
		items: (state) => state.orderedIds.map(id => state.posById[id]),
		byId: (state) => (id: number) => state.posById[id],
	})
	.withMutations({
		RESET (state, params: LazyLoadingParams = {}) {
			state.params = params;
			state.orderedIds = [];
		},
		APPEND_POSES (state, poses: Pos[]) {
			state.posById = { ...state.posById, ...createDict(poses, ({ id }) => id) };
			state.orderedIds = Array.from(new Set([ ...state.orderedIds, ...poses.map((pos) => pos.id) ]));
		},
		SET_POSES (state, poses: Pos[]) {
			state.posById = { ...state.posById, ...createDict(poses, ({ id }) => id) };
		},
	})
	.withActions({
		async getMore ({ state, commit }, limit: number | null = 20) {
			const params: GetPosParams = { ...state.params, limit, offset: state.orderedIds.length };

			if (params.searchTerm != null && params.searchTerm.length < 3) {
				params.searchTerm = undefined;
			}

			const { data } = await api.get('/pos', { params });

			commit('APPEND_POSES', data.payload);
		},
		async prefetchPoses ({ commit }, posIds: number[]) {
			const params: GetPosParams = { posId: posIds };
			const { data } = await api.get('/pos', { params });

			commit('SET_POSES', data.payload);
		},
		resetParams ({ state, commit }, params: LazyLoadingParams = {}) {
			commit('RESET', { ...state.params, ...params });
		},
	});
