<template>
	<svg :class="props.class">
		<use :href="`${ BASE_URL }#sprite-${ props.which }`" />
	</svg>
</template>

<script setup lang="ts">
import { SVGAttributes } from 'vue';
import { IconName } from '~/helpers';

const BASE_URL = import.meta.env.PROD ? '/__spritemap' : '';

type Props = {
	which: IconName;
	class?: SVGAttributes['class'];
};

const props = withDefaults(defineProps<Props>(), {
	class: 'w-full h-full',
});
</script>
