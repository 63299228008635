import { buildModule } from '../utils';

import lazy from './lazy';
import categories from './categories';

export default buildModule()
	.withSubmodules({
		lazy,
		categories,
	});
