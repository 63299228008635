<template>
	<basic-tooltip>
		<template #tooltip>
			{{ props.title }}
		</template>
		<a
			class="tray-item"
			:class="{
				'tray-item--disabled': props.disabled,
				'tray-item--active': props.active
			}"
			:href="props.link != null && !props.disabled ? props.link : undefined"
		>
			<icon :which="props.iconName" />
			<div
				v-if="props.alert"
				class="tray-item__alert-badge"
			>
				<pin
					color="#db0033"
					ripple
				/>
			</div>
		</a>
	</basic-tooltip>
</template>

<script setup lang="ts">
import { IconName } from '~/helpers';

type Props = {
	title: string;
	iconName: IconName;
	imageUrl?: string | null;
	link?: string | null;
	active?: boolean;
	disabled?: boolean;
	alert?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
	imageUrl: null,
	link: null,
	active: false,
});
</script>

<style scoped lang="scss">
.tray-item {
	position: relative;
	display: block;
	width: 6.2rem;
	height: 100%;
	padding: 1.5rem;
	color: $black;
	cursor: pointer;

	&--disabled {
		cursor: default;
	}

	&--active {
		background-color: $lightPrimary;
	}

	&__alert-badge {
		position: absolute;
		inset: 1.3rem 1.6rem auto auto;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 1.3rem;
		height: 1.3rem;
		border-radius: 50%;
		background-color: white;
	}
}
</style>
