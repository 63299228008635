import Multiselect from 'vue-multiselect';

const MultiselectDefaults = defineComponent({
	extends: Multiselect,
	props: {
		selectLabel: {
			type: String,
			default: '',
		},
		selectedLabel: {
			type: String,
			default: '',
		},
		deselectLabel: {
			type: String,
			default: '×',
		},
		tagPlaceholder: {
			type: String,
			default: '',
		},
	},
});
// register components globally
export default function registerComponents (app) {
	app
		.component('Multiselect', MultiselectDefaults);
}
