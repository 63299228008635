<template>
	<div class="notification-container">
		<div class="header">
			<div class="title">
				<a
					href="#"
					class="notification-icon"
					@click="emit('close')"
				>
					<icon
						which="cross-black"
						:aria-label="t('Zavřít')"
					/>
				</a>
				{{ t('Notifikace') }}
			</div>
			<a
				href="#"
				@click="markAllAsRead()"
			>{{ t('Označit vše jako přečtené') }}</a>
		</div>

		<div class="filter">
			<div>{{ t('Zobrazit jen nepřečtené') }}</div>
			<toggle
				id="show-only-unread-toggle"
				v-model="onlyUnreadShown"
				:checked-text="t('Ano')"
				:unchecked-text="t('Ne')"
			/>
		</div>

		<div class="notifications">
			<notification-list
				v-if="notifications.length > 0"
			>
				<notification-item
					v-for="notification in notifications"
					:key="notification.id"
					:html-content="notification.text"
					:iso-date="notification.createdAt"
					:is-read="notification.read"
					@toggle-read="toggleRead(notification)"
				/>
			</notification-list>
			<div
				v-else
				class="no-notifs-message"
			>
				{{ t('Žádné nové notifikace') }}
			</div>
		</div>

		<a
			class="footer"
			href="/core/notification"
		>
			{{ t('Zobrazit přehled všech upozornění') }}
		</a>
	</div>
</template>

<script setup lang="ts">
import { Notification } from '~/api';

const DISPLAYED_COUNT = 8;

const emit = defineEmits<{
	(e: 'close'): void;
}>();

const { t } = useTranslation();
const store = useTypedStore();

const onlyUnreadShown = ref(false);

const notifications = computed(() => store.getters['notifications/list/latestPage']);

onMounted(getNotifications);
watch(onlyUnreadShown, getNotifications);

async function getNotifications (): Promise<void> {
	await store.dispatch('notifications/list/getPage', {
		read: onlyUnreadShown.value ? false : null,
		limit: DISPLAYED_COUNT,
	});
}

async function toggleRead (notification: Notification): Promise<void> {
	await store.dispatch('notifications/list/updateWhetherRead', {
		id: notification.id,
		read: !notification.read,
	});
}

async function markAllAsRead (): Promise<void> {
	await store.dispatch('notifications/list/markAllRead');
}
</script>

<style lang="scss" scoped>
.notification-container {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	border-left: 1px solid $primary;
	background-color: white;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: -20rem;
		z-index: -1;
		width: 20rem;
		background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(15, 50, 235, 0.05) 70%);
		pointer-events: none;
	}

	.header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		height: 6rem;
		padding: 1rem 1.5rem;
		background: rgba(15, 50, 235, 0.05);

		a {
			flex-grow: 0;
			margin: auto 0;
			color: #0f32eb;
			line-height: 2.3rem;
		}

		.notification-icon {
			width: 3.2rem;
			height: 3.2rem;
			margin: auto 1rem auto 0;
			padding: 0 0.6rem;
			color: $black;
			line-height: initial;
		}

		.title {
			display: flex;
			align-items: center;
			color: $black;
			font-weight: $extraBold;
			font-size: 1.2rem;
			line-height: 1.8rem;
			text-transform: uppercase;
		}
	}

	.filter {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 1.5rem;
		font-size: 1.3rem;
		line-height: 2.8rem;
	}

	.notifications {
		padding: 0 1.5rem;

		.no-notifs-message {
			font-size: 1.2rem;
			text-align: center;
		}
	}

	.footer {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 6rem;
		margin-top: auto;
		background: rgba(15, 50, 235, 0.05);
		color: $primary;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}
}
</style>
