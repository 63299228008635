import $axios from '~/plugins/axios';
import moment from 'moment';

import { Admin, Api, AuditedPos, GetPosParams, GetAuditEvalIdParams, PaginatorResult, Pos, Audit, GetAdminParams, AuditedAdmin, GetFilterParams, Filter } from '~/api';
import {
	buildModule,
	createPaginationModule,
	createFiltersModule,
	defineArrayFilter,
	defineSingleFilter,
} from '../utils';

const api = $axios as Api;

const DATE_FORMAT = 'YYYY-MM-DD';
const START_DATE = moment().add(-3, 'M').startOf('M').format(DATE_FORMAT);
const END_DATE = moment().format(DATE_FORMAT);

const FILTERS_SHARED = {
	range: defineSingleFilter('range', String, 'weekly'),
	createdFrom: defineSingleFilter('createdFrom', String, START_DATE),
	createdTo: defineSingleFilter('createdTo', String, END_DATE),
	auditId: defineSingleFilter('audit', Number),
	areaId: defineSingleFilter('area', Number),
	regionId: defineSingleFilter('region', Number),
	territoryId: defineSingleFilter('territory', Number),
	customFilterIds: defineArrayFilter('filterId', Number),
	customFilterValues: defineArrayFilter('filterValue', String),
};

export default buildModule()
	.withSubmodules({
		posFilters: createFiltersModule({
			filters: {
				...FILTERS_SHARED,
				posId: defineArrayFilter('pos', Number),
				allowedAdminId: defineArrayFilter('admin', Number),
			},
		}),
		adminFilters: createFiltersModule({
			filters: {
				...FILTERS_SHARED,
				adminId: defineArrayFilter('admin', Number),
				allowedPosId: defineSingleFilter('pos', Number),
			},
		}),
		posPagination: createPaginationModule({
			pageQueryKey: 'posesPage',
			pageSizeQueryKey: 'posesPerPage',
			defaultPageSize: 10,
			itemCountGetter: 'audits/results/posTotalCount',
		}),
		adminPagination: createPaginationModule({
			pageQueryKey: 'posesPage',
			pageSizeQueryKey: 'posesPerPage',
			defaultPageSize: 10,
			itemCountGetter: 'audits/results/adminTotalCount',
		}),
	})
	.withState({
		audit: null as Audit | null,
		auditEvals: [] as (AuditedPos | AuditedAdmin)[],

		posPaginator: null as PaginatorResult | null,
		poses: [] as Pos[],

		adminPaginator: null as PaginatorResult | null,
		admins: [] as Admin[],

		customFilters: [] as Filter[],
		customFiltersLoaded: false,

		pendingRequestCount: 0,
	})
	.withGetters({
		audit: state => state.audit,
		auditEvals: state => state.auditEvals,
		adminAuditEvals: state => state.auditEvals.filter((auditEval): auditEval is AuditedAdmin => 'adminId' in auditEval),
		posAuditEvals: state => state.auditEvals.filter((auditEval): auditEval is AuditedPos => 'posId' in auditEval),
		poses: state => state.poses,
		admins: state => state.admins,
		posTotalCount: state => state.posPaginator?.totalCount ?? 0,
		adminTotalCount: state => state.adminPaginator?.totalCount ?? 0,
		posCustomFilters: state => state.customFilters.filter(f => f.infoType === 'pos'),
		adminCustomFilters: state => state.customFilters.filter(f => f.infoType === 'admin'),
		someRequestsPending: state => state.pendingRequestCount > 0,
	})
	.withMutations({
		SET_AUDIT (state, audit: Audit) {
			state.audit = audit;
		},

		SET_AUDIT_EVALS (state, auditEvals: (AuditedPos | AuditedAdmin)[]) {
			state.auditEvals = auditEvals;
		},

		POS_SUCCESS (state, poses: Pos[]) {
			state.poses = poses;
		},

		POS_PAGINATION (state, paginator: PaginatorResult) {
			state.posPaginator = paginator;
		},

		ADMIN_SUCCESS (state, admins: Admin[]) {
			state.admins = admins;
		},

		ADMIN_PAGINATION (state, paginator: PaginatorResult) {
			state.adminPaginator = paginator;
		},

		UPDATE_PENDING_REQUESTS (state, by: -1 | 1) {
			state.pendingRequestCount += by;
		},


		CUSTOM_FILTERS_SUCCESS (state, value: Filter[]) {
			state.customFilters = value;
			state.customFiltersLoaded = true;
		},
	})
	.withActions({
		getAudit ({ commit }, auditId: number) {
			commit('UPDATE_PENDING_REQUESTS', 1);
			return api.get(`/audit/${ auditId }`).then(resp => {
				commit('SET_AUDIT', resp.data.payload);
			}).finally(() => {
				commit('UPDATE_PENDING_REQUESTS', -1);
			});
		},

		getAuditEvals ({ commit }, { auditId, params = { range: 'weekly' } }: { auditId: number; params: GetAuditEvalIdParams }) {
			commit('UPDATE_PENDING_REQUESTS', 1);
			return api.get(`/audit/eval/${ auditId }`, { params }).then(resp => {
				commit('SET_AUDIT_EVALS', resp.data.payload);
			}).finally(() => {
				commit('UPDATE_PENDING_REQUESTS', -1);
			});
		},

		getPoses ({ commit }, params: GetPosParams) {
			commit('UPDATE_PENDING_REQUESTS', 1);
			return api.get('/pos', {
				params: {
					orderAsc: true,
					orderBy: 'name',
					...params,
				},
			})
				.then(resp => {
					const poses = resp.data.payload;
					const paginator = resp.data.paginator;

					commit('POS_SUCCESS', poses);
					commit('POS_PAGINATION', paginator);
				})
				.catch(err => { console.log(err); })
				.finally(() => {
					commit('UPDATE_PENDING_REQUESTS', -1);
				});
		},

		getUsers ({ commit }, params: GetAdminParams) {
			commit('UPDATE_PENDING_REQUESTS', 1);
			return api.get('/admin', {
				params: {
					orderAsc: true,
					orderBy: 'personalData.firstname',
					...params,
				},
			})
				.then(resp => {
					const users = resp.data.payload;
					const paginator = resp.data.paginator;

					commit('ADMIN_SUCCESS', users);
					commit('ADMIN_PAGINATION', paginator);
				})
				.catch(err => { console.log(err); })
				.finally(() => {
					commit('UPDATE_PENDING_REQUESTS', -1);
				});
		},

		getCustomFilters ({ commit }, resultType: 'poses' | 'users') {
			const params: GetFilterParams = {
				key: 'auditEval',
				hasPosInfo: resultType === 'poses',
				hasAdminInfo: resultType === 'users',
			};

			return api.get('/filter', { params })
				.then(resp => {
					const customFilters = resp.data.payload;
					commit('CUSTOM_FILTERS_SUCCESS', customFilters);
					return resp;
				});
		},
	});
