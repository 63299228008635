import $axios from '~/plugins/axios';

import { Api, GetRegionParams, Region } from '~/api';
import { buildModule } from '../utils';
import { createDict, Dict } from '~/helpers';

const api = $axios as Api;

type LazyLoadingParams = Omit<GetRegionParams, 'limit' | 'offset'>;

export default buildModule()
	.withState({
		regionById: {} as Dict<Region>,
		orderedIds: [] as number[],
		params: {} as LazyLoadingParams,
	})
	.withGetters({
		items: (state) => state.orderedIds.map(id => state.regionById[id]),
		byId: (state) => (id: number) => state.regionById[id],
	})
	.withMutations({
		RESET (state, params: LazyLoadingParams = {}) {
			state.params = params;
			state.orderedIds = [];
		},
		APPEND_REGIONS (state, regions: Region[]) {
			state.regionById = { ...state.regionById, ...createDict(regions, ({ id }) => id) };
			state.orderedIds = Array.from(new Set([ ...state.orderedIds, ...regions.map((region) => region.id) ]));
		},
		SET_REGIONS (state, regions: Region[]) {
			state.regionById = { ...state.regionById, ...createDict(regions, ({ id }) => id) };
		},
	})
	.withActions({
		async getMore ({ state, commit }, limit: number) {
			const params: GetRegionParams = { ...state.params, limit, offset: state.orderedIds.length };
			const { data } = await api.get('/region', { params });

			commit('APPEND_REGIONS', data.payload);
		},
		async prefetchRegions ({ commit }, regionIds: number[]) {
			const regionGetRequests = regionIds.map((id) => api.get(`/region/${ id }`));
			const regionResponses = await Promise.all(regionGetRequests);

			const regions = regionResponses.map((resp) => resp.data.payload);
			commit('SET_REGIONS', regions);
		},
		async getRegion ({ commit }, regionId: number) {
			const { data } = await api.get(`/region/${ regionId }`);

			commit('SET_REGIONS', [ data.payload ]);
		},
		resetParams ({ state, commit }, params: LazyLoadingParams = {}) {
			commit('RESET', { ...state.params, ...params });
		},
	});
