<template>
	<input
		v-model="model"
		type="checkbox"
		:class="theme"
		:data-text-unchecked="props.uncheckedText"
		:data-text-checked="props.checkedText"
	>
</template>

<script setup lang="ts">
type Props = {
	modelValue: boolean;
	checkedText?: string;
	uncheckedText?: string;
	theme?: 'default' | 'accent';
};

const props = withDefaults(defineProps<Props>(), {
	checkedText: '',
	uncheckedText: '',
	theme: 'default',
});

const emit = defineEmits<{
	(e: 'update:modelValue', value: boolean): void;
}>();

const model = computed({
	get: () => props.modelValue,
	set: value => emit('update:modelValue', value),
});
</script>

<style scoped lang="scss">
$input-width-default: 6.5rem;
$input-width-tablet: 9rem;
$input-height-default: 2.8rem;
$input-height-tablet: 3.4rem;
$circle-radius-default: 0.9rem;
$circle-radius-tablet: 1.2rem;
$transition-duration: 300ms;

@function circle-gradient($radius, $hole) {
	@return radial-gradient($radius, transparent 0, transparent $hole, white $hole + 5%, white 95%, transparent 100%);
}

@function circle-offset($input-width, $input-height, $sign: 1) {
	@return $sign * math.div($input-width - $input-height, 2);
}

input {
	position: relative;
	display: block;
	width: $input-width-default;
	height: $input-height-default;
	border-radius: math.div($input-height-default, 2);
	background-color: $secondary;
	background-image: circle-gradient($circle-radius-default, 0);
	background-position-x: circle-offset($input-width-default, $input-height-default, -1);
	cursor: pointer;
	transition-duration: $transition-duration;
	transition-property: background-position-x, background-color;
	appearance: none;

	&:disabled {
		opacity: 0.5;
	}

	@include tablet () {
		width: $input-width-tablet;
		height: $input-height-tablet;
		border-radius: math.div($input-height-tablet, 2);
		background-image: circle-gradient($circle-radius-tablet, 0);
		background-position-x: circle-offset($input-width-tablet, $input-height-tablet, -1);
	}

	&.accent {
		background-color: $error;
	}

	&::before,
	&::after {
		position: absolute;
		margin: 0 0.7rem;
		color: white;
		font-weight: $mediumFont;
		font-size: 1.2rem;
		line-height: $input-height-default;
		letter-spacing: 0.5px;
		text-transform: uppercase;
		transition: opacity $transition-duration;

		@include tablet () {
			margin: 0 1rem;
			font-size: 1.6rem;
			line-height: $input-height-tablet;
		}
	}

	&::before {
		content: attr(data-text-checked);
		left: 0;
		opacity: 0;
	}

	&::after {
		content: attr(data-text-unchecked);
		right: 0;
		opacity: 1;
	}
}

input:checked {
	background-color: $primary;
	background-image: circle-gradient($circle-radius-default, 58%);
	background-position-x: circle-offset($input-width-default, $input-height-default);

	@include tablet () {
		background-image: circle-gradient($circle-radius-tablet, 62%);
		background-position-x: circle-offset($input-width-tablet, $input-height-tablet);
	}

	&.accent {
		background-color: $success;
	}

	&::before {
		opacity: 1;
	}

	&::after {
		opacity: 0;
	}
}
</style>
