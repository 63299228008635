import i18next from 'i18next';
import * as resources from '~/../../../locales/i18n.json';

i18next.init({
	resources,
	lng: document.documentElement.lang,
	fallbackLng: false,
	preload: [ document.documentElement.lang ],
	debug: false,
	ns: 'messages',
	keySeparator: false,
	interpolation: {
		prefix: '%',
		suffix: '%',
	},
});
