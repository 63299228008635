import axios from 'axios';
import { Dict } from '~/helpers';
import store from '~/store';

const axiosInstance = axios.create({
	baseURL: `${ window.apiBaseUrl }/api/v1`,
});

axiosInstance.interceptors.request.use(request => (
	request.params != null
		? { ...request, params: transformParams(request.params) }
		: request
));

axiosInstance.interceptors.response.use((response) => {
	// Any status code that lie within the range of 2xx cause this function to trigger
	// Do something with response data
	return response;
}, function (error) {

	// 401 - vynuceni loginu s backlinkem reloadem stranky
	if (error.response && error.response.status === 401) {
		location.reload();
		return;
	}

	// 403 - zobrazit error page
	if (error.response && error.response.status === 403) {
		store.commit('core/GLOBAL_ERROR', error.response.status);
		return;
	}

	return Promise.reject(error);
});

export default axiosInstance;

function transformParams (params: Dict<unknown>): URLSearchParams {
	const entries = Object.entries(params).flatMap(transformParamsEntry);
	return new URLSearchParams(entries);
}

function transformParamsEntry ([ key, value ]: [string, unknown]): [string, string][] {
	switch (typeof value) {
	case 'string':
		return [ [ key, value ] ];
	case 'number':
		return [ [ key, String(value) ] ];
	case 'boolean':
		return [ [ key, String(Number(value)) ] ];
	case 'object':
		if (value == null) {
			return [];
		}
		return Array.isArray(value)
			? value
				.map((itemValue): [string, unknown] => [ `${ key }[]`, itemValue ])
				.flatMap(transformParamsEntry)
			: Object.entries(value)
				.map(([ childKey, childValue ]): [string, unknown] => [
					`${ key }[${ childKey }]`,
					childValue,
				])
				.flatMap(transformParamsEntry);
	default:
		return [];
	}
}
