import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

// Initialize Sentry
if (window.sentryConfig) {
	Sentry.init({
		dsn: window.sentryConfig.dsn,
		environment: window.sentryConfig.environment,
		beforeSend (event) {
			event.logger = 'javascript';

			return event;
		},

		integrations: [ new Integrations.BrowserTracing() ],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 0.25,
	});
}
