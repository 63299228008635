<template>
	<div class="error-page">
		<header>
			<img
				:src="imgSrc"
				:alt="statusCode"
			>
			<h1>{{ statusCode }}</h1>
		</header>
		<p>{{ errorMessage }}</p>
		<p v-if="statusCode !== 500">
			{{ t('Ujistěte se, že jste neudělali chybu v URL adrese.') }}<br>
			{{ t('Je možné, že byla stránka přemístěna nebo odstraněna.') }}
		</p>
	</div>
</template>

<script setup>
import imgErr4xx from '@home/img/posmio-broken_4xx.png?url';
import imgErr500 from '@home/img/posmio-broken_500.png?url';

const { t } = useTranslation();

const props = defineProps({
	statusCode: {
		type: Number,
		default: 500,
	},
});

const imgSrc = computed(() => ((props.statusCode === 500)
	? imgErr500
	: imgErr4xx));

const errorMessage = computed(() => {
	if (props.statusCode === 500) {
		return t('Omlouváme se! Server zaznamenal vnitřní chybu a nebyl schopen žádost dokončit. Prosím zkuste to znovu později.');
	}
	if (props.statusCode === 403) {
		return t('Nemáte oprávnění k zobrazení této stránky. Pokud si myslíte, že byste měli tuto stránku zobrazit, zkuste se obrátit na správce webu.');
	}
	if (props.statusCode === 404) {
		return t('Vypadá to, že zadaná adresa neexistuje.');
	}
	return '';
});
</script>
