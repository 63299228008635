import $axios from '~/plugins/axios';

import { Admin, Api, GetAdminParams, TaskItem, TaskItemComment, TaskItemLight } from '~/api';
import { buildModule } from '../utils';
import { Dict } from '~/helpers';

const api = $axios as Api;

export default buildModule()
	.withState({
		storedTaskItems: {} as Dict<TaskItem>,
		assignableAdmins: [] as Admin[],
		comments: [] as TaskItemComment[],
		commentsTotalCount: 0,
	})
	.withGetters({
		byId: state => (taskItemId: number) => (
			state.storedTaskItems[taskItemId] ?? null
		),
		assignableAdmins: (state) => state.assignableAdmins,
		comments: (state) => state.comments,
		commentsTotalCount: (state) => state.commentsTotalCount ?? 0,
	})
	.withMutations({
		STORE_TASK_ITEMS (state, taskItem: TaskItemLight) {
			state.storedTaskItems = {
				...state.storedTaskItems,
				[taskItem.id]: taskItem,
			};
		},
		SET_ASSIGNABLE_ADMINS (state, payload: Admin[]) {
			state.assignableAdmins = payload;
		},
		SET_COMMENTS (state, comments: TaskItemComment[]) {
			state.comments = comments;
		},
		SET_COMMENTS_COUNT (state, count: number) {
			state.commentsTotalCount = count;
		},
	})
	.withActions({
		async get ({ commit }, id: number) {
			const { data } = await api.get(`/task-item/${ id }`);
			commit('STORE_TASK_ITEMS', data.payload);

			// new TempMessage({
			// 	html: `<p>${ t('Detail úkolu se nepodařilo načíst.') }</p>`,
			// 	type: 'error',
			// });
		},
		async assign (_, { id, adminId }: { id: number; adminId: number }): Promise<TaskItem> {
			const { data } = await api.put(`/task-item/${ id }/assign`, { adminId });
			return data.payload;

			// 	new TempMessage({
			// 		html: `<p>${ t('Úkol se nepodařilo přiřadit.') }</p>`,
			// 		type: 'error',
			// 	});
		},
		async unassign (_, id: number): Promise<TaskItem> {
			const { data } = await api.put(`/task-item/${ id }/un-assign`);
			return data.payload;

			// new TempMessage({
			// 	html: `<p>${ t('Přiřazení úkolu se nepodařilo odstranit.') }</p>`,
			// 	type: 'error',
			// });
		},
		async update (_, { id, status }: { id: number; status: number }) {
			await api.put(`/task-item/${ id }`, { status });
		},
		async getAssignableAdmins ({ commit }, params: GetAdminParams = {}) {
			const { data } = await api.get('/admin', { params });
			commit('SET_ASSIGNABLE_ADMINS', data.payload);

			// new TempMessage({
			// 	html: `<p>${ t('Seznam uživatelů se nepodařilo načíst.') }</p>`,
			// 	type: 'error',
			// });
		},
		async updateInfoValue (_, { id, taskInfoId, value }: { id: number; taskInfoId: number; value: string }) {
			await api.put(`/task-item/${ id }/task-info/${ taskInfoId }`, {
				value,
				valueId: null,
			});
		},

		async deleteInfoValue (_, { id, taskInfoId }: { id: number; taskInfoId: number }) {
			await api.delete(`/task-item/${ id }/task-info/${ taskInfoId }`);
		},
		async getComments ({ commit, state }, { id, limit = 5, loadMore = false }:{ id: number; limit?: number; loadMore?: boolean }) {
			const { data } = await api.get(`/task-item/${ id }/comment`, {
				params: {
					offset: loadMore ? state.comments.length : 0,
					limit,
				},
			});
			commit('SET_COMMENTS', [
				...(loadMore ? state.comments : []),
				...data.payload,
			]);
			commit('SET_COMMENTS_COUNT', data.paginator.totalCount);

			// new TempMessage({
			// 	html: `<p>${ t('Komentáře se nepodařilo načíst.') }</p>`,
			// 	type: 'error',
			// });
		},
		async addComment (_, { id, comment }: { id: number; comment: string }) {
			await api.post(`/task-item/${ id }/comment`, { comment });


			// new TempMessage({
			// 	html: `<p>${ t('Komentář se nepodařilo přidat.') }</p>`,
			// 	type: 'error',
			// });
		},
	});

